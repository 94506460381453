import { Dispatch, SetStateAction } from 'react'

import { Box, Modal, SanitizedString } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useDeleteSamlConfigurationsMutation } from 'main/services/queries/use-saml-configurations-query'

type DeleteDataSourceModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  dataSourceId: number
  onSubmit: () => void
}

export const DeleteDatasourceModal = ({ open, setOpen, dataSourceId, onSubmit }: DeleteDataSourceModalType) => {
  // const notify = useNotify()
  const { t } = useLanguage('dataSources')
  const deleteMutation = useDeleteSamlConfigurationsMutation()
  const { isLoading: isDeleteLoading } = deleteMutation

  const onSubmitDelete = async () => {
    // deleteMutation.mutate(
    //   { id: dataSourceId },
    //   {
    //     onSuccess: () => {
    //       queryClient.invalidateQueries(['data-sources'])
    //       notify.success(t('dataSources.modals.deleteDataSource.toasters.success'))
    //       closeDeleteDataSourceForm()
    //       onSubmit()
    //     },
    //     onError: () => {
    //       notify.error(t('dataSources.modals.deleteDataSource.toasters.genericError'))
    //     }
    //   }
    // )
    console.log(dataSourceId)
    onSubmit() // TODO: remove these two lines when implementing delete (left in to prevent esLint unused args)
  }

  const closeDeleteDataSourceForm = () => {
    setOpen(false)
  }

  return (
    <Modal
      title={t('dataSources.modals.deleteDataSource.title')}
      open={open}
      onClose={closeDeleteDataSourceForm}
      onClickConfirm={onSubmitDelete}
      loading={isDeleteLoading}
      confirmText="Delete"
      confirmIcon="trash-o"
      loadingText={t('loading')}
    >
      <Box>
        <SanitizedString input={t('dataSources.modals.deleteDataSource.body')} />
      </Box>
    </Modal>
  )
}
