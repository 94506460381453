import { memo, SyntheticEvent, useMemo, useState } from 'react'

import { Avatar, AvatarSubject } from './avatar'
import { IconButton } from '../button'
import { Box } from '../layout/box'

export const AvatarGroup = memo(
  ({
    onClickAvatarItem,
    rightPosition = 15,
    avatars
  }: {
    avatars: { tooltip: string | boolean; subject: AvatarSubject }[]
    onClickAvatarItem: (e: SyntheticEvent, subject: AvatarSubject) => void
    rightPosition?: number
  }) => {
    const subjects = useMemo(() => avatars.map(a => a.subject), [avatars])
    const [groupAvatars, setGroupAvatars] = useState(true)

    const toggleGroupedAvatar = (e: SyntheticEvent) => {
      e.stopPropagation()
      setGroupAvatars(!groupAvatars)
    }

    return groupAvatars ? (
      <Avatar subject={subjects} onClick={toggleGroupedAvatar} tooltipPlacement="top" tooltip="Click to open" />
    ) : (
      <Box
        direction="row"
        gap="small"
        css={`
          position: absolute;
          z-index: 2;
          right: ${rightPosition}px;
        `}
      >
        {avatars.map(({ subject, tooltip }) => (
          <Avatar
            key={subject.id}
            subject={subject}
            tooltipPlacement="top"
            tooltip={tooltip}
            onClick={(e: SyntheticEvent) => {
              onClickAvatarItem(e, subject)
            }}
          />
        ))}
        <IconButton
          size="medium"
          label="Close avatar group"
          icon="close"
          disableTooltip
          onClick={toggleGroupedAvatar}
        />
      </Box>
    )
  }
)
