import { Icon, IconName, RunbookTypeIcon, RunbookTypeIconName } from '@cutover/react-ui'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type IconProps = AppComponentNodeProps & {
  icon: IconName | string
  iconColor?: string
}

export const IconNode = ({ appId, resourceId, id, ...props }: IconProps) => {
  const { icon, iconColor = 'gray' } = useComponentProps(appId, resourceId, id, props) as IconProps

  const runbookIconName = icon.replace('runbook-type-', '') as RunbookTypeIconName

  return (
    <>
      {icon.startsWith('runbook-type') ? (
        <RunbookTypeIcon icon={runbookIconName} color={iconColor} />
      ) : (
        <Icon icon={icon as IconName} color={iconColor} />
      )}
    </>
  )
}
