import styled, { css } from 'styled-components/macro'

import { Box } from '../../../../layout'
import { themeElevation } from '../../../../theme'

// TODO: get reusable radius...
export const SelectMenuDrop = styled(Box).attrs(() => ({
  background: 'bg'
}))<{ isOpen: boolean }>`
  width: 100%;
  z-index: 10000;
  box-shadow: ${themeElevation('medium')};

  ${props =>
    props.isOpen &&
    css`
      border-radius: 8px;
    `}
`
