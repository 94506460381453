import axios, { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

import { IBaseResponse } from 'Gateways/Service/IBaseResponse'

// Note: this gateway is mostly a mix of code put together from existing LogicRoom's services:
//  - HttpServiceGateway (for making requests using axios)
//  - AuthenticationRepository (for adding authentication headers to requests)
//  - StorageGateway (for cookie related logic)
//
// This will need refactoring as we are moving authentication from Angular to React,
// see https://cutover.atlassian.net/browse/CFE-41
export class AuthServiceGateway {
  private get urlRoot(): string {
    const portString = process.env.API_PORT ? `:${process.env.API_PORT}` : ''
    const { protocol, hostname } = window.location
    return `${protocol}//${hostname}${portString}/api/`
  }

  public get headers(): { [key: string]: string | number } {
    let headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Cache-Control': 'no-store, max-age=0',
      ClientTime: new Date().getTime(),
      Account: '',
      'Request-Origin': 'react',
      browserHash: window.sessionStorage.getItem('browserHash') as string
    }

    return headers
  }

  public async get(
    path: string,
    params?: { [key: string]: number | string | boolean },
    extraHeaders: { [key: string]: string } = {},
    extraConfig: AxiosRequestConfig = {}
  ): Promise<IBaseResponse> {
    try {
      // Do not apply auth headers to /configs request to improve performance on the /configs request
      // as the user won't get loaded in the backend
      const headers =
        path === 'configs'
          ? { ...this.headers, ...extraHeaders }
          : { ...this.headers, ...this.loadAuth(), ...extraHeaders }

      const response = await axios.get(this.urlRoot + path, { params, headers, ...extraConfig })
      await this.setAuth(response)

      return {
        success: true,
        body: response.data
      }
    } catch (error) {
      return this.handleResponseException(error)
    }
  }

  public async delete(
    path: string,
    params?: { [key: string]: number | string | boolean },
    extraHeaders: { [key: string]: string } = {}
  ): Promise<IBaseResponse> {
    try {
      const { headers } = this.applyHeadersAndGetAuthRepo(extraHeaders)
      const response = await axios.delete(this.urlRoot + path, { params, headers })
      await this.setAuth(response)

      return {
        success: true,
        body: response.data
      }
    } catch (error) {
      return this.handleResponseException(error)
    }
  }

  public async post(
    path: string,
    request?: object,
    extraHeaders: { [key: string]: string } = {}
  ): Promise<IBaseResponse> {
    try {
      const { headers } = this.applyHeadersAndGetAuthRepo(extraHeaders)
      const response = await axios.post(this.urlRoot + path, request, { headers })
      await this.setAuth(response)

      return {
        success: true,
        body: response.data
      }
    } catch (error) {
      return this.handleResponseException(error)
    }
  }

  public async put(
    path: string,
    request?: object,
    extraHeaders: { [key: string]: string } = {}
  ): Promise<IBaseResponse> {
    try {
      const { headers } = this.applyHeadersAndGetAuthRepo(extraHeaders)
      const response = await axios.put(this.urlRoot + path, request, { headers })
      await this.setAuth(response)

      return {
        success: true,
        body: response.data
      }
    } catch (error) {
      return this.handleResponseException(error)
    }
  }

  public async patch(
    path: string,
    request?: object,
    extraHeaders: { [key: string]: string } = {}
  ): Promise<IBaseResponse> {
    try {
      const { headers } = this.applyHeadersAndGetAuthRepo(extraHeaders)
      const response = await axios.patch(this.urlRoot + path, request, { headers })
      await this.setAuth(response)

      return {
        success: true,
        body: response.data
      }
    } catch (error) {
      return this.handleResponseException(error)
    }
  }

  private getCookie(key: string) {
    return document ? Cookies.get(key) || null : null
  }

  public setCookie(key: string, value: string) {
    return Cookies.set(key, value, { expires: 7, path: '/', secure: true, sameSite: 'strict' })
  }

  private loadAuth = () => {
    const blob = this.getCookie('auth_headers')
    return blob ? JSON.parse(blob) : null
  }

  private setAuth = async (response: any) => {
    const { headers } = response

    const authHeaders = this.getCookie('auth_headers')
    const oldToken = authHeaders ? JSON.parse(authHeaders) : null
    const oldTokenExpiry = (oldToken && oldToken.expiry) || 0
    const newTokenExpiry = headers?.['expiry']

    if (newTokenExpiry !== '' && Number(newTokenExpiry) >= Number(oldTokenExpiry)) {
      return this.setCookie(
        'auth_headers',
        JSON.stringify({
          'access-token': headers['access-token'],
          client: headers['client'],
          expiry: headers['expiry'],
          'token-type': headers['token-type'],
          uid: headers['uid']
        })
      )
    }
  }

  private applyHeadersAndGetAuthRepo(extraHeaders: { [p: string]: string }) {
    const authHeadersFromGateway: any = this.loadAuth()
    const headers = { ...this.headers, ...authHeadersFromGateway, ...extraHeaders }
    return { headers }
  }

  private async handleResponseException(error: any) {
    const { response } = error
    const status = response.status || null

    const baseResponse: IBaseResponse = {
      status,
      success: false,
      body: response.data
    }

    await this.setAuth(response)

    if (response.data?.hasOwnProperty('errors')) {
      if (Array.isArray(response.data.errors)) {
        baseResponse.errorMessages = response.data.errors
      } else if (typeof response.data.errors === 'string') {
        baseResponse.errorMessages = [response.data.errors]
      }
    }

    if (response.data?.hasOwnProperty('success') && Array.isArray(response.data.success)) {
      baseResponse.successMessages = response.data.success
    }

    return baseResponse
  }
}
