import { groupBy, keyBy, mapValues, sortBy } from 'lodash'
import { DefaultValue, selector, selectorFamily, useRecoilValue, waitForAll } from 'recoil'

import { runbookVersionPermissionsState } from './runbook-version'
import { runbookVersionMetaState } from '..'
import { filteredTaskListDataState, taskListLookupState } from '../tasks/task-list'
import { currentUserState } from 'main/recoil/current-user'
import { StreamListStream } from 'main/services/queries/types'

export const streamsState = selector<StreamListStream[]>({
  key: 'streams:list',
  get: ({ get }) => {
    const streams = get(runbookVersionMetaState).streams
    return sortBy(streams, 'name')
  },
  set: ({ set }, newValue) => {
    return set(runbookVersionMetaState, prev => {
      if (newValue instanceof DefaultValue) return newValue

      return { ...prev, streams: newValue }
    })
  }
})

export const streamsFlattenedState = selector<StreamListStream[]>({
  key: 'streams:list:flat',
  get: ({ get }) => {
    const parentStreams = get(streamsState)
    return parentStreams.flatMap(stream => [stream, ...(stream.children ?? [])])
  }
})

export const streamsLookupState = selector<Record<number, StreamListStream>>({
  key: 'streams:lookup',
  get: ({ get }) => {
    return keyBy(get(streamsFlattenedState), 'id')
  }
})

export const streamsInternalIdLookupState = selector<Record<number, StreamListStream>>({
  key: 'streams:internal-id-lookup',
  get: ({ get }) => {
    return keyBy(get(streamsFlattenedState), 'internal_id')
  }
})

export const streamState = selectorFamily<StreamListStream, { id: number }>({
  key: 'streams:id',
  get:
    ({ id }) =>
    ({ get }) => {
      return get(streamsLookupState)[id]
    }
})

export const streamTaskCountsRecordState = selector<{ [key: string]: { total: number; filtered: number } }>({
  key: 'streams:task-count',
  get: ({ get }) => {
    const [streamsLookup, tasksLookup] = get(waitForAll([streamsLookupState, taskListLookupState]))

    const allTasks = Object.values(tasksLookup)
    const [filteredIds] = get(filteredTaskListDataState)
    const filteredTasks = filteredIds.map(id => tasksLookup[id])

    const allTasksByStreamId = groupBy(allTasks, 'stream_id')
    const filteredTasksByStreamId = groupBy(filteredTasks, 'stream_id')

    return mapValues(streamsLookup, (stream, streamId) => {
      const allTasksForStreamCount = allTasksByStreamId[streamId]?.length ?? 0
      const filteredTasksForStreamCount = filteredTasksByStreamId[streamId]?.length ?? 0

      let streamChildCounts = { total: 0, filtered: 0 }

      if (stream.children?.length) {
        streamChildCounts = stream.children.reduce(
          (acc, child) => ({
            total: acc.total + allTasksByStreamId[child.id]?.length ?? 0,
            filtered: acc.filtered + (filteredTasksByStreamId[child.id]?.length ?? 0)
          }),
          { total: 0, filtered: 0 }
        )
      }

      return {
        total: allTasksForStreamCount + streamChildCounts.total,
        filtered: filteredTasksForStreamCount + streamChildCounts.filtered
      }
    })
  }
})

export const streamsPermittedState = selector<StreamListStream[]>({
  key: 'streams:permitted',
  get: ({ get }) => {
    const streams = get(streamsFlattenedState)
    const currentUser = get(currentUserState)

    const {
      streams: { create_tasks: canCreateTasks }
    } = get(runbookVersionPermissionsState)

    if (!currentUser) {
      return []
    }

    if (canCreateTasks.includes(currentUser.id)) return streams

    return streams.filter(s => s.permissions.create_task.includes(currentUser.id))
  }
})

export const streamsPermittedLookupState = selector<Record<string | number, StreamListStream>>({
  key: 'streams:permitted:lookup',
  get: ({ get }) => keyBy(get(streamsPermittedState), 'id')
})

export const streamPermittedState = selectorFamily<StreamListStream, { id: number | string }>({
  key: 'streams:permitted:id',
  get:
    ({ id }) =>
    ({ get }) =>
      get(streamsPermittedLookupState)[id]
})

export const streamPermittedDefaultState = selector<StreamListStream>({
  key: 'streams:permitted:default',
  get: ({ get }) => {
    const permittedStreams = get(streamsPermittedState)

    return permittedStreams.find(s => s.is_primary) || permittedStreams[0]
  }
})

export const usePermittedStreams = () => {
  return useRecoilValue(streamsPermittedState)
}

export const useStreamParent = (streamId: number) => {
  const stream = useRecoilValue(streamState({ id: streamId }))
  // have to get the lookup in full because we can't call hooks conditionally just when the parent_id exists
  const lookup = useRecoilValue(streamsLookupState)
  return stream.parent_id ? lookup[stream.parent_id] : undefined
}

export const useStreamOrPermittedDefault = (id?: number) => {
  // @ts-ignore the id possibly being undefined is safe here
  const stream = useRecoilValue(streamPermittedState({ id }))
  const defaultStream = useRecoilValue(streamPermittedDefaultState)
  return stream ?? defaultStream
}
