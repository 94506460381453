import { Box } from '../layout/box/box'
import { Text } from '../typography/text/text'

type NoResourceFoundProps = {
  clearAllFilters?: () => void
  context?: string
  horizontally?: boolean
  vertically?: boolean
}

// This has some one off styling choices that are specific to match the angular spacing.
// TODO: Determine what this should look like and implement properly.
export const NoResourceFound = ({ clearAllFilters, context }: NoResourceFoundProps) => {
  const noResourceFoundMessage = `No matching ${context}s found.` // TODO: implement language service
  const cantFindMessage = `Can't find the ${context} you're looking for? Try  `

  return (
    <Box margin={{ top: '48px' }} css="white-space: normal;">
      <Text textAlign="center" color="text-light" css="margin-bottom: 20px; font-size: 18px;">
        {noResourceFoundMessage}
      </Text>
      {clearAllFilters && (
        <Text textAlign="center" color="text-light" css="font-size: 18px;">
          {cantFindMessage}
          <Text role="button" color="primary" onClick={clearAllFilters} css="cursor: pointer; font-size: 18px;">
            clearing your filters
          </Text>
        </Text>
      )}
    </Box>
  )
}
