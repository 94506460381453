import { useMemo } from 'react'

import { AccordionPanel, Box, IconButton, Pill, PlainTextButton } from '@cutover/react-ui'
import { getFilterSelections, useFilterHeaderPillText } from './filter-helpers'
import { FilterGroup, FilterOption, FilterOptionCheckbox, SelectedFilter, SelectedFilters } from './filter-types'
import { useLanguage } from 'main/services/hooks'

export type FilterHeaderProps = {
  selected: SelectedFilters
  filterData: FilterGroup[]
  onClearAll: () => void
  onTabClear: (slug: string, filter: FilterGroup, option?: FilterOption) => void
  onReverse: (filter: FilterGroup, option: FilterOptionCheckbox) => void
}

type SlugToFilterMap = {
  [key: string]: {
    filter: FilterGroup
    option?: FilterOption
  }
}

export const FilterHeader = ({ selected, filterData, onClearAll, onTabClear, onReverse }: FilterHeaderProps) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const { f: selectedCustomFields, ...selectedFields } = selected
  const slugToFilterMap = useMemo(
    () =>
      filterData.reduce<SlugToFilterMap>((acc, filter) => {
        if (filter.customFieldId) {
          acc[`${filter.customFieldId}`] = { filter }
          return acc
        }
        if (filter.slug) {
          acc[`${filter.slug}`] = { filter }
        }

        if ('options' in filter) {
          filter.options.forEach(option => {
            if (option.slug) acc[option.slug] = { filter, option }
          })
        }
        return acc
      }, {}),
    [filterData]
  )

  const countSelected = (selections: SelectedFilters = {}) =>
    Object.keys(selections).reduce<number>((acc, selectedKey) => {
      if (!slugToFilterMap[selectedKey]) return acc
      return acc + 1
    }, 0)

  const selectedCount = countSelected(selectedFields) + countSelected(selectedCustomFields as SelectedFilters)

  return (
    <AccordionPanel
      disabled={selectedCount === 0}
      label={t('filterSelection', { count: selectedCount })}
      a11yTitle={t('filterSelection', { count: selectedCount })}
      suffix={selectedCount > 0 && <PlainTextButton onClick={onClearAll}>{t('clearAll')}</PlainTextButton>}
    >
      <Box direction="row" gap="xsmall" wrap>
        {[selectedFields, selectedCustomFields].map(
          fields =>
            fields &&
            Object.keys(fields).map(slug => {
              if (!slugToFilterMap[slug]) return

              const { filter, option } = slugToFilterMap[slug]
              return (
                <ActiveFilterPill
                  key={`${slug}-filter-pill`}
                  selected={getFilterSelections(selected, filter, option)}
                  filter={filter}
                  option={option}
                  onClear={() => onTabClear(slug, filter, option)}
                  onReverse={onReverse}
                />
              )
            })
        )}
      </Box>
    </AccordionPanel>
  )
}

const ActiveFilterPill = ({
  selected,
  filter,
  option,
  onClear,
  onReverse
}: {
  selected: SelectedFilter
  filter: FilterGroup
  option?: FilterOption
  onClear: () => void
  onReverse: (filter: FilterGroup, option: FilterOptionCheckbox) => void
}) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const text = useFilterHeaderPillText(selected, filter, option) ?? ''
  const reversible = (option as FilterOptionCheckbox)?.labelReversed
  const key = filter.customFieldId || filter.slug || option?.slug || filter.title
  return (
    <Pill
      data-testid={`${key}-filter-pill`}
      label={text}
      truncate="tip"
      suffix={
        <>
          {reversible && (
            <IconButton
              data-testid={`${key}-filter-pill-reverse`}
              icon="swap"
              size="small"
              tipPlacement="top"
              label={t('filterByOpposite')}
              onClick={() => onReverse(filter, option as FilterOptionCheckbox)}
            />
          )}
          <IconButton
            tipPlacement="top"
            label={t('removeFilter')}
            data-testid={`${key}-filter-pill-clear`}
            icon="close"
            size="small"
            onClick={onClear}
          />
        </>
      }
    />
  )
}
