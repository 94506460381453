import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import * as yup from 'yup'

import {
  Accordion,
  AccordionPanel,
  Box,
  Checkbox,
  EditPanel,
  FormWrapper,
  IconButton,
  Menu,
  MenuListItem,
  Message,
  TextArea,
  TextInput,
  useNotify
} from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useConnectSettingEditPanel } from 'main/context/panel-context'
import { ConnectSetting } from 'main/services/queries/types/connect'
import { useConnectSetting, useConnectSettingsPatch } from 'main/services/queries/use-connect-settings-query'
import { ActivateProjectModal } from '../modals/activate-project-modal'
import { ConnectDownloadsModal } from '../modals/connect-downloads-modal'
import { RenewCertificateModal } from '../modals/renew-certificate-modal'
import { queryClient } from 'main/query-client'
import { QueryKeys } from 'main/services/queries/query-keys'

export const ConnectSettingEditPanel = () => {
  const { connectSettingId, clearPanel } = useConnectSettingEditPanel()
  return (
    <>
      {connectSettingId ? (
        <ConnectSettingEdit connectSettingId={connectSettingId} onClose={clearPanel} key={connectSettingId} />
      ) : null}
    </>
  )
}

type ConnectSettingsEditType = {
  connectSettingId: number
  onClose: () => void
}

export type ConnectSettingFormType = Partial<ConnectSetting>

const ConnectSettingEdit = ({ connectSettingId, onClose }: ConnectSettingsEditType) => {
  const { t } = useLanguage('connectSettings')
  const { data, isLoading } = useConnectSetting(connectSettingId as unknown as string)

  const [activateModalOpen, setActivateModalOpen] = useState(false)
  const [renewCertModalOpen, setRenewCertModalOpen] = useState(false)
  const [connectDownloadsModalOpen, setConnectDownloadsModalOpen] = useState(false)

  const patchMutation = useConnectSettingsPatch()
  const notify = useNotify()

  const validationSchema = yup.object().shape({
    display_name: yup
      .string()
      .required(t('editPanel.formErrors.name.required'))
      .max(25, t('editPanel.formErrors.name.maxLength')),
    notification_threshold: yup
      .number()
      .typeError(t('editPanel.formErrors.notificationThreshold.type'))
      .required(t('editPanel.formErrors.notificationThreshold.required'))
      .min(30, t('editPanel.formErrors.notificationThreshold.minLength'))
      .max(300, t('editPanel.formErrors.notificationThreshold.maxLength')),
    critical_window_interval: yup
      .number()
      .typeError(t('editPanel.formErrors.criticalWindow.type'))
      .required(t('editPanel.formErrors.criticalWindow.required'))
      .min(1, t('editPanel.formErrors.criticalWindow.minLength'))
      .max(yup.ref('notification_threshold'), t('editPanel.formErrors.criticalWindow.maxLength'))
  })

  const handleClickActivateProject = () => {
    setActivateModalOpen(true)
  }
  const handleClickRenewCert = () => {
    setRenewCertModalOpen(true)
  }

  const handleClickConnectDownloads = () => {
    setConnectDownloadsModalOpen(true)
  }

  const onSubmit = async (formData: ConnectSettingFormType) => {
    patchMutation.mutate(
      { id: connectSettingId, payload: formData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.ConnectSettings])
          notify.success(t('toasters.updateSuccess'))
          onClose()
        },
        onError: () => {
          notify.error(t('toasters.updateFailure'))
        }
      }
    )
  }

  const resetForm = () => {
    methods.reset()
  }

  const methods = useForm<ConnectSettingFormType>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const {
    formState: { isDirty, isSubmitting },
    control,
    getValues,
    register,
    formState: { errors }
  } = methods

  return (
    <>
      <RenewCertificateModal open={renewCertModalOpen} setOpen={setRenewCertModalOpen} item={data} />
      <ConnectDownloadsModal open={connectDownloadsModalOpen} setOpen={setConnectDownloadsModalOpen} item={data} />
      <ActivateProjectModal open={activateModalOpen} setOpen={setActivateModalOpen} item={data} />

      <EditPanel
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        loading={isLoading}
        onClose={onClose}
        title={t('editPanel.headerTitle')}
        onSubmit={methods.handleSubmit(onSubmit)}
        onReset={() => {
          resetForm()
        }}
        headerItems={[
          <Menu
            align="end"
            trigger={
              <IconButton
                icon="more-vertical"
                disabled={data?.status.toLowerCase() === 'pending'}
                label={t('editPanel.moreOptions')}
                tipPlacement="bottom"
                onClick={e => e.stopPropagation()}
              />
            }
          >
            {data?.status.toLowerCase() === 'inactive' && (
              <MenuListItem
                label={t('editPanel.tooltips.activate')}
                icon={'play'}
                onClick={() => handleClickActivateProject()}
              />
            )}
            {data?.status.toLowerCase() === 'active' && (
              <MenuListItem
                label={t('editPanel.tooltips.renewCert')}
                icon={'refresh'}
                onClick={() => handleClickRenewCert()}
              />
            )}
            {data?.status.toLowerCase() === 'active' && (
              <MenuListItem
                label={t('editPanel.tooltips.additionalDownloads')}
                icon={'download'}
                onClick={() => handleClickConnectDownloads()}
              />
            )}
          </Menu>
        ]}
      >
        <Box>
          <FormProvider {...methods}>
            <form>
              {false && (
                <Box margin={{ bottom: '16px' }}>
                  <Message message={'sad'} type="error" />
                </Box>
              )}
              <FormWrapper>
                <Controller
                  name="display_name"
                  defaultValue={data?.display_name}
                  control={control}
                  render={() => (
                    <TextInput
                      {...register('display_name')}
                      label={'Name'}
                      required
                      hasError={!!errors.display_name}
                      inlineError={errors?.display_name?.message}
                    />
                  )}
                />
              </FormWrapper>
            </form>
          </FormProvider>
        </Box>

        <Accordion initialActiveIndex={[0]}>
          <AccordionPanel label={t('editPanel.notifications.title')} icon="notifications" iconColor="text-light">
            <Controller
              name="notification_threshold"
              defaultValue={data?.notification_threshold}
              control={control}
              render={() => (
                <TextInput
                  {...register('notification_threshold')}
                  required
                  hasError={!!errors.notification_threshold}
                  label={t('editPanel.notifications.notifyThreshold.label')}
                  tooltipText={t('editPanel.notifications.notifyThreshold.helpText')}
                  inlineError={errors?.notification_threshold?.message}
                />
              )}
            />

            <Controller
              name="critical_window_interval"
              defaultValue={data?.critical_window_interval}
              control={control}
              render={() => (
                <TextInput
                  {...register('critical_window_interval')}
                  required
                  hasError={!!errors.critical_window_interval}
                  label={t('editPanel.notifications.criticalThreshold.label')}
                  tooltipText={t('editPanel.notifications.criticalThreshold.helpText')}
                  inlineError={errors?.critical_window_interval?.message}
                />
              )}
            />

            <TextArea
              value={data?.notification_cadence}
              label={t('editPanel.notifications.notifyCadence.label')}
              tooltipText={t('editPanel.notifications.notifyCadence.helpText')}
              disabled
            />

            <Controller
              name="mute_notifications"
              defaultValue={data?.mute_notifications}
              control={control}
              render={() => (
                <Checkbox
                  {...register('mute_notifications')}
                  checked={Boolean(getValues('mute_notifications'))}
                  label={t('editPanel.notifications.mute.label')}
                  helpText={t('editPanel.notifications.mute.helpText')}
                />
              )}
            />
          </AccordionPanel>

          <AccordionPanel label={t('editPanel.otherDetails')} icon="info" iconColor="text-light">
            <TextArea
              value={`${data?.created_at ? format(new Date(data.created_at), 'E, do MMM yyyy HH:mm') : ''}`}
              label={t('editPanel.accordion.createdAt')}
              disabled
            />
            <TextArea
              value={`${data?.updated_at ? format(new Date(data.updated_at), 'E, do MMM yyyy HH:mm') : ''}`}
              label={t('editPanel.accordion.updatedAt')}
              disabled
            />
            <TextArea value={data?.updater} label={t('editPanel.accordion.lastUpdatedBy')} disabled />
          </AccordionPanel>
        </Accordion>
      </EditPanel>
    </>
  )
}
