import {
  FieldValue,
  RunbookShowRunbook,
  RunbookTeam,
  RunbookVersion,
  TaskBase,
  TaskCreateTask,
  TaskShowTask,
  User
} from './types'
import { apiClient } from '../api'
import {
  RunbookTaskBulkSkipResponse,
  RunbookTaskCreateResponse,
  RunbookTaskFinishResponse,
  RunbookTaskStartResponse,
  RunbookTaskUpdateResponse
} from '../api/data-providers/runbook-types'

export type TaskGetTaskResponseMeta = {
  permissions: {
    update: [number?]
    destroy: [number?]
    skip: [number?]
    modify_comms: [number?]
    modify_duration: [number?]
  }
  version_data: Pick<
    RunbookVersion,
    | 'id'
    | 'start_planned'
    | 'streams_count'
    | 'tasks_count'
    | 'completed_tasks_count'
    | 'completion'
    | 'timing_mode'
    | 'start_scheduled'
    | 'end_scheduled'
    | 'end_actual'
    | 'end_forecast'
    | 'start_actual'
    | 'end_planned'
    | 'start_display'
    | 'end_display'
    | 'duration_diff'
    | 'duration_display'
    | 'start_diff'
    | 'end_diff'
    | 'resumed_at'
    | 'paused_at'
    | 'cancelled_at'
    | 'stage'
    | 'runbook_id'
  >
}
export type TaskGetTaskResponse = {
  meta: TaskGetTaskResponseMeta
  task: TaskShowTask
}

export const getTask = async (
  runbookId: number | string,
  runbookVersionId: number | string,
  taskId: number | string
) => {
  const { data } = await apiClient.get<TaskGetTaskResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}`
  })

  return data
}

export type TaskEditTaskPayload = {
  task: Pick<
    TaskBase,
    | 'id'
    | 'name'
    | 'description'
    | 'duration'
    | 'stream_id'
    | 'task_type_id'
    | 'start_requirements'
    | 'end_requirements'
    | 'start_fixed'
    | 'end_fixed'
    | 'message'
    | 'level'
    | 'disable_notify'
    | 'field_values_attributes'
    | 'auto_finish'
  >
  predecessors: number[]
  recipients: {
    users: number[]
    runbook_teams: number[]
  }
  users: number[]
  runbook_teams: number[]
}

export const setTask = async (
  runbookId: number | string,
  runbookVersionId: number | string,
  taskId: number | string,
  payload: TaskEditTaskPayload
) => {
  const { data } = await apiClient.patch<TaskEditTaskPayload, RunbookTaskUpdateResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}`,
    data: payload
  })

  return data
}

export type TaskStartPayload = {
  taskId?: number
  override: boolean
  field_values_attributes: FieldValue[]
  selected_successor_ids: number[]
}

export const startTask = async (
  runbookId: number | string,
  runbookVersionId: number | string,
  taskId: number | string,
  payload: TaskStartPayload
) => {
  const { data } = await apiClient.patch<TaskStartPayload, RunbookTaskStartResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/start`,
    data: payload
  })

  return data
}

export type TaskFinishPayload = {
  taskId?: number
  override: boolean
  field_values_attributes: FieldValue[]
  selected_successor_ids: number[]
}

export const finishTask = async (
  runbookId: number | string,
  runbookVersionId: number | string,
  taskId: number | string,
  payload: TaskStartPayload
) => {
  const { data } = await apiClient.patch<TaskFinishPayload, RunbookTaskFinishResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/finish`,
    data: payload
  })

  return data
}

export type TaskBulkSkipPayload = {
  comment: string
  task_ids: number[]
}

export const bulkSkipTasks = async (
  runbookId: number | string,
  runbookVersionId: number | string,
  payload: TaskBulkSkipPayload
) => {
  const { data } = await apiClient.patch<TaskBulkSkipPayload, RunbookTaskBulkSkipResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/bulk_skip`,
    data: payload
  })

  return data
}

export type TaskCreatePayload = {
  task: TaskCreateTask
  base_task_id: null | number
  successors: number[]
  predecessors: number[]
  users: User[]
  runbook_teams: RunbookTeam[]
  integration_events: any[] // TODO find out what this is
  runBook?: RunbookShowRunbook // yes the property name is correct..
}

export const createTask = async (
  runbookId: number | string,
  runbookVersionId: number | string,
  payload: TaskCreatePayload
) => {
  const { data } = await apiClient.post<TaskCreatePayload, RunbookTaskCreateResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks`,
    data: payload
  })

  return data as RunbookTaskCreateResponse
}

export type TaskTestPayload = {
  message: string | null
  type: 'comms_email' | 'comms_sms'
}

export const testTaskComms = async (
  runbookId: number | string,
  runbookVersionId: number | string,
  taskId: number | string,
  payload: TaskTestPayload
) => {
  return await apiClient.patch<TaskTestPayload, any>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/test`,
    data: payload
  })
}
