import { SyntheticEvent, useCallback } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'

import { Box, Button, Checkbox, IconButton, Text, Tooltip } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  filteredTaskListDataState,
  useCanCreateRootTask,
  useCanSkipTask,
  useSelectedIdsValue,
  useSetSelectedIds,
  useSetToggleTaskCreateState,
  useTaskProgression
} from 'main/recoil/runbook'

export const TaskListHeader = () => {
  const { t } = useLanguage('tasks')
  const [filteredTaskIds] = useRecoilValue(filteredTaskListDataState)
  const { selectedIdsToggleAll } = useSetSelectedIds()
  const canCreateRootTask = useCanCreateRootTask()
  const canSkipTask = useCanSkipTask()
  const selectedIds = useSelectedIdsValue()
  const toggleTaskCreate = useSetToggleTaskCreateState()
  const { onSkipTasks } = useTaskProgression()

  const isSelectMode = !!selectedIds.length
  const allTasksSelected = selectedIds.length === filteredTaskIds.length

  const handleChangeSelectAll = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      selectedIdsToggleAll()
    },
    [selectedIdsToggleAll]
  )

  const handleClickCreateTask = () => {
    toggleTaskCreate({ fromPredecessorId: 0 })
  }

  return (
    <Box direction="row" align="center" gap="medium" height={{ min: '64px' }}>
      {filteredTaskIds.length > 0 && (
        <Box direction="row">
          <Box
            align="center"
            justify="center"
            height="40px"
            width={{ width: '40px', min: '40px' }}
            margin={{ left: '6px' }}
          >
            <Tooltip
              placement="top"
              content={isSelectMode ? t('bulkActionTooltip.deselect') : t('bulkActionTooltip.select')}
            >
              <Checkbox
                data-testid="task-select-all"
                width={16}
                checked={isSelectMode && allTasksSelected}
                indeterminate={isSelectMode && !allTasksSelected}
                onChange={handleChangeSelectAll}
                onClick={e => e.stopPropagation()}
              />
            </Tooltip>
          </Box>
          {isSelectMode ? (
            <Box direction="row" gap="xsmall">
              {canSkipTask && (
                <IconButton
                  tertiary
                  label={t('bulkActionTooltip.skip')}
                  data-testid="bulk-skip-task-button"
                  icon="skip"
                  onClick={() => onSkipTasks()}
                  tipPlacement="top"
                />
              )}
              <SingleLineText data-testid="task-select-all-label">{`${selectedIds.length} ${t(
                'list.header.selected'
              )}`}</SingleLineText>
            </Box>
          ) : (
            <SingleLineText data-testid="task-select-all-label" margin={{ left: '6px' }}>
              {t('list.header.checkboxLabel')}
            </SingleLineText>
          )}
        </Box>
      )}
      {canCreateRootTask && (
        <Box>
          <Button
            data-testid="create-task-no-predecessors-button"
            tertiary
            disableTooltip
            icon="add"
            onClick={handleClickCreateTask}
            label="Create task"
          />
        </Box>
      )}
    </Box>
  )
}

const SingleLineText = styled(Text).attrs(() => ({
  alignSelf: 'center',
  color: 'text-light'
}))`
  overflow: hidden;
  white-space: nowrap;
`
