import { useMemo, useState } from 'react'

import { Box, Checkbox, Modal, Text, useNotify } from '@cutover/react-ui'
import { useCurrentUser, useUpdateFrontendUserSettingState } from 'main/recoil/current-user'
import {
  useAccountMetaProperty,
  useAccountProperty,
  useRunbookId,
  useSetModalActiveState,
  useTaskListTask,
  useTaskProgression,
  useTaskProgressionState
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import { FrontendUserSettingType } from 'main/services/queries/types'
import { updateFrontendUserSetting } from 'main/services/queries/use-frontend-user-setting'

type TaskFinishConfirmProps = {
  id: number
  onClose: () => void
}

export const TaskFinishConfirm = ({ id, onClose }: TaskFinishConfirmProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskFinishConfirmModal' })
  const { resolveProgressionModalCallback, startOrFinishTask } = useTaskProgression()
  const { modalContinue } = useSetModalActiveState()
  const [userPreferenceHideModal, setUserPreferenceHideModal] = useState(false)

  const runbookId = useRunbookId()
  const currentUser = useCurrentUser()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const progressionState = useTaskProgressionState(id)! // if we've gotten to this modal then there has to be a progression state
  const accountName = useAccountProperty({ attribute: 'name' })
  const task = useTaskListTask(id)
  const customFields = useAccountMetaProperty({ attribute: 'custom_fields' })

  const frontEndUserSetting: FrontendUserSettingType = currentUser?.frontend_user_setting || {}
  const updateFrontendUserSettingState = useUpdateFrontendUserSettingState()
  const notify = useNotify()

  const nextModal = useMemo(
    () =>
      resolveProgressionModalCallback({
        task,
        accountName,
        progressionState,
        customFields,
        currentUser,
        runbookId,
        from: { id, type: 'task-finish-confirm' }
      }),
    [task, accountName, progressionState, customFields, currentUser, runbookId, resolveProgressionModalCallback]
  )

  const handleSubmit = async () => {
    nextModal ? modalContinue(nextModal, { id, type: 'task-finish-confirm' }) : startOrFinishTask(id)

    if (userPreferenceHideModal) {
      await handleUserPreferenceUpdate()
    }
  }

  const handleUserPreferenceUpdate = async () => {
    const payload: FrontendUserSettingType = {
      type: 'FrontendUserSetting',
      data: {}
    }
    const hiddenRunbooks = [...(frontEndUserSetting.data?.task_finish_confirm_hidden || [])]
    hiddenRunbooks.push(runbookId)
    payload.data = { ...frontEndUserSetting.data, task_finish_confirm_hidden: hiddenRunbooks }

    if (frontEndUserSetting.id) {
      payload.id = frontEndUserSetting.id
    }

    try {
      const response = await updateFrontendUserSetting(payload)
      if (response && response?.user_setting) {
        updateFrontendUserSettingState({ payload: response.user_setting.data })
      }
    } catch {
      notify.error(t('userPreferenceError'))
    }
  }

  return (
    <Modal
      data-testid="modal-task-finish-confirm"
      animate={nextModal ? 'in' : true}
      title={t('title')}
      confirmText={t('confirmText')}
      confirmIcon="chevron-right"
      open
      key="task-finish-confirm-form"
      onClickConfirm={handleSubmit}
      onClose={onClose}
    >
      <Box direction="column" gap="medium">
        <Text>{t('content')}</Text>
        <Checkbox label={t('doNotShowButton')} onChange={e => setUserPreferenceHideModal(e.currentTarget.checked)} />
      </Box>
    </Modal>
  )
}
