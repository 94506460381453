import { Box, ListItem, TaskTypeIcon, Text } from '@cutover/react-ui'
import { useEditTaskPanel } from 'main/context/panel-context'
import { useTaskListTask, useTaskListTaskStream, useTaskListTaskTaskType } from 'main/recoil/runbook'

export const TaskLineItem = ({
  taskId,
  onClickRemove,
  active,
  hasClickEvent,
  role,
  disabled
}: {
  taskId: number
  onClickRemove?: () => void
  active?: boolean
  hasClickEvent?: boolean
  role?: string
  disabled?: boolean
}) => {
  const task = useTaskListTask(taskId)
  const { name, internal_id, id } = task
  const { openPanel } = useEditTaskPanel()
  const taskType = useTaskListTaskTaskType(id)
  const stream = useTaskListTaskStream(id)

  return taskType && stream ? (
    <ListItem
      tabIndex={undefined}
      size="small"
      startComponent={<TaskTypeIcon size="medium" icon={taskType?.icon} color={stream?.color} />}
      onClickRemove={onClickRemove}
      active={active}
      onClick={hasClickEvent ? () => openPanel({ taskId: id }) : undefined}
      a11yTitle={name}
      role={role ?? 'listitem'}
      disabled={disabled}
    >
      <Box>
        <Text truncate="tip" tipPlacement="top">
          <Text color="text-light">#{internal_id}</Text> {name}
        </Text>
      </Box>
    </ListItem>
  ) : null
}
