import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { ConnectSetting, ConnectStatus } from './types/connect'
import { useSetPermissions } from './use-permissions'
import { apiClient, ApiError } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'

export function useConnectSetting(connectId: string | undefined, options: UseQueryOptions<ConnectSetting, Error> = {}) {
  return useQuery<ConnectSetting, Error, ConnectSetting>(
    [QueryKeys.ConnectSettings, connectId],
    async () => {
      const { data } = await apiClient.get<ConnectSetting>({
        url: `cutover_connect/settings/${connectId}`,
        responseProperty: 'connect_setting',
        convertCase: false
      })
      return data
    },
    { enabled: connectId !== undefined, ...options }
  )
}

type ConnectSettingsResponse = {
  connect_settings: ConnectSetting[]
  meta: {
    permissions: { [key: string]: number[] }
  }
}

export function useConnectSettingsQuery() {
  const queryClient = useQueryClient()
  const setPermissions = useSetPermissions('connect-settings')

  return useQuery<ConnectSettingsResponse, Error, ConnectSettingsResponse>(
    [QueryKeys.ConnectSettings],
    async () => {
      const { data } = await apiClient.get<ConnectSettingsResponse>({
        url: 'cutover_connect/settings'
      })
      return data
    },
    {
      onSuccess: data => {
        setPermissions(data.meta.permissions)
        data.connect_settings.forEach(d => {
          queryClient.setQueryData([QueryKeys.ConnectSettings, d.id.toString()], d)
        })
      }
    }
  )
}

type ConnectPatchResponse = {
  connect_setting: ConnectSetting
  meta: {
    additional_operation_results: any
  }
}

type ConnectPatchPayload = {
  status?: ConnectStatus
  additional_operations?: { [x: string]: {} }
  display_name?: string
}

type ConnectCreateResponse = {
  connect_setting: ConnectSetting
}

type FetchVersionsResponse =
  | [
      {
        label: string
        value: string
      }
    ]
  | []

export function useConnectSettingsPatch() {
  return useMutation<ConnectPatchResponse, ApiError, { id: number; payload: ConnectPatchPayload }>(
    async ({ id, payload }: { id: number; payload: ConnectPatchPayload }) => {
      const response = await apiClient.patch<ConnectPatchPayload, ConnectPatchResponse>({
        url: `cutover_connect/settings/${id}`,
        data: payload
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    }
  )
}

export function useConnectSettingsCreate() {
  return useMutation<ConnectCreateResponse, Error, { payload: ConnectSetting }>(
    async ({ payload }: { payload: ConnectSetting }) => {
      const response = await apiClient.post<ConnectSetting, ConnectCreateResponse>({
        url: 'cutover_connect/settings',
        data: payload
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    }
  )
}

export function useFetchConnectVersions() {
  return useQuery<FetchVersionsResponse, Error>(QueryKeys.ConnectVersions, async () => {
    const { data } = await apiClient.get<FetchVersionsResponse>({
      url: 'cutover_connect/settings/available_versions'
    })

    return data
  })
}

export function useFetchSettingsPopulated() {
  return useQuery<{ populated: boolean }, Error>(QueryKeys.ConnectSettingsAvailable, async () => {
    const { data } = await apiClient.get<{ populated: boolean }>({
      url: 'cutover_connect/settings/settings_populated'
    })

    return data
  })
}
