import { useMemo, useState } from 'react'

import { Box, Modal, RadioboxList, SanitizedString, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  useAccountMetaProperty,
  useAccountProperty,
  useRunbookId,
  useSetModalActiveState,
  useTaskListTask,
  useTaskProgression,
  useTaskProgressionState
} from 'main/recoil/runbook'
import { useCurrentUser } from 'main/recoil/current-user'

export const TaskOverride = ({ onClose, id }: { id: number; onClose: () => void }) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskOverrideModal' })
  const [override, setOverride] = useState(false)
  const { resolveProgressionModalCallback, startOrFinishTask } = useTaskProgression()
  const { modalContinue } = useSetModalActiveState()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const progressionState = useTaskProgressionState(id)! // if we've gotten to this modal then there has to be a progression state
  const accountName = useAccountProperty({ attribute: 'name' })
  const task = useTaskListTask(id)
  const customFields = useAccountMetaProperty({ attribute: 'custom_fields' })
  const currentUser = useCurrentUser()
  const runbookId = useRunbookId()

  const nextModal = useMemo(
    () =>
      resolveProgressionModalCallback({
        task,
        accountName,
        progressionState,
        customFields,
        currentUser,
        runbookId,
        from: { id, type: 'task-override' }
      }),
    [task, accountName, progressionState, customFields, currentUser, runbookId, resolveProgressionModalCallback]
  )

  const handleConfirm = () => {
    if (nextModal) return modalContinue(nextModal, { id, type: 'task-override', context: { override } })

    return startOrFinishTask(id, { override: progressionState?.optional ? override : true })
  }

  return (
    <Modal
      data-testid="modal-task-override"
      open
      animate={nextModal ? 'in' : true}
      onClose={onClose}
      confirmIcon="chevron-right"
      confirmText={t('confirmText')}
      title={t(progressionState.stage === 'startable' ? 'titleStart' : 'titleFinish')}
      onClickConfirm={handleConfirm}
    >
      <Box>
        {progressionState.optional ? (
          <Box>
            <SanitizedString input={t('contentOptional')} />
            <Text
              size="small"
              color="text-light"
              css={`
                margin-top: 12px;
              `}
            >
              {t('wishToOverride')}
            </Text>
            <RadioboxList
              name="override"
              direction="row"
              options={[
                {
                  value: 'continue',
                  label:
                    progressionState.stage === 'startable' ? t('startWithoutOverriding') : t('finishWithoutOverriding')
                },
                {
                  value: 'override',
                  label: progressionState.stage === 'startable' ? t('startAndOverride') : t('finishAndOverride')
                }
              ]}
              defaultValue="continue"
              onChange={e => {
                setOverride((e.target.value as 'continue' | 'override') === 'override')
              }}
            />
          </Box>
        ) : (
          <Text>{t('content', { stage: progressionState.stage === 'startable' ? 'start' : 'end' })}</Text>
        )}
      </Box>
    </Modal>
  )
}
