import { useMemo } from 'react'
import { format, fromUnixTime } from 'date-fns'

import { Box, Modal, Text } from '@cutover/react-ui'
import {
  useAccountMetaProperty,
  useAccountProperty,
  useRunbookId,
  useRunbookPermission,
  useSetModalActiveState,
  useTaskListTask,
  useTaskProgression,
  useTaskProgressionState
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import { useCurrentUser } from 'main/recoil/current-user'

type TaskFixedStartOverrideProps = {
  id: number
  onClose: () => void
}

export const TaskFixedStartOverride = ({ id, onClose }: TaskFixedStartOverrideProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskFixedStartOverrideModal' })
  const canUpdate = useRunbookPermission({ attribute: 'update' })
  const task = useTaskListTask(id)
  const startFixedTime = format(fromUnixTime(task.start_fixed as number), 'dd/MM/yy HH:mm')
  const { modalClose, modalContinue } = useSetModalActiveState()
  const { resolveProgressionModalCallback, startOrFinishTask } = useTaskProgression()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const progressionState = useTaskProgressionState(id)! // if we've gotten to this modal then there has to be a progression state
  const accountName = useAccountProperty({ attribute: 'name' })
  const customFields = useAccountMetaProperty({ attribute: 'custom_fields' })
  const currentUser = useCurrentUser()
  const runbookId = useRunbookId()

  const nextModal = useMemo(
    () =>
      resolveProgressionModalCallback({
        task,
        accountName,
        progressionState,
        customFields,
        currentUser,
        runbookId,
        from: { id, type: 'task-override-fixed-start' }
      }),
    [task, accountName, progressionState, customFields, currentUser, runbookId, resolveProgressionModalCallback]
  )

  const handleConfirm = () => {
    if (!canUpdate) return modalClose()

    return nextModal ? modalContinue(nextModal, { id, type: 'task-override-fixed-start' }) : startOrFinishTask(id)
  }

  return (
    <Modal
      data-testid="modal-task-override-start-fixed"
      open
      animate={nextModal ? 'in' : true}
      title={t('title')}
      confirmText={t(canUpdate ? 'confirmTextAdmin' : 'confirmText')}
      confirmIcon={canUpdate ? 'caret-right' : undefined}
      onClickConfirm={handleConfirm}
      onClose={onClose}
    >
      <Box>
        <Text>{t('content', { startFixedTime })}</Text>
        {!canUpdate && <Text>{t('contentNotAdmin')}</Text>}
      </Box>
    </Modal>
  )
}
