import { HTMLAttributes } from 'react'
import { add as addToDate, getHours, getMinutes, startOfDay } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

import { NumberField } from './number-field'
import { Box } from '../../layout'
import { Text } from '../../typography'
import { FormField } from '../internal/form-field'

export type TimePickerProps = {
  value: Date | null
  name?: string
  onChange: (date: Date | null) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  disabled?: boolean
  readOnly?: boolean
  hasError?: boolean
  timezone?: string | null
  plain?: boolean
  required?: boolean
  label?: string
  a11yTitle?: string
  'data-testid'?: string
  hourInputRef?: any
}

export const TimePickerPlain = ({
  value,
  onChange,
  disabled,
  readOnly,
  timezone,
  onClick,
  a11yTitle,
  hourInputRef
}: Omit<HTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'onClick'> & TimePickerProps) => {
  const timezoneAdjustedValue = timezone && value ? utcToZonedTime(value, timezone) : value ?? new Date()

  const handleChange = (nextValue: number, time: 'minutes' | 'hours') => {
    if (!isInRange(nextValue, time)) return timezoneAdjustedValue

    const startTime = addToDate(startOfDay(timezoneAdjustedValue), {
      [time === 'hours' ? 'minutes' : 'hours']:
        time === 'hours' ? getMinutes(timezoneAdjustedValue) : getHours(timezoneAdjustedValue)
    })

    const updatedDate = addToDate(startTime, {
      [time === 'hours' ? 'hours' : 'minutes']: nextValue
    })

    onChange(timezone ? zonedTimeToUtc(updatedDate, timezone) : updatedDate)
  }

  return (
    <Box align="center" direction="row">
      <NumberField
        isDisabled={disabled}
        isReadOnly={readOnly}
        onChange={value => handleChange(value, 'hours')}
        onClick={onClick}
        value={timezoneAdjustedValue ? timezoneAdjustedValue.getHours() : 0}
        label={`${a11yTitle ? a11yTitle + ' ' : ''}hours`}
        formatOptions={{ minimumIntegerDigits: 2 }}
        inputRef={hourInputRef}
      />
      <Text
        alignSelf="center"
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        margin={{ left: '2px' }}
        color={disabled ? 'text-disabled' : 'text'}
      >
        :
      </Text>
      <NumberField
        isDisabled={disabled}
        isReadOnly={readOnly}
        onChange={value => handleChange(value, 'minutes')}
        onClick={onClick}
        value={timezoneAdjustedValue ? timezoneAdjustedValue.getMinutes() : 0}
        label={`${a11yTitle ? a11yTitle + ' ' : ''}minutes`}
        formatOptions={{ minimumIntegerDigits: 2 }}
      />
    </Box>
  )
}

export const TimePicker = (
  props: Omit<HTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'onClick'> & TimePickerProps
) => {
  const { plain = true, hasError, disabled, required, label, a11yTitle } = props
  const defaultLabel = label || 'Select time'

  return plain ? (
    <TimePickerPlain {...props} />
  ) : (
    <FormField
      hasError={hasError}
      clickable={false}
      disabled={disabled}
      startIcon="time"
      labelProps={{ 'aria-label': a11yTitle ?? label }}
    >
      <Box direction="row" align="center" justify="between" fill pad={{ top: '4px', bottom: '4px' }}>
        <Text
          color={disabled ? 'text-disabled' : hasError ? 'error' : 'text-light'}
          margin={{ right: '10px', left: '4px' }}
        >
          {` ${defaultLabel}${required ? ' *' : ''}`}
        </Text>
        <TimePickerPlain {...props} />
      </Box>
    </FormField>
  )
}

const isInRange = (val: number, time: 'hours' | 'minutes') => {
  if (val < -1) return false
  return time === 'hours' ? val <= 24 : val <= 60
}
