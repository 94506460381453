import { useCallback } from 'react'
import { useRecoilTransaction_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { extend, pullAllWith } from 'lodash'

import {
  useProcessRunbookUpdateResponse,
  useProcessRunbookVersionCsvImportResponse,
  useProcessStreamCreateResponse,
  useProcessStreamDeleteResponse,
  useProcessStreamUpdateResponse
} from './runbook-operations'
import { useTaskNotifications } from './tasks-notifications'
import {
  runbookResponseState_INTERNAL,
  runbookVersionResponseState_INTERNAL,
  taskListResponseState_INTERNAL
} from '../models'
import {
  RunbookResponse,
  RunbookRunCancelResponse,
  RunbookRunCreateResponse,
  RunbookRunPauseResponse,
  RunbookRunResumeResponse,
  RunbookStreamCreateResponse,
  RunbookStreamDestroyResponse,
  RunbookStreamUpdateResponse,
  RunbookTaskBulkCreateResponse,
  RunbookTaskBulkDeleteResponse,
  RunbookTaskBulkSkipResponse,
  RunbookTaskUpdateResponse,
  RunbookUpdateResponse,
  RunbookVersionCreateResponse,
  RunbookVersionImportResponse
} from 'main/services/api/data-providers/runbook-types'
import { useNavigateToVersion } from 'main/recoil/shared/nav-utils'
import { useProcessMergeResponse, useProcessRunResponse } from './use-process-run-response'
import { updateRunbookData, updateTasksAndVersion } from './shared-updates'
import { RunbookVersion, TaskShowTask } from 'main/services/queries/types'
import { taskEditUpdatedTaskId } from '../models/tasks/task-edit'
import { RunbookVersionData } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'

export const useHandleRunbookResponse = () => {
  const processTaskUpdateResponse = useProcessTaskUpdateResponse()
  const processRunResponse = useProcessRunResponse()
  const processMergeResponse = useProcessMergeResponse()
  const processTaskBulkSkipResponse = useProcessTaskBulkSkipResponse()
  const processTaskBulkCreateResponse = useProcessTaskBulkCreateResponse()
  const processTaskBulkDeleteResponse = useProcessTaskBulkDeleteResponse()
  const processRunbookUpdateResponse = useProcessRunbookUpdateResponse()
  const processRunbookVersionCsvImportResponse = useProcessRunbookVersionCsvImportResponse()
  const processStreamCreateResponse = useProcessStreamCreateResponse()
  const processStreamUpdateResponse = useProcessStreamUpdateResponse()
  const processStreamDeleteResponse = useProcessStreamDeleteResponse()

  const processRunbookVersionCreateResponse = useProcessRunbookVersionResponse()
  const navigateToVersion = useNavigateToVersion()
  const notifyTaskResponse = useTaskNotifications()

  // Please do not early return from the switch. It is helpful to put the log marking the finish of the websocket process for
  // performance testing at the end of the function but it is not possible to wrap the contents if there are multiple returns.
  // Don't forget to place `break` after each case, both the response class and method level switches.
  return useCallback(
    async (response: RunbookResponse) => {
      switch (response?.meta.headers.request_class) {
        case 'Task':
          // @ts-ignore version_data does not exist on all task response types (not quick_update) but this conditional is based on its presence value
          if (response.meta.version_data?.stage === 'complete') {
            window.dispatchEvent(new CustomEvent('refresh-data-store', { detail: { type: 'runbook-version' } }))
          }

          switch (response.meta.headers.request_method) {
            case 'update':
            case 'create':
            case 'start':
            case 'finish':
              processTaskUpdateResponse(response as RunbookTaskUpdateResponse)
              break
            case 'bulk_delete':
              processTaskBulkDeleteResponse(response as RunbookTaskBulkDeleteResponse)
              break
            case 'bulk_skip':
              processTaskBulkSkipResponse(response as RunbookTaskBulkSkipResponse)
              break
            case 'bulk_create':
              processTaskBulkCreateResponse(response as RunbookTaskBulkCreateResponse)
              break
            default:
              break
          }

          notifyTaskResponse(response)
          break
        case 'Run':
          await processRunResponse(
            response as
              | RunbookRunCreateResponse
              | RunbookRunPauseResponse
              | RunbookRunResumeResponse
              | RunbookRunCancelResponse
          )
          break
        case 'Runbook':
          switch (response.meta.headers.request_method) {
            case 'update':
              processRunbookUpdateResponse(response as RunbookUpdateResponse)
              break
            case 'merge':
              await processMergeResponse(response as any)
              break
            default:
              break
          }
          break
        // TODO: when is this? Just when creating a new version manually?
        case 'RunbookVersion':
          switch (response.meta.headers.request_method) {
            case 'create':
              processRunbookVersionCreateResponse(response as RunbookVersionCreateResponse)
              const currentVersionId = (response as RunbookVersionCreateResponse).runbook_version.id
              navigateToVersion(currentVersionId)
              break
            case 'runbook_version_import':
              await processRunbookVersionCsvImportResponse(response as RunbookVersionImportResponse)
              break
            default:
              break
          }
          break
        case 'Stream':
          switch (response.meta.headers.request_method) {
            case 'create':
              processStreamCreateResponse(response as RunbookStreamCreateResponse)
              break
            case 'update':
              processStreamUpdateResponse(response as RunbookStreamUpdateResponse)
              break
            case 'destroy':
              processStreamDeleteResponse(response as RunbookStreamDestroyResponse)
              break
            default:
              break
          }
          break
        default:
          break
      }
    },
    [
      // Each process function should be added here as it's included in the above function.
      // You *must* also memoize the process function's return by wrapping that in a useCallback.
      processTaskUpdateResponse,
      processRunResponse,
      processMergeResponse,
      processTaskBulkSkipResponse,
      processTaskBulkCreateResponse,
      processTaskBulkDeleteResponse,
      processRunbookUpdateResponse,
      processRunbookVersionCsvImportResponse,
      processStreamCreateResponse,
      processStreamUpdateResponse,
      processStreamDeleteResponse,
      processRunbookVersionCreateResponse,
      navigateToVersion,
      notifyTaskResponse
    ]
  )
}

export const useProcessRunbookVersionResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookVersionCreateResponse) => {
    const { set } = transactionInterface

    // FIXME: seems wrong - doesn't update the runbook's runbook_version_id
    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        extend(draftRunbookResponse.runbook, { current_version: response.runbook_version })
      })
    )

    // FIXME: seems wrong - can't just update our runbook version state with the runbook_version from the response.
    // what if this is a websocket handling and they aren't viewing the current version? State will not match the URL
    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
      })
    )
  })
}
// NOTE: currently used for handling methods: `update`, `start`, `finish`, `create`. Separated this out here because
// it will need to be split up when differences in the response meta are handled
// TODO: does not currently handle possible_changed_fields updates (`update` method)
// TODO: does not handle updating task permissions updates (`start` and `finish` methods)
export const useProcessTaskUpdateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInteface => (response: RunbookTaskUpdateResponse) => {
    const { set } = transactionInteface

    if (response.meta.headers.request_method === 'update') {
      set(taskEditUpdatedTaskId, response.task as TaskShowTask)
    }

    // @ts-ignore
    const versionData = response.meta.version_data as RunbookVersionData | undefined

    // If the task is finished and the version is complete, we refetched the runbook version to get updated permissions.
    // In that case, we can't also update the local date version so we only update the runbook's current_versiom with
    // the version_data
    if (versionData?.stage === 'complete') {
      // @ts-ignore
      delete response.meta.headers.version_data
      updateRunbookData(transactionInteface)({ current_version: versionData as RunbookVersion })
    }

    updateTasksAndVersion(transactionInteface)({
      changedTasks: response.meta.changed_tasks,
      versionData: response.meta.version_data
    })
  })
}

// NOTE: keeping this separate because it has unhandled `new_task_ids` in meta respose. If we do not have to handle
// this, can just use the `useProcessTaskUpdateResponse` handler
// TODO: does not handle new_task_ids updates: However, do we need to distinguish this if we're iterating over changed tasks and adding any tasks that aren't there to the task collection
export const useProcessTaskBulkCreateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInteface => (response: RunbookTaskBulkCreateResponse) => {
    updateTasksAndVersion(transactionInteface)({
      changedTasks: response.meta.changed_tasks,
      versionData: response.meta.version_data
    })
  })
}

// Handles skipping either single or multiple tasks
// NOTE: keeping this separate because it has unhandled meta data
// TODO: does not handle comments updates
export const useProcessTaskBulkSkipResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInteface => (response: RunbookTaskBulkSkipResponse) => {
    updateTasksAndVersion(transactionInteface)({
      changedTasks: response.meta.changed_tasks,
      versionData: response.meta.version_data
    })
  })
}

// TODO: does not handle deleted_runbook_component_ids updates
export const useProcessTaskBulkDeleteResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInteface => (response: RunbookTaskBulkDeleteResponse) => {
    const { set } = transactionInteface

    updateTasksAndVersion(transactionInteface)({
      changedTasks: response.meta.changed_tasks,
      versionData: response.meta.version_data
    })

    // performance-wise: make more sense to do this before or after updateTasksAndVersion
    set(taskListResponseState_INTERNAL, prevTaskResponse =>
      produce(prevTaskResponse, draftTaskResponse => {
        pullAllWith(draftTaskResponse.tasks, response.meta.deleted_task_ids, (task, deletedId) => task.id === deletedId)
      })
    )
  })
}
