import { memo, useMemo } from 'react'
import { LiteralUnion } from 'type-fest'

import { TaskTypeIconProps as BaseTaskTypeIconProps, TASK_TYPE_ICON_NAME_MAP, TaskTypeIconName } from '@cutover/icons'
import { useTheme } from '../theme'

export type TaskTypeIconProps = BaseTaskTypeIconProps & { icon: LiteralUnion<TaskTypeIconName, string> }

export const TaskTypeIcon = memo(({ icon, size = 'large', ...props }: TaskTypeIconProps) => {
  const theme = useTheme()
  const cleanedIconName = useMemo(() => {
    const iconName = icon.startsWith('icon_') ? icon.substring(5) : icon
    return iconName.replace(/_/g, '-') as TaskTypeIconName
  }, [icon])

  // Default to "normal" if passed task type icon isn't found
  let Component = TASK_TYPE_ICON_NAME_MAP.normal

  if (!TASK_TYPE_ICON_NAME_MAP.hasOwnProperty(cleanedIconName)) {
    console.warn(
      `TaskTypeIcon: icon ${icon} does not exist in the icon map. Make sure it is added properly to the @cutover/icons package and the generator script is run`
    )
  } else {
    Component = TASK_TYPE_ICON_NAME_MAP[cleanedIconName]
  }

  return Component ? <Component {...props} size={size} theme={theme} /> : null
})
