import { ReactNode, Suspense, useEffect } from 'react'

import { LoadingPanel, PageFilterPanelLoader, PageHeadingLoader, PageSubHeadingLoader } from '@cutover/react-ui'
import { SetDefaultLayoutProps, useToggleRightPanel } from './layout-hooks'
import { Layout } from 'main/components/layout'

export const LayoutLoading = ({
  filterPanel,
  subHeader,
  rightNav,
  children
}: SetDefaultLayoutProps & {
  children?: ReactNode
}) => {
  return (
    <Suspense fallback={<FallbackContent filterPanel={filterPanel} subHeader={subHeader} rightNav={rightNav} />}>
      {children}
    </Suspense>
  )
}

export const FallbackContent = ({ filterPanel, subHeader, rightNav }: SetDefaultLayoutProps) => {
  const toggleRightPanel = useToggleRightPanel()

  // make sure to remove the right panel layout from the screen when loading between pages
  useEffect(() => {
    toggleRightPanel(false)
  }, [toggleRightPanel])

  return (
    <Layout
      header={<PageHeadingLoader />}
      subHeader={subHeader ? <PageSubHeadingLoader /> : null}
      filter={filterPanel ? <PageFilterPanelLoader /> : null}
      rightNavigation={rightNav ? <div></div> : null}
      // children here is what typically has the outlet that the page content is injected into
      // we need to overwrite that with the loading panel while all of the page dependencies load
      children={<LoadingPanel contained />}
    />
  )
}
