import { useState } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'

import { Box, Text, themeColor } from '@cutover/react-ui'
import { formatActivityDetailsValue, renderMessageMarkup } from './activity-helper'
import { ActivityVM } from 'main/services/queries/use-activities'

type ActivityDetailsProps = {
  activity: ActivityVM
}

const DEFAULT_CHANGES_LINES: number = 1
const MIN_CHANGES_LINES: number = 2
export const DEFAULT_MESSAGE_CHARACTERS: number = 800

export const ActivityDetails = ({ activity }: ActivityDetailsProps) => {
  const { changes, display } = activity
  const [showMore, setShowMore] = useState(true)
  const isChangesMinLines = changes && changes?.length > MIN_CHANGES_LINES

  const changesList = showMore && isChangesMinLines ? changes?.slice(0, DEFAULT_CHANGES_LINES) : changes

  const message = showMore ? display?.message?.substring(0, DEFAULT_MESSAGE_CHARACTERS) : display?.message
  const showToggle = isChangesMinLines || (display?.message && display?.message?.length > DEFAULT_MESSAGE_CHARACTERS)

  const renderColumn = (columnValue: string, textWeight: number, columnWidth: string) => {
    const formattedValue = formatActivityDetailsValue(columnValue)
    return (
      <Box alignSelf="start" width={columnWidth} direction="column" gap="xsmall" pad="xxsmall">
        <Text truncate="tip" color="text-light" weight={textWeight} size="13px">
          {parse(formattedValue)}
        </Text>
      </Box>
    )
  }

  return (
    <ActivityDetailsWrapper flex={false} align="start" justify="start" featured={activity.featured}>
      {message && (
        <Box pad="xxsmall">
          <Text margin={'5px'} color="text-light" weight={400} size="13px" css="white-space: normal;">
            {parse(renderMessageMarkup(message) as string)}
          </Text>
        </Box>
      )}
      {changesList?.map((param, key) => (
        <ChangesListWrapper
          alignContent="start"
          key={key}
          direction="row"
          width="medium"
          data-testid={`changes-list-activity-${activity.id}`}
        >
          {param.field && renderColumn(param.field, 600, 'small')}
          {param.value && renderColumn(param.value, 400, 'medium')}
        </ChangesListWrapper>
      ))}
      {showToggle && (
        <Box gap="xsmall" pad="xxsmall" onClick={() => setShowMore(!showMore)}>
          <Text color="primary" weight={400} size="13px">
            {showMore ? '+ Show more' : '- Show less'}
          </Text>
        </Box>
      )}
    </ActivityDetailsWrapper>
  )
}

const ActivityDetailsWrapper = styled(Box)<{ featured: boolean }>`
  border-left: solid 2px ${props => (props.featured ? themeColor('star') : themeColor('bg-2'))};
  margin-left: 74px;
  padding-left: 22px;
  padding-right: 22px;
  min-height: 38px;
`

const ChangesListWrapper = styled(Box)`
  p {
    margin: 0;
  }
`
