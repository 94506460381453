import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { GroupedVirtuoso } from 'react-virtuoso'
import styled, { css } from 'styled-components'

import { Box, IconButton, Loader, media } from '@cutover/react-ui'
import { ActivitySeparator, DayDivider } from './separator'
import { groupActivities, sortActivityData } from './activity-feed-grouping'
import { Activity } from '../activity/activity'
import { ActivityConfig, ActivityVM } from 'main/services/queries/use-activities'

const scrollTopPosition = 1200

export type ActivityFeedProps<DataT> = {
  activities?: ActivityVM[]
  activityConfig?: ActivityConfig[]
  emptyRender?: ReactElement
  getDivider?: (key: string) => string
  getNextPage: () => Promise<DataT>
  hasNextPage: boolean
  perPage?: number
  totalSize: number
  canUpdate: boolean
}

export function ActivityFeed<DataT>({
  activities = [],
  activityConfig = [],
  emptyRender = <p>No results</p>,
  getNextPage,
  hasNextPage,
  canUpdate = false
}: ActivityFeedProps<DataT>) {
  const initialMount = useRef(true)
  const listRef = useRef<any>()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [atBottom, setAtBottom] = useState(false)

  const groupedActivities = groupActivities(activities)
  const { sortedActivities, groupCounts, lastInGroupIndexes } = useMemo(
    () => sortActivityData(groupedActivities),
    [groupedActivities]
  )

  const fetchMore = async (atTop: boolean) => {
    if (atTop && hasNextPage && !initialMount.current) {
      await getNextPage()
      listRef.current.scrollTo({ top: scrollTopPosition })
    }
  }

  const scrollToBottom = () => {
    listRef.current.scrollToIndex({ index: groupedActivities.length - 1, behavior: 'smooth' })
  }

  const atBottomStateHandler = (atBottom: boolean) => {
    const lastActivity = groupedActivities[groupedActivities.length - 1]
    //this is used to prevent glitchy scroll-to-bottom icon button behavior
    //when adding new activity, it has only UI usage
    setAtBottom(lastActivity.new ? true : atBottom)
    lastActivity.new = false
  }

  const renderActivity = (index: number) => {
    return (
      <Activity
        activity={groupedActivities[index]}
        activityConfig={activityConfig}
        previousActivity={groupedActivities[index - 1]}
        canUpdate={canUpdate}
        index={index}
        active={index === activeIndex}
        setActive={(index: number | null) => setActiveIndex(index)}
      />
    )
  }

  useEffect(() => {
    if (initialMount.current) initialMount.current = false
  }, [initialMount])

  return (
    <ActivityFeedWrapper>
      {groupedActivities.length > 1 && !atBottom && (
        <ScrollToBottomWrapper>
          <IconButton
            label="Scroll to bottom"
            onClick={scrollToBottom}
            size="medium"
            icon="arrow-down"
            disableTooltip
          />
        </ScrollToBottomWrapper>
      )}
      {!!groupedActivities.length ? (
        <GroupedVirtuoso
          atTopStateChange={fetchMore}
          atBottomStateChange={bottom => atBottomStateHandler(bottom)}
          components={{
            Header: () =>
              hasNextPage ? (
                <Box pad={'small'}>
                  <Loader />
                </Box>
              ) : null
          }}
          groupCounts={groupCounts}
          groupContent={index => {
            return <ActivitySeparator date={Object.keys(sortedActivities)[index]} index={index} />
          }}
          increaseViewportBy={300}
          initialItemCount={groupedActivities.length}
          initialTopMostItemIndex={groupedActivities.length - 1}
          itemContent={index => {
            return lastInGroupIndexes.includes(index) ? (
              <>
                <DayDivider role="separator" css="margin-bottom: 12px;" />
                {renderActivity(index)}
              </>
            ) : (
              renderActivity(index)
            )
          }}
          ref={listRef}
          followOutput={(isAtBottom: boolean) => (isAtBottom ? 'smooth' : false)}
          data-testid="list"
          style={{
            height: '100%'
          }}
        />
      ) : (
        emptyRender
      )}
    </ActivityFeedWrapper>
  )
}

const ActivityFeedWrapper = styled(Box)`
  height: 100%;
  list-style: none;
  overflow: hidden;
  ${media.sm(css`
    padding: 0;
  `)}
`

const ScrollToBottomWrapper = styled(Box).attrs(() => ({
  background: 'bg',
  round: 'large'
}))`
  position: absolute;
  bottom: 15px;
  right: 7px;
  z-index: 2;
`
