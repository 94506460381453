import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MergeDeep } from 'type-fest'

import { Accordion, Box, Select, TextInput } from '@cutover/react-ui'
import { RunbookEditDisplaySettingsPanel as DisplaySettingsPanel } from './display-settings-panel'
import { RunbookEditOtherDetailsPanel as OtherDetailsPanel } from './other-details-panel'
import { RagStatusFields } from './rag-status-fields'
import { RunbookEditRolesPanel as RolesPanel } from './roles-panel'
import { RunbookEditTemplateSettingsPanel as TemplateSettingsPanel } from './template-settings-panel'
import { RunbookEditTimingModeFields as TimingModeFields } from './timing-mode-fields'
import { RunbookEditWebhooksPanel } from './webhooks-panel'
import { TimezoneSelect } from '../../form/timezone-select'
import { useFolderOptions } from '../../form/use-folder-options'
import { useType } from 'main/components/shared/hooks/runbook'
import { useLanguage } from 'main/services/hooks'
import {
  Account,
  CustomField,
  CustomFieldGroup,
  CustomFieldUser,
  FieldValue,
  RoleType,
  RunbookEditRunbook
} from 'main/services/queries/types'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { RunbookUpdatePayload } from 'main/services/queries/use-update-runbook'
import { CustomFieldsGroupsForm } from '../../custom-field-form/custom-field-groups-form'

export type RunbookEditFormType = MergeDeep<
  RunbookUpdatePayload,
  {
    runbook: {
      field_values?: Record<string, FieldValue>
      // collect the form value via role_types but then transform it into roles before submitting
      role_types: RoleType[]
    }
  }
>

export const RunbookEditForm = ({
  disabled,
  readOnly,
  runbook,
  account,
  projects,
  customFields = [],
  customFieldsByGroupId = {},
  customFieldGroupsLookup = {},
  customFieldUsers = []
}: {
  disabled?: boolean
  readOnly?: boolean
  runbook: RunbookEditRunbook
  // passing this in makes testing significantly easier
  account: Account
  projects: PermittedProject[]
  customFields?: CustomField[]
  customFieldsByGroupId?: Record<number, CustomField[]>
  customFieldGroupsLookup?: Record<number, CustomFieldGroup>
  customFieldUsers?: CustomFieldUser[]
}) => {
  const {
    formState: { errors }
  } = useFormContext<RunbookEditFormType>()
  const isSnippet = runbook.template_type === 'snippet'
  const isRunbook = runbook.template_type === 'off'
  const runbookType = useType(runbook)
  const isIncident = runbookType?.incident

  return (
    <Box flex={false} pad={{ bottom: '64px' }}>
      <NameField disabled={disabled} readOnly={readOnly} defaultValue={runbook.name} />
      {!isSnippet && <FolderField disabled={disabled} readOnly={readOnly || !projects.length} projects={projects} />}
      {!isSnippet && <TimezoneField runbook={runbook} disabled={disabled} readOnly={readOnly} />}
      {isRunbook && !isIncident && <TimingModeFields runbook={runbook} disabled={disabled} readOnly={readOnly} />}
      {isRunbook && !isIncident && (
        <RagStatusFields<RunbookEditFormType>
          name="runbook.status"
          statusMessageFieldName="runbook.status_message"
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      <Accordion>
        <RolesPanel runbook={runbook} accountId={account.id} disabled={disabled} readOnly={readOnly} />
        {!isSnippet && (
          <CustomFieldsGroupsForm
            customFields={customFields}
            customFieldGroupsLookup={customFieldGroupsLookup}
            groupedCustomFields={customFieldsByGroupId}
            customFieldUsers={customFieldUsers}
            iconColor={runbook.project.color}
            namePrefix="runbook."
            errors={errors.runbook}
          />
        )}
        {!isRunbook && !isIncident && (
          <TemplateSettingsPanel runbook={runbook} disabled={disabled} readOnly={readOnly} />
        )}
        {!isSnippet && <DisplaySettingsPanel runbook={runbook} disabled={disabled} readOnly={readOnly} />}
        <OtherDetailsPanel runbook={runbook} accountSlug={account.slug} disabled={disabled} readOnly={readOnly} />
        {!isSnippet && <RunbookEditWebhooksPanel runbook={runbook} />}
      </Accordion>
    </Box>
  )
}

const NameField = ({
  defaultValue,
  disabled,
  readOnly
}: {
  defaultValue: string
  disabled?: boolean
  readOnly?: boolean
}) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const { register, formState } = useFormContext<RunbookEditFormType>()

  return (
    <TextInput
      label={t('title.label')}
      required
      autoFocus
      disabled={disabled || readOnly} // TODO: distinguish between disabled and readOnly
      hasError={!!formState.errors.runbook?.name}
      defaultValue={defaultValue}
      {...register('runbook.name', { required: true })}
    />
  )
}

const FolderField = ({
  disabled,
  readOnly,
  projects
}: {
  disabled?: boolean
  readOnly?: boolean
  projects: PermittedProject[]
}) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const { control } = useFormContext<RunbookEditFormType>()
  const flattenedFolderHierarchy = useFolderOptions({ folders: projects })

  return (
    <Controller
      name={'runbook.project_id'}
      control={control}
      render={({ field: { onChange, value, onBlur, ref }, fieldState: { error } }) => (
        <Select
          data-testid="runbook-edit-folder-select"
          labelKey="name"
          optionToString={option => option.name_hierarchy ?? option.name}
          icon="folder-open"
          valueKey="id"
          filterKeys={['name']}
          disabled={disabled}
          hasError={!!error}
          inlineError={error?.message}
          inputRef={ref}
          label={t('folder.label')}
          onBlur={onBlur}
          onChange={onChange}
          options={flattenedFolderHierarchy}
          placeholder={t('folder.placeholder')}
          readOnly={readOnly}
          required
          value={value}
        />
      )}
    />
  )
}

const TimezoneField = ({
  runbook,
  disabled,
  readOnly
}: {
  runbook: RunbookEditRunbook
  disabled?: boolean
  readOnly?: boolean
}) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const { formState, control, watch } = useFormContext<RunbookEditFormType>()
  const startScheduled = watch(`runbook.runbook_versions_attributes.${0}.start_scheduled`)
  const targetDate = useMemo(() => (startScheduled ? new Date(startScheduled) : undefined), [startScheduled])

  return (
    <Controller
      name={'runbook.timezone'}
      control={control}
      defaultValue={runbook.timezone}
      render={({ field: { onChange, value, ref, onBlur } }) => (
        <TimezoneSelect
          targetDate={targetDate}
          disabled={disabled}
          hasError={!!formState.errors.runbook?.timezone}
          inlineError={formState.errors.runbook?.timezone?.message}
          inputRef={ref}
          label={t('timezone.label')}
          name="runbook.timezone"
          onBlur={onBlur}
          onChange={onChange}
          placeholder={t('timezone.placeholder')}
          readOnly={readOnly}
          helpText={t('timezone.tooltip')}
          value={value}
          customFirstOption={{
            id: 'automatic',
            name: 'Automatic'
          }}
        />
      )}
    />
  )
}
