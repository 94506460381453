import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil'
import * as yup from 'yup'
import styled from 'styled-components'
import { usePrevious } from 'react-use'

import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  DayTimePicker,
  DurationPicker,
  Icon,
  IconButton,
  Modal,
  TaskTypeIcon,
  Text,
  TextEditor,
  Tooltip
} from '@cutover/react-ui'
import { TasksDeleteModal } from 'main/components/runbook/modals/task-modals/tasks-delete-modal'
import {
  DateTimePickerField,
  DurationPickerField,
  FormEditPanel,
  RadioboxGroupField,
  SelectField,
  StreamSelectField,
  TextEditorField,
  TextInputField
} from 'main/components/shared/form'
import {
  getAccountTaskType,
  streamsLookupState,
  streamsState,
  TaskShowPermissions,
  useGetTaskShowPermissions,
  usePermittedStreams,
  useRunbookCurrentVersionProperty,
  useRunbookId,
  useRunbookProperty,
  useRunbookRunbookType,
  useRunbookVersionId,
  useRunbookVersionProperty,
  useTaskProgression
} from 'main/recoil/runbook'
import { useHandleRunbookResponse } from 'main/recoil/runbook/updaters/tasks-operations'
import { globalConfig } from 'main/recoil/shared/global-config'
import { useLanguage } from 'main/services/hooks'
import { FieldValue, TaskCompletionType, TaskListTask, TaskShowTask, TaskType } from 'main/services/queries/types'
import { getTask, setTask, TaskEditTaskPayload } from 'main/services/queries/use-task'
import { RunbookTaskUpdateResponse } from 'main/services/api/data-providers/runbook-types'
import { TaskCommsSettingsModal } from 'main/components/runbook/modals/task-modals/task-comms-settings-modal'
import { taskEditUpdatedTaskId, useTaskEditTaskTypes } from 'main/recoil/runbook/models/tasks/task-edit'
import { customFieldValidation, useCustomFieldForm } from 'main/components/shared/custom-field-form'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helper'
import {
  accountCustomFieldGroupsState,
  accountCustomFieldUsers,
  customFieldGroupsLookup,
  customFieldLookup
} from 'main/recoil/runbook/models/account/custom-fields'
import {
  CustomFieldsGroupsForm,
  CustomFieldsGroupsFormProps
} from 'main/components/shared/custom-field-form/custom-field-groups-form'
import { TaskEditDependenciesPanel } from './dependencies-panel'
import { TaskActionContent } from './task-action-content'
import { TaskAdvancedSettings } from './task-advanced-settings'
import { FormType } from 'main/components/shared/form/form'
import { TaskCommsStatus } from './task-comms-status'
import { TaskEditAssignedUsersAndTeamsPanel } from './assigned-users-teams-panel'

export const TaskEditForm = ({ data: taskListTask, onClose }: { data: TaskListTask; onClose: () => void }) => {
  const { id: taskId, internal_id: taskInternalId, stage } = taskListTask

  const taskEditFormRef = useRef<FormType<TaskEditFormType>>(null)
  const getTaskShowPermissions = useGetTaskShowPermissions()
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })
  const runbookId = useRunbookId()
  const runbookVersionId = useRunbookVersionId()
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false)
  const [completionType, setCompletionType] = useState<TaskCompletionType | undefined>(undefined)
  const [can, setPermissions] = useState<TaskShowPermissions | null>(null)
  const [taskTypeId, setTaskTypeId] = useState<number>(taskListTask.task_type_id)
  const processTaskUpdateResponse = useHandleRunbookResponse()
  const customFieldsLookup = useRecoilValue(customFieldLookup)
  const customFieldGroups = useRecoilValue(accountCustomFieldGroupsState)
  const customFieldsGroupsLookup = useRecoilValue(customFieldGroupsLookup)
  const customFieldUsers = useRecoilValue(accountCustomFieldUsers)
  const currentTaskType = useRecoilValue(getAccountTaskType(taskTypeId))
  const [updatedTask, setupdatedTask] = useRecoilState(taskEditUpdatedTaskId)
  const { onSkipTasks } = useTaskProgression()

  const {
    initialFieldValues,
    fieldValueValidation,
    buildFieldValuesAttributesRequestData,
    data: { customFields, groupedCustomFields, integrationGroupedCustomFields }
  } = useCustomFieldForm({
    applyToSlugs: ['task_edit'],
    customFieldsLookup,
    fieldValues: taskListTask.field_values,
    customFieldGroups,
    integrationActionItem: currentTaskType.integration_action_items?.[0],
    constraintContext: { task_type_id: taskTypeId }
  })

  useEffect(() => {
    if (!taskEditFormRef.current) return

    // TODO: task completion type is changed when there's a linked resource
    // See task_active_model _getTaskCompletionStatus function
    setCompletionType(taskListTask.completion_type)

    const { isDirty } = taskEditFormRef.current.formState

    if (!isDirty || (updatedTask && updatedTask.id === taskListTask.id)) {
      // If form is not dirty or same task was edited, reset the whole form
      if (updatedTask) {
        taskEditFormRef.current.reset(buildDefaultValues(updatedTask))
      }
    } else {
      // If form is dirty, reset only certain fields. See task_active_model syncFromList method.
      const resetField = taskEditFormRef.current.resetField
      resetField('start_display', { defaultValue: taskListTask.start_display })
      resetField('end_display', { defaultValue: taskListTask.end_display })
      resetField('stage', { defaultValue: taskListTask.stage })
      resetField('predecessor_ids', { defaultValue: taskListTask.predecessor_ids })
      resetField('possible_predecessors_ids', { defaultValue: taskListTask.possible_predecessors_ids })
      resetField('successor_ids', { defaultValue: taskListTask.successor_ids })
    }
    setupdatedTask(undefined)
  }, [taskListTask])

  const getDefaultValues = async () => {
    const resp = await getTask(runbookId, runbookVersionId, taskId)
    setPermissions(getTaskShowPermissions(resp))
    const task = resp?.task ?? {}
    setCompletionType(task.completion_type)

    return buildDefaultValues({ ...task, ...taskListTask })
  }

  const buildDefaultValues = (task: TaskShowTask | TaskListTask) => {
    return {
      ...task,
      disable_notify: task.disable_notify.toString(),
      description: task.description,
      end_fixed: task.end_fixed ? task.end_fixed * 1000 : null,
      end_display: taskListTask.end_display * 1000,
      start_display: taskListTask.start_display * 1000,
      field_values: buildDefaultFieldValues(
        customFields,
        groupedCustomFields,
        initialFieldValues,
        integrationGroupedCustomFields
      )
    }
  }

  const handleSuccess = useCallback(
    (response: RunbookTaskUpdateResponse) => {
      const currentFormValues = taskEditFormRef.current?.getValues()
      const fieldValues: { [x: number]: FieldValue } = {}
      response.task.field_values.forEach(fv => (fieldValues[fv.custom_field_id] = fv))
      taskEditFormRef.current?.reset({
        ...currentFormValues,
        field_values: fieldValues,
        recipient_runbook_team_ids: response.task.recipient_runbook_team_ids,
        recipient_user_ids: response.task.recipient_user_ids,
        recipients: undefined
      })

      processTaskUpdateResponse(response)
    },
    [processTaskUpdateResponse]
  )

  const handleSubmit = useCallback(
    (values: TaskEditTaskPayload) => {
      return setTask(runbookId, runbookVersionId, taskId, values)
    },
    [runbookId, runbookVersionId, taskId]
  )

  const footerButton = useMemo(() => {
    switch (completionType) {
      case 'complete_skipped':
        return <Button label={t('footer.skipped')} icon="skip" primary full disabled />
      default:
        return null
    }
  }, [completionType, t])

  const handleSkipTask = () => onSkipTasks([taskId])

  return (
    <>
      <TasksDeleteModal taskIds={[taskId]} open={showDeleteTaskModal} onClose={() => setShowDeleteTaskModal(false)} />
      <FormEditPanel<TaskEditFormType, TaskEditTaskPayload>
        key={taskId}
        ref={taskEditFormRef}
        title={t('title', { internalId: taskInternalId })}
        defaultValues={getDefaultValues}
        onSubmit={handleSubmit}
        onClose={onClose}
        schema={buildTaskEditFormSchema(fieldValueValidation)}
        readOnly={!can?.update || stage === 'complete'}
        onSuccess={handleSuccess}
        transformer={({ field_values, ...rest }) => {
          const data = rest
          return {
            task: {
              ...data,
              field_values_attributes: buildFieldValuesAttributesRequestData(field_values)
            },
            predecessors: data.predecessor_ids,
            recipients: data.recipients,
            runbook_teams: data.runbook_team_ids,
            users: data.user_ids
          } as unknown as TaskEditTaskPayload
        }}
        headerItems={[
          ...(can?.skip
            ? [<IconButton tipPlacement="top" label={t('iconSkipLabel')} icon="skip" onClick={handleSkipTask} />]
            : []),
          ...(can?.destroy
            ? [
                <IconButton
                  tipPlacement="top"
                  label={t('iconDeleteLabel')}
                  icon="trash-o"
                  onClick={() => setShowDeleteTaskModal(true)}
                />
              ]
            : [])
        ]}
        footer={footerButton}
      >
        {can && (
          <TaskEditFormFields
            task={taskListTask}
            permissions={can}
            customFields={customFields}
            customFieldGroupsLookup={customFieldsGroupsLookup}
            groupedCustomFields={groupedCustomFields}
            customFieldUsers={customFieldUsers}
            integrationGroupedCustomFields={integrationGroupedCustomFields}
            setTaskTypeId={setTaskTypeId}
          />
        )}
      </FormEditPanel>
    </>
  )
}

const buildTaskEditFormSchema = (fieldValueValidation: ReturnType<typeof customFieldValidation>) => {
  return yup.object({
    disable_notify: yup.string().oneOf(['true', 'false']),
    description: yup.string().nullable(),
    duration: yup.number().nullable(),
    end_actual: yup.number().nullable(),
    end_display: yup.number().nullable(),
    end_fixed: yup.mixed().nullable(), // is received as a number but it's saved as a Date object
    end_planned: yup.number().nullable(),
    end_requirements: yup.string().oneOf(['any_can_end', 'all_must_end', 'same_must_end']),
    field_values: fieldValueValidation,
    level: yup.string().oneOf(['level_1', 'level_2', 'level_3']),
    message: yup.string().nullable(),
    name: yup.string().required(),
    possible_predecessors_ids: yup.array().of(yup.number().required()).notRequired(),
    predecessor_ids: yup.array().of(yup.number().required()).notRequired(),
    recipients: yup
      .object()
      .shape({
        users: yup.array().of(yup.mixed()).notRequired(),
        runbook_teams: yup.array().of(yup.number().required()).notRequired()
      })
      .notRequired(),
    recipient_runbook_team_ids: yup.array().of(yup.number().required()).notRequired(),
    recipient_user_ids: yup.array().of(yup.number().required()).notRequired(),
    runbook_team_ids: yup.array().of(yup.number().required()).notRequired(),
    stage: yup.string().oneOf(['default', 'startable', 'in-progress', 'complete']),
    start_actual: yup.number().nullable(),
    start_display: yup.number().nullable(),
    start_latest_planned: yup.number().nullable(),
    start_requirements: yup.string().oneOf(['any_can_start', 'all_must_start']),
    stream_id: yup.number().required(),
    successor_ids: yup.array().of(yup.number().required()).notRequired(),
    task_type_id: yup.number().required(),
    user_ids: yup.array().of(yup.number().required()).notRequired()
  })
}

export type TaskEditFormType = yup.InferType<ReturnType<typeof buildTaskEditFormSchema>>

const TaskEditFormFields = ({
  task,
  permissions: can,
  setTaskTypeId,
  ...customFieldsProps
}: CustomFieldsGroupsFormProps & {
  task: TaskListTask
  permissions: TaskShowPermissions
  setTaskTypeId: (id: number) => void
}) => {
  const {
    watch,
    setValue,
    formState: { errors, defaultValues },
    resetField,
    getFieldState,
    control
  } = useFormContext<TaskEditFormType>()
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })
  const [showTaskCommsSettingsModal, setShowTaskCommsSettingsModal] = useState(false)

  const [isDescriptionModalOpen, setDescriptionModalOpen] = useState(false)
  const runbookVersionStage = useRunbookCurrentVersionProperty({ attribute: 'stage' })
  const startScheduled = useRunbookCurrentVersionProperty({ attribute: 'start_scheduled' })
  const versionStartDisplay = useRunbookCurrentVersionProperty({ attribute: 'start_display' })
  const timing_mode = useRunbookCurrentVersionProperty({ attribute: 'timing_mode' })
  const run = useRunbookVersionProperty({ attribute: 'run' })
  const is_template = useRunbookProperty({ attribute: 'is_template' })
  const timezone = useRunbookProperty({ attribute: 'timezone' })
  const { dynamic } = useRunbookRunbookType()

  const taskTypeId = watch('task_type_id')
  const recipients = watch('recipients')
  const { taskTypes, taskTypeIntegrations } = useTaskEditTaskTypes()
  const currentTaskType = useRecoilValue(getAccountTaskType(taskTypeId))
  const previousTaskType = usePrevious(currentTaskType)
  const taskTypesOptions =
    taskTypes?.map((t: TaskType) => ({ value: t.id, label: t.name, icon: <TaskTypeIcon icon={t.icon} /> })) ?? []
  const taskTypesIntegrationsOptions =
    taskTypeIntegrations?.map((t: TaskType) => ({
      value: t.id,
      label: t.integration_action_items[0].name,
      icon: (
        <IntegrationIcon
          alt={`${t.integration_action_items[0].name}-icon`}
          src={t.integration_action_items[0].image_url || t.integration_action_items[0].integration_setting.image_url}
        />
      )
    })) ?? []
  const taskLevels = useRecoilValue(globalConfig).taskLevels
  const integrations = useRecoilValue(globalConfig).integrations
  const allStreams = useRecoilValue(streamsState)
  const permittedStreams = usePermittedStreams()
  const streams = can?.update ? permittedStreams : allStreams
  const streamLookup = useRecoilValue(streamsLookupState)
  const integrationActionItem = currentTaskType.integration_action_items?.[0]

  // stores temporary description field value defined in modal text editor
  const [descriptionModalValue, setDescriptionModalValue] = useState<string | undefined>(undefined)
  // signals the text editor to reset its content to its current value
  const [isResetDescription, setResetDescription] = useState<boolean>(false)

  const description = watch('description')
  const endDisplay = watch('end_display')
  const startDisplay = watch('start_display')
  const endFixed = watch('end_fixed')
  const endPlanned = watch('end_planned')
  const startLatestPlanned = watch('start_latest_planned')
  const startActual = watch('start_actual')
  const endActual = watch('end_actual')
  const duration = watch('duration')
  const stage = watch('stage')
  const streamId = watch('stream_id')
  const userIds = watch('user_ids')
  const runbookTeamIds = watch('runbook_team_ids')
  const successorIds = watch('successor_ids')
  const possiblePredecessorsIds = watch('possible_predecessors_ids')
  const recipientUserIds = watch('recipient_user_ids')
  const recipientRunbookTeamIds = watch('recipient_runbook_team_ids')
  const message = watch('message')
  const isTaskTypeAutoFinish = currentTaskType?.auto_finish
  const isTaskTypeEnableEndFixed = currentTaskType?.enable_end_fixed
  const isTaskTypeCommsOff = currentTaskType?.comms === 'comms_off'
  const isRunbookPlanningOrPaused = run === null || run?.mode === 'paused'
  const durationDifference = useMemo(
    () =>
      endDisplay && startDisplay
        ? {
            start: (endDisplay - startDisplay) / 1000,
            end: duration ?? 0
          }
        : undefined,
    []
  )
  const endDifference = useMemo(
    () =>
      endDisplay && endPlanned && !(is_template || timing_mode === 'unscheduled')
        ? {
            start: endDisplay / 1000,
            end: endFixed ? Math.max(endPlanned, (endFixed as number) / 1000) : endPlanned
          }
        : undefined,
    []
  )
  const startDiffernce = useMemo(
    () =>
      startDisplay && startLatestPlanned && !(is_template || timing_mode === 'unscheduled')
        ? {
            start: startDisplay / 1000,
            end: startLatestPlanned
          }
        : undefined,
    []
  )

  const shouldShowTimingField = (arg: any) =>
    (startScheduled || run) && (!(is_template || timing_mode === 'unscheduled') || !!arg)

  const displayActionsTask = useCallback(() => ({ ...defaultValues, ...task }), [task, defaultValues])

  const recipientCount = recipients
    ? (recipients.users?.length ?? 0) + (recipients.runbook_teams?.length ?? 0)
    : (recipientUserIds?.length ?? 0) + (recipientRunbookTeamIds?.length ?? 0)

  useEffect(() => {
    if (startDisplay && duration && getFieldState('duration').isDirty && isRunbookPlanningOrPaused) {
      setValue('end_display', startDisplay + duration * 1000, { shouldDirty: false, shouldTouch: false })
    }
  }, [duration])

  useEffect(() => {
    const userHasUpdatedTaskType = previousTaskType && currentTaskType.id !== previousTaskType?.id
    if (!userHasUpdatedTaskType) return

    if (previousTaskType.enable_end_fixed && currentTaskType.enable_end_fixed && endFixed) {
      setValue('end_fixed', new Date(endFixed as number))
    }

    setTaskTypeId(taskTypeId)
    resetField('field_values')
  }, [previousTaskType, currentTaskType, endFixed])

  const integrationDescription = useMemo(() => {
    if (!integrationActionItem) {
      return
    }

    const integration = integrations.find(i => i.name === integrationActionItem?.integration_setting?.integration_title)
    const integrationAction = integration?.actions?.find(
      action => action.klass === integrationActionItem?.integration_action
    )
    return integrationAction?.description
  }, [integrationActionItem, integrations])

  // reset description value and text editor when resetting the form
  useEffect(() => {
    if (!getFieldState('description').isDirty) {
      resetField('description')
      setResetDescription(true)
    }
  }, [getFieldState('description').isDirty])

  // after resetting the description text editor, restore the flag to false
  useEffect(() => {
    if (isResetDescription) {
      setResetDescription(false)
    }
  }, [isResetDescription])

  const handleDescriptionModalConfirm = () => {
    if (descriptionModalValue) {
      const value = descriptionModalValue === '<p></p>' ? null : descriptionModalValue
      setValue('description', value, { shouldDirty: true })
      setResetDescription(true)
      setDescriptionModalValue(undefined)
    }
    setDescriptionModalOpen(false)
  }

  return (
    <>
      <Modal
        title="Task Description"
        confirmText={t('fields.description.modalButton')}
        open={isDescriptionModalOpen}
        onClose={() => setDescriptionModalOpen(false)}
        onClickConfirm={handleDescriptionModalConfirm}
      >
        <TextEditor
          value={description ?? ''}
          onChange={value => setDescriptionModalValue(value)}
          readOnly={!can?.update}
        />
      </Modal>
      <TextInputField<TaskEditFormType> name="name" label={t('fields.title.label')} />
      <Box flex direction="row" gap="small">
        <TaskTypeSelectWrapper isTaskTypeCommsOff={isTaskTypeCommsOff}>
          <SelectField<TaskEditFormType>
            name="task_type_id"
            options={[
              { header: t('fields.taskType.taskTypeSelectHeader') },
              ...taskTypesOptions,
              ...[
                ...(taskTypesIntegrationsOptions.length
                  ? [{ header: t('fields.taskType.integrationTaskTypeSelectHeader') }]
                  : [])
              ],
              ...taskTypesIntegrationsOptions
            ]}
            label={t('fields.taskType.label')}
            placeholder={t('fields.taskType.placeholder')}
            noBottomPadding={!isTaskTypeCommsOff}
          />
        </TaskTypeSelectWrapper>
        {!isTaskTypeCommsOff && (
          <Button
            css="flex: 1; position: relative; top: 6px;"
            label={t('fields.commsSettings.settings')}
            secondary
            onClick={() => setShowTaskCommsSettingsModal(true)}
          />
        )}
      </Box>
      {!isTaskTypeCommsOff && (
        <MessageStatusWrapper>
          <TaskCommsStatus
            condition={!!message}
            statusMessage={t('fields.commsSettings.messageStatus', { count: message ? 1 : 0 })}
            position="left"
            type="message"
          />
          <TaskCommsStatus
            condition={recipientCount > 0}
            statusMessage={t('fields.commsSettings.recipientsStatus', { count: recipientCount })}
            type="recipients"
          />
        </MessageStatusWrapper>
      )}
      <TaskCommsSettingsModal
        loadInitialRecipients={!recipients}
        open={showTaskCommsSettingsModal}
        recipientUserIds={recipientUserIds ?? []}
        recipientRunbookTeamIds={recipientRunbookTeamIds ?? []}
        onClose={() => setShowTaskCommsSettingsModal(false)}
        canModifyComms={can?.modify_comms}
        taskId={task.id}
        commsType={currentTaskType?.comms}
      />
      <StreamSelectField<TaskEditFormType>
        label={t('fields.stream.label')}
        streams={streams ?? []}
        name="stream_id"
        clearable={false}
        placeholder={t('fields.stream.placeholder')}
        loading={streams === undefined}
      />
      <RadioboxGroupField<TaskEditFormType>
        name="level"
        label={t('fields.level.label')}
        direction="row"
        options={taskLevels.map(level => ({ value: level.id, label: level.name })).reverse()}
      />
      {shouldShowTimingField(startActual) && (
        <DateTimePickerField<TaskEditFormType>
          tooltipText={t('fields.forecastStart.helpText', { context: startActual ? 'started' : undefined })}
          diff={startDiffernce}
          name="start_display"
          readOnly // always true as it's a computed value
          onChange={date => date}
          label={t('fields.forecastStart.label', { context: startActual ? 'started' : undefined })}
          timezone={timezone}
        />
      )}
      {!isTaskTypeAutoFinish && stage !== 'complete' && (
        <DurationPickerField<TaskEditFormType>
          label={t('fields.duration.label')}
          readOnly={!can?.update && !(dynamic && task.stage === 'in-progress')}
          name="duration"
          diff={durationDifference}
        />
      )}
      {!isTaskTypeAutoFinish && stage === 'complete' && endDisplay && startDisplay && (
        // Not using DurationPickerField because value is computed
        <DurationPicker
          value={(endDisplay - startDisplay) / 1000}
          readOnly
          onChange={value => value}
          label={t('fields.duration.labelActual')}
          diff={durationDifference}
        />
      )}
      {shouldShowTimingField(endActual) && !isTaskTypeAutoFinish && (
        <DateTimePickerField<TaskEditFormType>
          tooltipText={t('fields.forecastFinish.helpText', { context: endActual ? 'finished' : undefined })}
          diff={endDifference}
          name="end_display"
          readOnly // always true as it's a computed value
          onChange={date => date}
          label={t('fields.forecastFinish.label', { context: endActual ? 'finished' : undefined })}
          timezone={timezone}
        />
      )}
      {isTaskTypeEnableEndFixed && isRunbookPlanningOrPaused && (
        // Not using a checkbox field since it is setting the value of end_fixed. This checkbox is not registered with the form.
        <Checkbox
          label={t('fields.dueDate.checkboxLabel')}
          checked={endFixed !== null}
          onChange={function (e) {
            e.preventDefault()
            setValue('end_fixed', endFixed === null ? new Date(endDisplay as number | Date) : null, {
              shouldDirty: true,
              shouldTouch: true
            })
          }}
        />
      )}
      {endFixed !== null && isTaskTypeEnableEndFixed && timing_mode === 'unscheduled' && (
        // TODO: Add "day" time picker to smart form fields.
        <Controller
          name="end_fixed"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DayTimePicker
                dayZeroStartValue={versionStartDisplay ? new Date(versionStartDisplay * 1000) : new Date()}
                onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                value={!!value ? new Date(value as number) : new Date()}
                timezone={timezone}
              />
            )
          }}
        />
      )}
      {endFixed !== null && isTaskTypeEnableEndFixed && timing_mode === 'scheduled' && (
        <DateTimePickerField<TaskEditFormType>
          tooltipText={t('fields.dueDate.tooltip')}
          name="end_fixed"
          label={t('fields.dueDate.timeFieldLabel')}
          timezone={timezone}
        />
      )}
      <Accordion>
        <CustomFieldsGroupsForm
          {...customFieldsProps}
          integrationActionItem={integrationActionItem}
          iconColor={streamLookup[streamId]?.color}
          errors={errors}
          readOnly={!can?.update}
          integrationDescription={integrationDescription}
        />
        {runbookVersionStage !== 'planning' && (
          <AccordionPanel iconColor={streamLookup[streamId]?.color} icon="play" label={t('actionAccordion.label')}>
            {displayActionsTask().start_ready || displayActionsTask().end_actual ? (
              <TaskActionContent task={displayActionsTask()} />
            ) : (
              <Text>{t('actionAccordion.noActions')}</Text>
            )}
          </AccordionPanel>
        )}
        <AccordionPanel
          iconColor={streamLookup[streamId]?.color}
          icon="info"
          label={t('description.label')}
          suffix={
            <Tooltip content={t('description.tooltip')}>
              <Icon
                icon="open-new"
                color="text-light"
                data-testid="open-description-modal-icon"
                onClick={() => setDescriptionModalOpen(true)}
                onKeyDown={e => {
                  if (e.code === 'Space' || e.code === 'Enter') {
                    setDescriptionModalOpen(true)
                  }
                }}
                tabIndex={0}
                css={`
                  position: relative;
                  top: 2px;
                `}
              />
            </Tooltip>
          }
        >
          <Box
            css={`
              label {
                padding: 0px;
              }
            `}
          >
            <TextEditorField<TaskEditFormType>
              name="description"
              readOnly={!can?.update}
              resetToValue={isResetDescription}
              css="padding-bottom: 0px;"
            />
          </Box>
        </AccordionPanel>
        <TaskEditAssignedUsersAndTeamsPanel
          iconColor={streamLookup[streamId]?.color}
          userIds={userIds ?? []}
          runbookTeamIds={runbookTeamIds ?? []}
          setValue={setValue}
          readOnly={!can?.update && !(dynamic && task.stage === 'in-progress')}
        />
        <TaskEditDependenciesPanel
          successorIds={successorIds ?? []}
          possiblePredecessorsIds={possiblePredecessorsIds ?? []}
          iconColor={streamLookup[streamId]?.color}
        />
        <TaskAdvancedSettings iconColor={streamLookup[streamId]?.color} readOnly={!can?.update} />
      </Accordion>
    </>
  )
}

const TaskTypeSelectWrapper = styled(Box)<{ isTaskTypeCommsOff: boolean }>`
  flex-grow: 1;
  max-width: ${({ isTaskTypeCommsOff }) => (isTaskTypeCommsOff ? '100%' : '50%')};
`

const IntegrationIcon = styled.img`
  height: 22px;
  width: 22px;
`
const MessageStatusWrapper = styled(Box)`
  display: inline-block;
  position: relative;
  top: 5px;
  padding-bottom: 20px;
`
