import { ReactNode, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { eventManager } from 'event-manager'

import { operations, useComponentPropsStateHandler } from './apps-component-props-helper'
import { AppFieldValues, AppsChannelResponse } from 'main/components/apps/apps-types'
import { appComponentProps, appFieldValues, appHeaderView, appModal, appViews } from 'main/recoil/apps/apps-atoms'
import { useCurrentUser } from 'main/recoil/current-user'
import { useWebsockets } from 'main/services/hooks'

const APPS_USER_CHANNEL_NAME = 'AppUserChannel'

export const AppsUserChannelSubscriber = ({ children }: { children: ReactNode }) => {
  const componentPropsStateHandler = useComponentPropsStateHandler()
  // useRecoilState employed on appViews to subscribe the component to state updates,
  // causing it to re-render and properly update the views when first loading the page from a redirect.
  const [_, setAppViews] = useRecoilState(appViews)
  const [appModalState, setAppModal] = useRecoilState(appModal)
  const [isTemplate, setIsTemplate] = useState<boolean | undefined>(false)
  const setComponentProps = useSetRecoilState(appComponentProps)
  const setHeaderApp = useSetRecoilState(appHeaderView)
  const websockets = useWebsockets()
  const user = useCurrentUser()

  const [searchParams, _setSeachParams] = useSearchParams()
  const runbookIdInParams = useParams().runbookId
  const runbookId =
    window.location.href.indexOf('/app/integration_action_item') != -1
      ? searchParams.get('runbook_id')
      : runbookIdInParams

  const subscriptionId = user.id
  const setAppFieldValues = useSetRecoilState(appFieldValues)
  const appsUserChannelSubscription = websockets.findExistingSubscription(APPS_USER_CHANNEL_NAME, subscriptionId)

  useMount(() => {
    eventManager.on('runbook-data', handleRunbookData)
  })

  useUnmount(() => {
    eventManager.off('runbook-data', handleRunbookData)
    appsUserChannelSubscription?.unsubscribe()
  })

  useEffect(() => {
    if (!runbookId || runbookId !== appModalState.resourceId) {
      setAppModal({ ...appModalState, open: false })
    }

    if (appsUserChannelSubscription && isTemplate) {
      appsUserChannelSubscription?.unsubscribe()
    }

    if (!appsUserChannelSubscription && !isTemplate) {
      websockets.subscribe(APPS_USER_CHANNEL_NAME, subscriptionId, {
        received: data => {
          updateAppViewsState(data.response)
        }
      })
    }
  }, [runbookId, appModalState.resourceId, isTemplate])

  const handleRunbookData = ({
    runbook
  }: {
    permissions: { [x: string]: number[] }
    runbook: { is_template: boolean }
  }) => {
    setIsTemplate(runbook.is_template)
  }

  const updateAppViewsState = (response: AppsChannelResponse) => {
    const appId = response.app_id
    const resourceId = response.resource_id
    const view = response.view
    const state = response.state
    const type = view.type
    const context = `${resourceId}-${appId}`

    if (response.session_id && response.session_id !== window.sessionStorage.getItem('browserHash')) {
      return null
    }

    componentPropsStateHandler({ response, context, setComponentProps })

    if (view.close_modal) {
      setAppModal({ view: undefined, appId, resourceId, open: false })
    }

    if (!operations.includes(type)) {
      if (state) {
        setAppFieldValues(prevValues => {
          const updatedState = { ...prevValues, [context]: state }
          return updatedState as AppFieldValues
        })
      }

      switch (type) {
        // TODO: if you send a modal to a page directly it has a white background.
        case 'modal':
          if (!view.close_modal) {
            setAppModal({ view, appId, resourceId, open: true })
          }
          break
        case 'page':
          response.view.content?.forEach(content => {
            if (content.type === 'modal') {
              setAppModal({ view: { ...content, appId, resourceId, id: '' }, appId, resourceId, open: true })
            }
          })

          setAppViews(views => {
            const updatedView = {
              ...response.view,
              appId,
              resourceId
            }
            return { ...views, [context]: updatedView }
          })
          break

        // TODO: following are identical in apps-resource-channel-subscriber.tsx
        case 'header':
          setHeaderApp({ view, appId, resourceId })
          break
        case 'panel':
          setAppViews(views => {
            const existingView = views[context]
            const updatedView = {
              ...response.view,
              appId,
              resourceId,
              visible: existingView?.visible,
              order: existingView?.order
            }
            return { ...views, [context]: updatedView }
          })
          break
        default:
          console.warn(`User resource channel does not accept ${type} type nodes.`)
      }
    }
  }

  return <>{children}</>
}
