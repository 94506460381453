import {
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  KeyboardEvent,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { differenceInDays, format } from 'date-fns'
import { KeyboardType } from 'grommet/utils'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { TaskTypeIconName } from '@cutover/icons'
import {
  Avatar,
  Box,
  durationParser,
  duration as formatDuration,
  TaskItemIcon,
  TaskItemLayout,
  Text,
  TextInput,
  TextInputProps
} from '@cutover/react-ui'
import {
  streamState,
  taskCreateNameState,
  useRunbookId,
  useRunbookVersionId,
  useRunbookVersionProperty,
  useStreamOrPermittedDefault,
  useTaskListTask,
  useTaskTypeOrDefault,
  useToggleTaskCreateState
} from 'main/recoil/runbook'
import { useHandleRunbookResponse } from 'main/recoil/runbook/updaters/tasks-operations'
import { useGlobalTaskLevelOrDefault } from 'main/recoil/shared/global-config'
import { useLanguage } from 'main/services/hooks'
import {
  RunbookTimingMode,
  RunbookVersion,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { createTask, TaskCreatePayload } from 'main/services/queries/use-task'
import { TaskSuccessorsModal } from 'main/components/runbook/modals/task-modals/task-successors-modal'

/**
 * TaskItemCreateToggle toggle for showing/hiding TaskItemCreate
 */
export const TaskItemCreateToggle = ({
  predecessorId,
  isDefault = false
}: {
  predecessorId: number
  isDefault?: boolean
}) => {
  const [currentPredecessor, toggleCreateInput] = useToggleTaskCreateState()

  return predecessorId === currentPredecessor || isDefault ? (
    <TaskItemCreate isDefault={isDefault} onCancel={() => toggleCreateInput({ fromPredecessorId: predecessorId })} />
  ) : null
}

/**
 * TaskItemCreate w/ data from recoil state
 */
export type NewTaskInputProps = TextInputProps & {
  onCancel?: any
  onCreate?: () => void
  taskTypes?: TaskType[]
  streams?: StreamListStream[]
  streamLookup?: Record<number, StreamListStream>
  runbookTimingMode?: RunbookVersion['timing_mode']
  runbookStartPlanned?: RunbookVersion['start_planned']
  canAdd?: boolean
  isDefault?: boolean
}

type NewTaskState = Omit<TaskCreatePayload['task'], 'stream_id' | 'task_type_id'>

const getInitialDate = (endDisplay?: number, startPlanned?: number | null, timingMode?: RunbookTimingMode) => {
  if (timingMode === 'scheduled')
    return format((endDisplay ? endDisplay : startPlanned ? startPlanned : 0) * 1000, 'd LLL HH:mm')

  const date = new Date((endDisplay || startPlanned || 0) * 1000)
  const dayZero = new Date((startPlanned || 0) * 1000)
  const days = differenceInDays(dayZero, date)
  const time = format(date, 'HH:mm')

  return `Day ${days} ${time}`
}

export const TaskItemCreate = ({ onCancel, isDefault }: NewTaskInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isSuccessorsModalOpen, setSuccessorsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [createTaskPredecessor, toggleCreateTaskInput] = useToggleTaskCreateState()
  const runbookVersionId = useRunbookVersionId()
  const runbookId = useRunbookId()
  const runbookTimingMode = useRunbookVersionProperty({ attribute: 'timing_mode' })
  const runbookStartPlanned = useRunbookVersionProperty({ attribute: 'start_planned' })
  const handleTaskCreateResponse = useHandleRunbookResponse()

  const previousTask = useTaskListTask(createTaskPredecessor || 0) as TaskListTask | undefined
  const previousTaskType = useTaskTypeOrDefault(previousTask?.task_type_id)
  const initialStream = useStreamOrPermittedDefault(previousTask?.stream_id)
  const parentStream = useRecoilValue(streamState({ id: initialStream?.parent_id || 0 })) as
    | StreamListStream
    | undefined
  const initialTaskType = useTaskTypeOrDefault(
    initialStream?.default_task_type_id || parentStream?.default_task_type_id
  )
  const initialLevel = useGlobalTaskLevelOrDefault(previousTask?.level)

  const initialDuration = useMemo(
    () => (initialTaskType?.auto_finish ? 0 : initialTaskType?.default_duration),
    [initialTaskType?.auto_finish, initialTaskType?.default_duration]
  )
  // TODO make date take account of timezones https://cutover.atlassian.net/browse/CFE-1400
  const dateText = useMemo(
    () => getInitialDate(previousTask?.end_display, runbookStartPlanned, runbookTimingMode),
    [previousTask?.end_display, runbookStartPlanned, runbookTimingMode]
  )

  const [dummy, setDummy] = useState<(NewTaskState & { dateText: string; previousTask?: TaskListTask }) | undefined>(
    undefined
  )
  const [tempTaskName, setTempTaskName] = useRecoilState(taskCreateNameState)
  const [newTaskData, setNewTaskData] = useState<NewTaskState>({
    disable_notify: false,
    end_fixed: null,
    stream: initialStream,
    level: initialLevel.id,
    level_name: initialLevel.name,
    task_type: initialTaskType,
    name: tempTaskName ?? '',
    stage: 'new',
    duration: initialDuration
  })

  const updateNewTaskData = (data: Partial<NewTaskState>) => {
    setNewTaskData(prev => ({ ...prev, ...data }))
  }

  const matchDuration = (inputValue: string) => {
    const matchPattern = new RegExp(/\b(\d+[dhms])+?\b/)
    const words = inputValue.split(/\s+/)

    words.forEach(word => {
      if (matchPattern.test(word)) {
        // word is a duration string, eg 45h30m, convert it to an actual duration
        const durationSeconds = durationParser(word)
        // now, remove the matching string from the task title (plus the space before it)
        const textToRemove = new RegExp(`\\s*${word}`, 'g')
        const newName = newTaskData.name.replace(textToRemove, '')
        updateNewTaskData({ name: newName, duration: durationSeconds })
      }
    })
  }

  const resetNewTask = useCallback(() => {
    setNewTaskData({
      disable_notify: false,
      end_fixed: null,
      stage: 'new',
      stream: initialStream,
      level: initialLevel.id,
      level_name: initialLevel.name,
      task_type: initialTaskType,
      name: '',
      duration: initialDuration
    })
  }, [initialStream, initialLevel, initialTaskType])

  const handleKeyDown: KeyboardType = event => {
    event.stopPropagation()
    const input = event.target as HTMLInputElement
    const inputValue = input?.value

    switch (event.key) {
      case 'Enter':
        return handleTaskCreate()
      case 'Escape':
        return toggleCreateTaskInput({ fromPredecessorId: previousTask?.id || 0 })
      case ' ':
        matchDuration(inputValue)
    }
  }

  const handleChangeContent: ChangeEventHandler<HTMLInputElement> = event => {
    setTempTaskName(event.target.value)
    updateNewTaskData({ name: event.target.value })
  }

  const handleSuccessorSelection = (successors: number[]) => {
    setSuccessorsModalOpen(false)
    handleMutation(successors)
  }

  const handleTaskCreate = () => {
    if (isLoading || dummy || !newTaskData.name) return

    if (previousTask?.successor_ids && previousTask.successor_ids.length > 1) {
      setSuccessorsModalOpen(true)
    } else {
      handleMutation(previousTask ? [...previousTask.successor_ids] : [])
    }
  }

  const handleMutation = async (successors: number[]) => {
    setIsLoading(true)
    const payload = {
      task: { ...newTaskData, stream_id: newTaskData.stream?.id, task_type_id: newTaskData.task_type.id },
      base_task_id: createTaskPredecessor || null,
      predecessors: previousTask ? [previousTask.id] : [],
      successors,
      runbook_teams: [],
      users: [],
      integration_events: []
    }

    try {
      setDummy({ ...newTaskData, dateText, previousTask })
      resetNewTask()
      setTempTaskName(undefined)

      const data = await createTask(runbookId, runbookVersionId, payload)
      handleTaskCreateResponse(data)
      toggleCreateTaskInput({ fromPredecessorId: data.task.id })
      setIsLoading(false)
    } catch {
      setDummy(undefined)
      resetNewTask()
      setTempTaskName(undefined)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (dummy) {
      inputRef.current?.focus()
    }
  }, [dummy])

  return (
    <>
      {dummy && (
        <TaskItemCreateInput
          dummy
          disabled
          date={dummy.dateText}
          previousTask={dummy.previousTask}
          previousTaskTypeIcon={previousTaskType?.icon as TaskTypeIconName}
          streamColor={dummy.stream?.color}
          taskName={dummy.name}
          taskTypeIcon={(dummy.task_type.icon + '-dashed') as TaskTypeIconName}
          duration={dummy.duration}
        />
      )}
      <TaskItemCreateInput
        ref={inputRef}
        isDefault={isDefault}
        previousTask={previousTask}
        previousTaskTypeIcon={previousTaskType?.icon as TaskTypeIconName}
        date={dateText}
        streamColor={newTaskData.stream?.color}
        streamName={newTaskData.stream?.name}
        taskName={newTaskData.name}
        taskTypeIcon={(newTaskData.task_type.icon + '-dashed') as TaskTypeIconName}
        levelName={newTaskData.level_name}
        duration={newTaskData.duration}
        handleChangeContent={handleChangeContent}
        handleKeyDown={handleKeyDown}
        onCancel={onCancel}
      />
      {previousTask && (
        <TaskSuccessorsModal
          open={isSuccessorsModalOpen}
          onClose={() => setSuccessorsModalOpen(false)}
          previousTask={previousTask}
          onSubmit={(successors: number[]) => handleSuccessorSelection(successors)}
        />
      )}
    </>
  )
}

/**
 * TaskItemCreateInput presentational only
 */
const TaskItemCreateInput = forwardRef(
  (
    {
      dummy = false,
      previousTask,
      date,
      disabled,
      isDefault,
      streamColor,
      taskTypeIcon,
      previousTaskTypeIcon,
      onCancel,
      handleChangeContent,
      handleKeyDown,
      taskName,
      streamName,
      levelName,
      duration
    }: {
      dummy?: boolean
      previousTask?: TaskListTask
      date: string | number
      disabled?: boolean
      isDefault?: boolean
      streamColor: string
      taskTypeIcon: TaskTypeIconName
      previousTaskTypeIcon?: TaskTypeIconName
      onCancel?: () => void
      handleChangeContent?: (e: ChangeEvent<HTMLInputElement>) => void
      handleKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
      taskName: string
      streamName?: string
      levelName?: string
      duration?: number
    },
    ref: Ref<HTMLInputElement>
  ) => {
    const { t } = useLanguage('runbook', { keyPrefix: 'taskList' })
    const tempTaskName = useRecoilValue(taskCreateNameState)
    const [value, setValue] = useState(tempTaskName ?? '')

    useEffect(() => {
      if (dummy) {
        setValue(taskName)
      } else {
        setValue(tempTaskName ?? '')
      }
    }, [dummy, taskName, tempTaskName])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!dummy) {
        handleChangeContent?.(e)
        setValue(e.target.value)
      }
    }

    return (
      <TaskItemLayout
        disabled={disabled}
        data-testid={`task-list-item-create-from-${previousTask?.id ? previousTask?.id : 'root'}`}
        dateContent={<Text color="text-light">{date}</Text>}
        icon={
          <TaskItemIcon
            color={streamColor}
            icon={taskTypeIcon}
            disabled={disabled}
            onClick={onCancel}
            hoverIcon={!isDefault ? 'close' : undefined}
            withConnectors={previousTask?.id ? { previousIcon: previousTaskTypeIcon } : undefined}
          />
        }
        mainContent={
          <Box width="100%">
            <TextInput
              ref={ref}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              autoFocus={!isDefault}
              plain
              value={value}
              placeholder={t('enterTitle')}
              css={`
                padding: 0;
                &:focus {
                  outline: none;
                }
              `}
            />

            {streamName && levelName && (
              <CreateTaskHelpTextBox>
                <Text
                  data-testid="task-item-create-input-subtext"
                  size="xsmall"
                  color="text-light"
                  truncate
                  css="position: absolute; top: 4px;"
                >
                  {t('stream', {
                    streamName,
                    level: levelName
                  })}
                </Text>
              </CreateTaskHelpTextBox>
            )}
          </Box>
        }
        endContent={duration ? <Text color="text-light">{formatDuration(duration, 2)}</Text> : null}
        suffixContent={<Avatar />}
      />
    )
  }
)

const CreateTaskHelpTextBox = styled(Box)`
  position: relative;
  bottom: 7px;
`
