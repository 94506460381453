import { useCallback } from 'react'

import { useFeature } from '../hooks'
import { routes } from './routes'

export const useRouting = () => {
  const { isEnabled } = useFeature()
  const reactRunbook = isEnabled('react_runbook')

  const toRunbook = useCallback(
    (params: Parameters<typeof routes.toRunbook>[0]) => {
      return routes.toRunbook({ reactRunbook, ...params })
    },
    [reactRunbook]
  )

  return { toRunbook }
}
