import { Children, createElement, useEffect, useMemo, useState } from 'react'
import { AccordionExtendedProps, Accordion as GrommetAccordion } from 'grommet'
import { MergeExclusive } from 'type-fest'

type AccordionControlledProps = Omit<AccordionExtendedProps, 'animate'> & {
  iconColor?: string
}
type AccordionUncontrolledProps = Omit<AccordionControlledProps, 'activeIndex'> & {
  initialActiveIndex?: number | number[]
  disabledIndices?: number[]
}
type AccordionProps = MergeExclusive<AccordionUncontrolledProps, AccordionControlledProps & { controlled: true }>

export const Accordion = ({ controlled, ...props }: AccordionProps & { controlled?: boolean }) =>
  controlled ? <AccordionControlled {...props} /> : <AccordionUncontrolled {...props} />

const AccordionUncontrolled = ({
  children,
  onActive,
  initialActiveIndex = [],
  disabledIndices = [],
  ...props
}: AccordionUncontrolledProps) => {
  const initialActiveIndices = useMemo(
    () =>
      Array.isArray(initialActiveIndex) || initialActiveIndex === undefined ? initialActiveIndex : [initialActiveIndex],
    [initialActiveIndex]
  )
  const [activeIndices, setActiveIndices] = useState(initialActiveIndices)

  const includesDisabledPanel = (indexes: number[]) =>
    disabledIndices.length > 0 && indexes.filter(i => disabledIndices.includes(i)).length > 0

  const handleActive: typeof onActive = newActiveIndex => {
    if (includesDisabledPanel(newActiveIndex)) {
      setActiveIndices(prev => prev.filter(i => !disabledIndices.includes(i)))
      return
    }
    onActive?.(newActiveIndex)
    setActiveIndices(newActiveIndex)
  }

  useEffect(() => {
    if (includesDisabledPanel(activeIndices)) {
      setActiveIndices(prev => prev.filter(i => !disabledIndices.includes(i)))
    }
  }, [disabledIndices])

  return (
    <AccordionControlled {...props} activeIndex={activeIndices} onActive={handleActive}>
      {children}
    </AccordionControlled>
  )
}

const AccordionControlled = ({
  children,
  onActive,
  activeIndex,
  iconColor,
  multiple = true,
  ...props
}: AccordionControlledProps) => (
  <GrommetAccordion activeIndex={activeIndex} onActive={onActive} multiple={multiple} {...props}>
    {Children.toArray(children)
      .filter(Boolean)
      .map((child: any, i) =>
        child
          ? createElement(child.type, {
              iconColor,
              ...child.props,
              key: i,
              active: Array.isArray(activeIndex) ? activeIndex.includes(i) : i === activeIndex
            })
          : null
      )}
  </GrommetAccordion>
)
