import { useRecoilCallback, useRecoilTransaction_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { useNavigate } from 'react-router-dom'
import { extend } from 'lodash'

import {
  accountSlugUrlParamState,
  runbookResponseState_INTERNAL,
  runbookVersionResponseState_INTERNAL,
  runbookVersionUrlParamState,
  taskListResponseState_INTERNAL
} from '../models'
import {
  RunbookRunCancelResponse,
  RunbookRunCreateResponse,
  RunbookRunPauseResponse,
  RunbookRunResumeResponse
} from 'main/services/api/data-providers/runbook-types'
import { RunbookShowRunbook } from 'main/services/queries/types'
import { getTasks, TaskListResponseType } from 'main/services/queries/use-tasks'
import { getRunbookVersion, GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'

type ProgressRunbookResponse =
  | RunbookRunCreateResponse
  | RunbookRunPauseResponse
  | RunbookRunResumeResponse
  | RunbookRunCancelResponse

/**
 * With create, pause, and cancel, we always have a new runbook version, which means a new navigational state
 * triggering updates to runbook version and task list data. However, we have to manually update the runbook
 * data in store with the response's runbook.
 *
 * With resume, we get updated runbook data with updated current_version data but the current_version is not
 * newly created (it has the same id).
 *
 * ***********************
 *
 * Our url supports a 'current_version' alias and we want all actions to result in a numerical id
 * format (for now), so we need to navigate sometimes when it is actually a resume.
 *
 * So the logic is: if its current_version url OR the response's version and the url's version are different,
 * navigate and let that take care of re-requesting the rbv and task list data. Update the runbook data store
 * manually.

 * If we're on a numerical version url and we resume, we need to update the data without navigating. In this case
 * just refetch all the data.
 */

export const useProcessRunResponse = () => {
  const navigate = useNavigate()

  return useRecoilCallback(({ snapshot, set }) => async (response: ProgressRunbookResponse) => {
    const responseRunbook = response.runbook ?? response.meta.runbook
    const responseRunbookCurrentVersionId = responseRunbook.current_version?.id || responseRunbook.runbook_version_id
    const runbookVersionUrlParam = await snapshot.getPromise(runbookVersionUrlParamState)

    if (runbookVersionUrlParam === 'current_version' || runbookVersionUrlParam !== responseRunbookCurrentVersionId) {
      const accountSlugUrlParam = await snapshot.getPromise(accountSlugUrlParamState)

      navigate(
        `/app/${accountSlugUrlParam}/runbooks/${responseRunbook.id}/${responseRunbookCurrentVersionId}/tasks/react-list`
      )

      // updating the runbook data here even though need to do it across both conditions because in the second we're transacting all 3 data store updates
      set(runbookResponseState_INTERNAL, prev =>
        produce(prev, draft => {
          extend(draft.runbook, responseRunbook)
        })
      )
    }
    // In the resume case, we should just refresh all the data, so this triggers this with proper suspense loading states in the UI
    else {
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook-version' } }))
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook' } }))
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'tasks' } }))
    }
  })
}

// Note: the below is similar to the Run response handler (since triggers a version/task reload),
// but the useNavigate was causing issues, so made it separate
export const useProcessMergeResponse = () => {
  const updatePageData = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      ({
        taskData,
        runbookVersionData,
        runbookData
      }: {
        taskData: TaskListResponseType
        runbookVersionData: GetRunbookVersionResponse
        runbookData: RunbookShowRunbook
      }) => {
        set(
          taskListResponseState_INTERNAL,
          produce(taskData, draft => {
            extend(draft, taskData)
          })
        )

        set(
          runbookVersionResponseState_INTERNAL,
          produce(runbookVersionData, draft => {
            extend(draft, runbookVersionData)
          })
        )

        set(runbookResponseState_INTERNAL, prev =>
          produce(prev, draft => {
            extend(draft.runbook, runbookData)
          })
        )
      }
  )

  return useRecoilCallback(() => async (response: ProgressRunbookResponse) => {
    const responseRunbook = response.runbook ?? response.meta.runbook
    const responseRunbookId = responseRunbook.id
    const responseRunbookCurrentVersionId = responseRunbook.current_version?.id || responseRunbook.runbook_version_id

    const [taskData, runbookVersionData] = await Promise.all([
      getTasks(responseRunbookId, responseRunbookCurrentVersionId),
      getRunbookVersion(responseRunbookId, responseRunbookCurrentVersionId)
    ])

    updatePageData({
      taskData,
      runbookVersionData,
      runbookData: responseRunbook
    })
  })
}
