import { useRecoilValue } from 'recoil'

import { AccordionPanel, TextInput } from '@cutover/react-ui'
import { taskListLookupState } from 'main/recoil/runbook'
import { TaskSelectField } from 'main/components/shared/form/task-select'
import { TaskEditFormType } from './task-edit-form'
import { useLanguage } from 'main/services/hooks'

export const TaskEditDependenciesPanel = ({
  iconColor,
  successorIds,
  possiblePredecessorsIds
}: {
  iconColor: string
  successorIds: number[]
  possiblePredecessorsIds: number[]
}) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })
  const taskLookup = useRecoilValue(taskListLookupState)
  const successorTasks = successorIds?.map(id => taskLookup[id])
  const possiblePredecessorsTasks = possiblePredecessorsIds?.map(id => taskLookup[id])

  return (
    <AccordionPanel label={t('dependencies.header')} icon="predecessors" iconColor={iconColor}>
      <TaskSelectField<TaskEditFormType>
        label={t('fields.predecessors.label')}
        name="predecessor_ids"
        tasks={possiblePredecessorsTasks ?? []}
        placeholderValue={t('fields.predecessors.placeholderValue')}
        placeholder={t('fields.predecessors.placeholder')}
        required={false}
      />
      {successorTasks?.length > 0 ? (
        <TaskSelectField<TaskEditFormType>
          label={t('fields.successors.label')}
          name="successor_ids"
          placeholder={t('fields.predecessors.placeholder')}
          tasks={successorTasks}
          readOnly
          required={false}
        />
      ) : (
        <TextInput label={t('fields.successors.label')} readOnly value={t('fields.successors.placeholderValue')} />
      )}
    </AccordionPanel>
  )
}
