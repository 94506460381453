import { createInstance, InitOptions, ReadCallback } from 'i18next'
import { initReactI18next, Namespace } from 'react-i18next'
import { atom, selectorFamily } from 'recoil'

export const NAMESPACES = [
  'accounts',
  'activities',
  'apps',
  'authentication',
  'changeRequests',
  'common',
  'components',
  'connectionSettings',
  'connectSettings',
  'customFieldGroups',
  'customFields',
  'dashboard',
  'dataSources',
  'incidentSeverities',
  'instanceSettings',
  'integrationDebug',
  'integrationSettings',
  'integrationStatuses',
  'myTasks',
  'navigation',
  'notification',
  'pageHeader',
  'roleMappings',
  'roleTypes',
  'runbook',
  'runbooks',
  'runbookTypes',
  'runbookUsers',
  'samlConfigurations',
  'saveFilters',
  'sidebarNav',
  'tasks',
  'taskTypes',
  'userAppTokens',
  'users',
  'userVerify'
]

export const DEFAULT_NAMESPACE = 'common'

export const DEFAULT_OPTIONS = {
  ns: NAMESPACES,
  defaultNS: DEFAULT_NAMESPACE,
  fallbackNS: 'common',
  fallbackLng: 'en',
  debug: false,
  preload: ['en'],
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    skipOnVariables: false
  },
  react: {
    useSuspense: false
  }
}

export const createI18nInstance = (opts: InitOptions & { test?: boolean } = {}) => {
  let { test, ...initOptions } = opts
  const i18n = createInstance()

  if (process.env.NODE_ENV === 'test' && test) {
    const Backend = require('i18next-fs-backend')
    i18n.use(Backend)

    initOptions = {
      ...initOptions,
      ...{
        initImmediate: false,
        backend: {
          loadPath: 'src/Assets/locales/{{ns}}/en.json'
        }
      }
    }
  } else {
    i18n
      // Custom back-end loading language files during webpack compilation.
      // From https://gist.github.com/SimeonC/6a738467c691eef7f21ebf96918cd95f#file-i18nextnamespacejson-ts
      .use({
        type: 'backend',
        read(language: string, namespace: string, callback: ReadCallback): void {
          import(`../Assets/locales/${namespace}/${language}.json`)
            .then(resources => callback(null, resources))
            .catch(error => callback(error, null))
        }
      })
  }

  i18n.use(initReactI18next).init({ ...DEFAULT_OPTIONS, ...initOptions })
  addFormatters(i18n)

  return i18n
}

const addFormatters = (instance: ReturnType<typeof createI18nInstance>) => {
  instance.services.formatter?.add('capitalize', (value: string) => {
    return value.replace(/^./, value[0].toUpperCase())
  })

  instance.services.formatter?.add('bold', (value: string) => {
    return '<strong>' + value + '</strong>'
  })
}

const i18n = createI18nInstance()

export default i18n

///
// Below is unstable recoil configuration, do not touch unless you know what you're doing
export const scopedTranslations = selectorFamily({
  key: 'i18n:scoped',
  get:
    ({ ns, keyPrefix }: { ns: Namespace; keyPrefix?: string }) =>
    ({ get }) => {
      const locali18nInstance = get(i18nAtom)
      return locali18nInstance.getFixedT('en', ns, keyPrefix)
    }
})

const i18nAtom = atom({
  key: 'translations',
  dangerouslyAllowMutability: true,
  default: i18n
})
