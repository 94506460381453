import { CustomField, FieldValue } from 'main/services/queries/types'

export const buildDefaultFieldValues = (
  customFields: CustomField[],
  groupedCustomFields: Record<number, CustomField[]>,
  initialFieldValues: Record<CustomField['id'], any> = {},
  integrationGroupedCustomFields?: Record<number, CustomField[]>
) => {
  const defaultFieldValues: { [key: number]: Partial<FieldValue> } = {}
  customFields.forEach(cf => {
    const value = cf.field_type.slug === 'textarea' ? '' : null
    defaultFieldValues[cf.id] = {
      value: cf.default_value || value,
      field_option_id: null
    }
  })

  Object.values(groupedCustomFields).forEach(group => {
    group.forEach(cf => {
      const value = cf.field_type.slug === 'textarea' ? '' : null
      if (
        cf.type !== 'MultiSearchableCustomField' &&
        cf.type !== 'SearchableCustomField' &&
        cf.type !== 'DependentCustomField'
      )
        defaultFieldValues[cf.id] = {
          value: cf.default_value || value,
          field_option_id: null
        }
    })
  })

  if (integrationGroupedCustomFields) {
    Object.values(integrationGroupedCustomFields).forEach(group => {
      group.forEach(cf => {
        const value = cf.field_type.slug === 'textarea' ? '' : null
        if (
          cf.type !== 'MultiSearchableCustomField' &&
          cf.type !== 'SearchableCustomField' &&
          cf.type !== 'DependentCustomField'
        )
          defaultFieldValues[cf.id] = {
            value: cf.default_value || value,
            field_option_id: null
          }
      })
    })
  }

  for (const [key, value] of Object.entries(initialFieldValues)) {
    defaultFieldValues[key as unknown as number] = value
  }

  return defaultFieldValues
}
