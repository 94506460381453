import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, CheckboxGroup, Form, Message, Modal, RadioboxGroup, TextInput } from '@cutover/react-ui'
import { RunbookValidation } from './runbook-validation'
import { useHandleRunbookResponse } from 'main/recoil/runbook/updaters/tasks-operations'
import { useFeature } from 'main/services/hooks'
import { useLanguage } from 'main/services/hooks/use-language'
import { PauseAndCancelRunPayload, ResumeRunPayload, useRunUpdate } from 'main/services/queries/use-run'

type ResumeRunbookModalProps = {
  onClose: () => void
  runbookId: string | number
  runId: string | number
  runbookVersionId: string | number
  templateType: 'off' | 'default' | 'snippet'
  runType: 'rehearsal' | 'live'
  isParentRunbook: boolean
  isRunCommsOff: boolean
}

type ResumeRunbookModalForm = {
  type: 'resume' | 'cancel'
  options: ('taskNotify' | 'notify')[] | null
  message: string | null
}

export const ResumeRunbookModal = ({
  onClose,
  templateType,
  runbookId,
  runbookVersionId,
  runId,
  runType,
  isParentRunbook,
  isRunCommsOff
}: ResumeRunbookModalProps) => {
  const [isValidating, setIsValidating] = useState<boolean | null>(null)
  const [errorScore, setErrorScore] = useState(0)
  const { isEnabled } = useFeature()
  const reactRunbookFeatureFlag = isEnabled('react_runbook')
  const processRunbookResponse = useHandleRunbookResponse()

  const { t } = useLanguage('runbook')
  const resumeMutation = useRunUpdate({ runbookId, runId, type: 'resume' })
  const cancelMutation = useRunUpdate({ runbookId, runId, type: 'cancel' })

  const isSnippet = templateType === 'snippet'

  const { control, ...methods } = useForm<ResumeRunbookModalForm>({
    defaultValues: {
      type: 'resume',
      options: isRunCommsOff ? null : ['taskNotify'],
      message: null
    }
  })

  const type = methods.watch('type')
  const options = methods.watch('options')
  const notify = options?.includes('notify') ?? false

  const mutation = type === 'resume' ? resumeMutation : cancelMutation

  useEffect(() => {
    if (!notify) {
      methods.setValue('message', null)
    }
  }, [notify])

  const onFormSubmit = ({ type, options, message }: ResumeRunbookModalForm) => {
    let mutationPayload

    if (type === 'resume') {
      mutationPayload = {
        type: 'resume',
        task_notify: options?.includes('taskNotify') ?? false,
        resume_notify: options?.includes('notify') ?? false,
        resume_message: message
      } as ResumeRunPayload
    } else {
      mutationPayload = {
        type: 'cancel',
        action_notify: options?.includes('notify') ?? false,
        action_message: message
      } as PauseAndCancelRunPayload
    }

    mutation.mutate(mutationPayload, {
      onSuccess: data => {
        if (reactRunbookFeatureFlag) {
          // TODO: type
          // @ts-ignore
          processRunbookResponse(data)
        }

        onClose()
      }
    })
  }

  const isSubmitting = resumeMutation.isLoading || cancelMutation.isLoading

  const confirmText = () => {
    if (isValidating) {
      return t('resumeRunbookModal.validatingText')
    } else if (type === 'resume') {
      return isSubmitting ? t('resumeRunbookModal.resumeSubmitingText') : t('resumeRunbookModal.resumeSubmitText')
    } else {
      return isSubmitting ? t('resumeRunbookModal.cancelSubmitingText') : t('resumeRunbookModal.cancelSubmitText')
    }
  }

  const hasErrorScoreForResume = () => {
    return errorScore > 0 && type === 'resume'
  }

  return (
    <Modal
      title={t('resumeRunbookModal.heading')}
      open
      onClose={onClose}
      hasCancelButton={false}
      loading={isValidating || isSubmitting}
      confirmText={confirmText()}
      onClickConfirm={
        hasErrorScoreForResume() || isValidating || isSubmitting ? undefined : methods.handleSubmit(onFormSubmit)
      }
      confirmIcon={type === 'resume' ? 'play-arrow' : 'dnd-forwardslash'}
    >
      {mutation.isError && (
        <Box margin={{ bottom: 'medium' }}>
          <Message type="error" message={mutation.error.errors} />
        </Box>
      )}
      <RunbookValidation
        closeModal={onClose}
        isSnippet={isSnippet}
        runbookId={runbookId}
        runbookVersionId={runbookVersionId}
        setIsValidating={setIsValidating}
        setErrorScore={setErrorScore}
      />
      <Form>
        <Box gap="14px" margin={{ top: '20px', bottom: '20px' }}>
          {/* TODO:  Investigate issue with tasks dissappearing on submit */}
          <Controller // controlling to render correct confirm text when switching between options
            name="type"
            control={control}
            render={({ field: { name, onChange, onBlur, ref } }) => (
              <RadioboxGroup
                name={name}
                direction="row"
                label={t('resumeRunbookModal.runActionLabel', { item: isSnippet ? 'Snippet' : 'Runbook' })}
                disabled={isSubmitting}
                options={[
                  { value: 'resume', label: t('resumeRunbookModal.resume') },
                  { value: 'cancel', label: t('resumeRunbookModal.cancel') }
                ]}
                defaultValue="resume"
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
              />
            )}
          />

          {type === 'cancel' && runType === 'live' && (
            <Message
              type="error"
              message={isParentRunbook ? t('resumeRunbookModal.parentRunbookProblem') : t('resumeRunbookModal.problem')}
            />
          )}
          {type === 'resume' && isParentRunbook && <Message type="info" message={t('resumeRunbookModal.warning')} />}

          {!isRunCommsOff && (
            <Controller
              control={control}
              name="options"
              render={({ field: { onChange } }) => (
                <CheckboxGroup
                  onChange={e => e && onChange(e.value)}
                  label={t('resumeRunbookModal.options')}
                  options={[
                    ...(type === 'resume'
                      ? [{ value: 'taskNotify', option: 'taskNotify', label: t('resumeRunbookModal.taskNotify') }]
                      : []),
                    { value: 'notify', option: 'notify', label: t('resumeRunbookModal.notify', { item: type }) }
                  ].filter(Boolean)}
                />
              )}
            />
          )}

          {notify && (
            <TextInput
              {...methods.register('message')}
              label={t('resumeRunbookModal.messageLabel')}
              disabled={isSubmitting}
            />
          )}
        </Box>
      </Form>
    </Modal>
  )
}
