import { ReactNode, Suspense, useEffect } from 'react'
import { eventManager } from 'event-manager'

import { PageFilterPanelLoader, PageHeadingLoader, PageSubHeadingLoader } from '@cutover/react-ui'
import { StreamEditPanel, TaskEditPanel } from './right-panels'
import { UserOrTeamDetailsPanel } from './right-panels/user-team-details-panel'
import { RunbookFilter } from './runbook-filter/runbook-filter'
import { RunbookHeader } from './runbook-header/runbook-header'
import { RunbookRightNavigation } from './runbook-right-navigation/runbook-right-navigation'
import { RunbookSubHeader } from './runbook-sub-header/runbook-sub-header'
import { RunbookBanner } from '../layout/runbook-banner'
import { Layout } from 'main/components/layout'
import { useDefaultLayout } from 'main/components/layout/layout-hooks'

export const RunbookLayout = ({ children }: { children?: ReactNode }) => {
  useDefaultLayout({
    filterPanel: true,
    subHeader: true,
    rightNav: true
  })

  const refreshRunbookPageData = () => {
    window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook-version' } }))
    window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook' } }))
    window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'tasks' } }))
  }

  useEffect(() => {
    eventManager.on('refresh-runbook-page-data', refreshRunbookPageData)

    return () => {
      eventManager.off('refresh-runbook-page-data', refreshRunbookPageData)
    }
  }, [])

  return (
    <Layout
      header={
        <Suspense fallback={<PageHeadingLoader />}>
          <RunbookHeader />
        </Suspense>
      }
      subHeader={
        <Suspense fallback={<PageSubHeadingLoader />}>
          <RunbookSubHeader />
        </Suspense>
      }
      banner={
        <Suspense fallback={<></>}>
          <RunbookBanner />
        </Suspense>
      }
      filter={
        <Suspense fallback={<PageFilterPanelLoader />}>
          <RunbookFilter />
        </Suspense>
      }
      rightNavigation={<RunbookRightNavigation />}
      rightPanels={
        <>
          <StreamEditPanel />
          <TaskEditPanel />
          <UserOrTeamDetailsPanel />
        </>
      }
    >
      {children}
    </Layout>
  )
}
