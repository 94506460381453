import { memo, Ref, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { AvatarSubject, DependencyType, TaskItem } from '@cutover/react-ui'
import {
  getAccountTaskType,
  streamState,
  useLoadingIdValue,
  useMenu,
  userIdToTeamLookup,
  useRunbook,
  useRunbookCurrentVersion,
  useRunbookPermission,
  useRunbookVersion,
  useSelectedIdsValue,
  useSetMenuState,
  useSetModalActiveState,
  useSetSelectedIds,
  useSetToggleTaskCreateState,
  useTaskItemPermissions,
  useTaskListTask,
  useTaskListTaskStream,
  useTaskListTaskTeams,
  useTaskListTaskUsers,
  useTaskProgression,
  useTaskProgressionState
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import { useEditTaskPanel, useUserOrTeamDetailsEditPanel } from 'main/context/panel-context'
import { runbookComponentsStateLookup } from 'main/recoil/runbook/models/runbook-version/runbook-components'
import { TaskItemCreateToggle } from './task-item-create'
import { useTaskListItemProps } from './task-list-item-props'
import { useBuildTaskActionMenuItems, useBuildTaskDependencyMenuItems } from './use-task-list-item-menu-builder'

type TaskContentProps = {
  id: number
  critical?: boolean
  float?: number
  previousTaskId?: number
  nextTaskId?: number
}

// TODO: remove when transaction updates have been made to recoil data updating
export const TaskListItem = ({ id, ...props }: TaskContentProps) => {
  const task = useTaskListTask(id)
  if (!task) return null
  return <TaskListItemInner id={id} {...props} />
}

const MIN_DEPENDENCY_MENU_WIDTH = 200
const MAX_DEPENDENCY_MENU_WIDTH = 320
const MAX_DEPENDENCY_MENU_HEIGHT = 280

const TaskListItemInner = memo(({ id, critical, float, previousTaskId, nextTaskId }: TaskContentProps) => {
  const first = !previousTaskId

  const [isOptionsMenuOpen, setOptionsMenuOpen] = useState<boolean>(false)
  const { setMenu, clearMenu, setMenuOpenState } = useSetMenuState()
  const { menu, isMenuOpen } = useMenu()
  const { taskId, type } = menu
  const buildTaskActionMenuItems = useBuildTaskActionMenuItems()
  const buildTaskDependencyMenuItems = useBuildTaskDependencyMenuItems()

  const updating = useLoadingIdValue(id)
  const taskProgressionState = useTaskProgressionState(id)
  const { openPanel, closePanel, taskId: editPanelTaskId } = useEditTaskPanel()
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const { modalOpen } = useSetModalActiveState()
  const toggleTaskCreate = useSetToggleTaskCreateState()
  const {
    openPanel: openPeoplePanel,
    closePanel: closePeoplePanel,
    userOrTeam: panelUserOrTeam
  } = useUserOrTeamDetailsEditPanel()
  const { selectedIdToggle, selectedIdsRemoveAll } = useSetSelectedIds()
  const selected = useSelectedIdsValue().includes(id)
  const runbookVersion = useRunbookVersion()
  const stream = useTaskListTaskStream(id)
  const runbook = useRunbook()
  const runbookCurrentVersion = useRunbookCurrentVersion()
  const parentStream = useRecoilValue(streamState({ id: stream.parent_id || 0 }))
  const previousTask = useTaskListTask(previousTaskId || id)
  const previousTaskType = useRecoilValue(getAccountTaskType(previousTask?.task_type_id))
  const runbookComponentLookup = useRecoilValue(runbookComponentsStateLookup)
  const runbookUserTeamLookup = useRecoilValue(userIdToTeamLookup)
  const task = useTaskListTask(id)
  const taskType = useRecoilValue(getAccountTaskType(task.task_type_id))
  const teams = useTaskListTaskTeams(id)
  const userCanUpdate = useRunbookPermission({ attribute: 'update' })
  const users = useTaskListTaskUsers(id)
  const taskPermissions = useTaskItemPermissions(id)
  const { canCreateTaskAfterFromIcon } = taskPermissions
  const taskItemProps = useTaskListItemProps({
    canCreate: canCreateTaskAfterFromIcon,
    canUpdate: userCanUpdate,
    critical,
    float,
    first,
    iconDisabled:
      ['finishabled', 'startable'].includes(taskProgressionState?.stage || '') &&
      taskProgressionState?.override !== 'fixed-start',
    nextTaskId,
    parentStream,
    previousTask,
    previousTaskType,
    run: runbookVersion.run,
    runbookComponent: task.runbook_component_id ? runbookComponentLookup[task.runbook_component_id] : undefined,
    runbookCurrentVersion,
    runbookUserTeamLookup,
    runbookVersion,
    runbook,
    stream,
    task,
    taskType,
    teams,
    users
  })
  const { resolveProgressionModalRecoilCallback, startOrFinishTask, onSkipTasks } = useTaskProgression()

  const handleOptionsClick = useRecoilCallback(() => async (triggerRef: Ref<HTMLElement>) => {
    const menuItems = await buildTaskActionMenuItems({ task, taskPermissions })
    if (taskId === id && type === 'options') {
      clearMenu()
    } else {
      setMenu({
        taskId: id,
        triggerRef,
        type: 'options',
        keyPrefix: 'task-opts-menu',
        items: menuItems
      })
      setMenuOpenState(true)
    }
  })

  const handleDependenciesClick = useRecoilCallback(
    () => async (triggerRef: Ref<HTMLElement>, dependencyType: DependencyType) => {
      const menuItems = await buildTaskDependencyMenuItems({ task, type: dependencyType, runbookVersion })
      if (taskId === id && type === dependencyType) {
        clearMenu()
      } else {
        setMenu({
          taskId: id,
          triggerRef,
          type: dependencyType,
          items: menuItems,
          minWidth: MIN_DEPENDENCY_MENU_WIDTH,
          maxWidth: MAX_DEPENDENCY_MENU_WIDTH,
          maxHeight: MAX_DEPENDENCY_MENU_HEIGHT
        })
        setMenuOpenState(true)
      }
    }
  )

  useEffect(() => {
    setOptionsMenuOpen(taskId === id && isMenuOpen && type === 'options')
  }, [taskId, isMenuOpen])

  const handleTaskEdit = useCallback(() => {
    if (editPanelTaskId === id) {
      closePanel()
    } else {
      openPanel({
        taskId: id
      })
    }
  }, [id, openPanel, closePanel, editPanelTaskId])

  const handleBulkTaskSelection = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      selectedIdToggle(id)
    },
    [selectedIdToggle, id]
  )

  const onAdd = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      toggleTaskCreate({ fromPredecessorId: id })
    },
    [toggleTaskCreate, id]
  )

  const handleClickTaskIcon = useCallback(
    async (e: SyntheticEvent) => {
      e.stopPropagation()
      if (taskItemProps.iconProps.hoverIcon === 'add') return onAdd(e)

      const nextModal = await resolveProgressionModalRecoilCallback(id)
      return nextModal ? modalOpen(nextModal) : startOrFinishTask(id)
    },
    [resolveProgressionModalRecoilCallback, id]
  )

  const handleCommentsClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    console.log('item comments for', id)
  }, [])

  const handleSkipClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      selectedIdsRemoveAll()
      onSkipTasks([id])
    },
    [selectedIdsRemoveAll, id]
  )

  const handleClickAvatar = useCallback(
    (e: SyntheticEvent, subject?: AvatarSubject) => {
      e.stopPropagation()

      if (subject) {
        panelUserOrTeam?.id === subject.id
          ? closePeoplePanel()
          : openPeoplePanel({
              userOrTeam: subject
            })
      }
    },
    [panelUserOrTeam, closePeoplePanel, openPeoplePanel]
  )

  const handleErrorClick = useCallback(() => {}, [])

  return (
    <>
      <TaskItem
        {...taskItemProps}
        isOptionsMenuOpen={isOptionsMenuOpen}
        draggable={false}
        highlight={editPanelTaskId === id || selected}
        isLoadingTeams={false}
        onChangeSelect={handleBulkTaskSelection}
        onClick={handleTaskEdit}
        onClickAvatar={handleClickAvatar}
        onClickComments={handleCommentsClick}
        onClickError={handleErrorClick}
        onClickOptions={handleOptionsClick}
        onClickDependencies={handleDependenciesClick}
        onClickSkip={handleSkipClick}
        selected={selected}
        updating={updating}
        iconProps={{
          ...taskItemProps.iconProps,
          isLoading: updating,
          onClick: handleClickTaskIcon
        }}
        labelCommentsButton={t('comments', { count: task.comments_count })}
        labelEarly={t('taskStartDiffText.early')}
        labelLate={t('taskStartDiffText.late')}
        labelMenuButton={t('moreOptions')}
        labelSelectCheckbox={t('selectTask')}
        labelSkipButton={t('skipTaskButtonLabel')}
        labelStarted={taskItemProps.started ? t('taskStartDiffText.forecastToStart') : t('taskStartDiffText.started')}
      />
      <TaskItemCreateToggle predecessorId={id} />
    </>
  )
})
