import { useMemo, useState } from 'react'
import * as yup from 'yup'

import { IconButton, useNotify } from '@cutover/react-ui'
import { DataSourcesEditForm } from './data-sources-edit-form'
import { dataSourceFormValidationSchema } from '../data-sources-form-validation-schema'
import { DeleteDatasourceModal } from '../modals/delete-data-source-modal'
import { FormEditPanel } from 'main/components/shared/form'
import { queryClient } from 'main/query-client'
import { ApiError } from 'main/services/api'
import { useLanguage } from 'main/services/hooks'
import { QueryKeys } from 'main/services/queries/query-keys'
import { DataSource } from 'main/services/queries/types'
import { useUpdateDataSourceMutation } from 'main/services/queries/use-data-sources'
import { usePermissions } from 'main/services/queries/use-permissions'

type DataSourceEditPanelType = {
  dataSource: DataSource
  onClose: () => void
}

const formType = dataSourceFormValidationSchema()
export type DataSourceEditFormType = yup.InferType<typeof formType>

export const DataSourcesEdit = ({ dataSource, onClose }: DataSourceEditPanelType) => {
  const { t } = useLanguage('dataSources')
  const notify = useNotify()
  const permissions = usePermissions('data-source')
  const patchMutation = useUpdateDataSourceMutation()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [apiError, setApiError] = useState<string | undefined>(undefined)
  const canDelete = permissions('delete')
  const canEdit = permissions('update')

  const findPrimaryKey = useMemo(() => {
    let primaryKey = ''
    if (dataSource?.json_mappings) {
      dataSource.json_mappings.forEach((key: any) => {
        if (key.primary) {
          primaryKey = key.mapping
        }
      })
      return primaryKey
    }
  }, [dataSource])

  const defaultEditPanelValues = {
    json_mappings: findPrimaryKey,
    name: dataSource.name,
    url: dataSource.url,
    http_method: dataSource.http_method,
    search_parameter_name: dataSource.search_parameter_name,
    http_headers: dataSource.http_headers
  }

  const handleOnSubmit = async (formData: DataSourceEditFormType) => {
    formData.id = dataSource.id
    patchMutation.mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.DataSource])
        notify.success(t('dataSources.modals.updateDataSource.toasters.success'))
        onClose()
      },
      onError: error => {
        const castedError = error as ApiError
        if (castedError?.errors) {
          setApiError(castedError?.errors.join(', '))
        }
        notify.error(t('dataSources.modals.updateDataSource.toasters.genericError'))
      }
    })
  }

  const handleClickDelete = () => {
    setIsDeleteModalOpen(true)
  }

  return (
    <FormEditPanel<DataSourceEditFormType>
      onClose={onClose}
      onSubmit={handleOnSubmit}
      defaultValues={defaultEditPanelValues}
      readOnly={!canEdit}
      headerItems={
        canDelete
          ? [
              <IconButton
                label={t('dataSources.editPanel.main.deleteButton.label')}
                tipPlacement="top"
                icon="trash-o"
                onClick={handleClickDelete}
              />
            ]
          : undefined
      }
      title={t('dataSources.editPanel.main.title.label')}
      schema={dataSourceFormValidationSchema()}
    >
      <DataSourcesEditForm dataSource={dataSource} apiError={apiError} />
      <DeleteDatasourceModal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        dataSourceId={dataSource.id as number}
        onSubmit={() => {
          onClose()
        }}
      />
    </FormEditPanel>
  )
}
