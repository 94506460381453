export const QueryKeys = {
  IntegrationSettings: 'integration-settings',
  IntegrationActionItems: 'integration-action-items',
  InstanceSettings: 'instance-settings',
  CustomFields: 'custom-fields',
  CustomField: 'custom-field',
  ConnectSettings: 'connect-settings',
  ConnectVersions: 'connect-versions',
  ConnectSettingsAvailable: 'connect-settings-available',
  DataSource: 'data-source',
  DataSources: 'data-sources',
  Accounts: 'accounts',
  Account: 'account',
  ChangeRequests: 'change-requests',
  Runbooks: 'runbooks',
  RunbooksMeta: 'runbooks-meta',
  RunbookVersions: 'runbook_versions',
  RunbookIntegrations: 'runbook-integrations',
  Tasks: 'tasks',
  Favorites: 'favorites',
  Users: 'users',
  Snippets: 'snippets',
  Runs: 'runs',
  SamlConfigurations: 'saml-configurations',
  UserSettings: 'user-settings',
  MyTasks: 'my-tasks',
  RoleMappings: 'role-mappings'
} as const
