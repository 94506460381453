import { useEffect } from 'react'
import { useIntercom as useReactIntercom } from 'react-use-intercom'

import { useCurrentUser } from '../../recoil/current-user'
import { useGlobalConfig } from '../../services/hooks'
import { useInstanceName } from '../../services/hooks/use-instance-name'

const DEFAULT_TIMEOUT = 36000

export const useIntercom = () => {
  const { boot, shutdown } = useReactIntercom()
  const { hashed_email, first_name, last_name, email, created_at, sign_in_count } = useCurrentUser()
  const { version, deviseMaxSessionTimeout } = useGlobalConfig()
  const instanceName = useInstanceName()

  useEffect(() => {
    if (!hashed_email) return

    boot({
      name: `${first_name} ${last_name}`,
      email,
      userHash: hashed_email,
      createdAt: created_at,
      sessionDuration: (deviseMaxSessionTimeout ? parseInt(deviseMaxSessionTimeout) : DEFAULT_TIMEOUT) * 1000,
      customLauncherSelector: '#help',
      company: {
        companyId: instanceName,
        name: instanceName
      },
      customAttributes: {
        appVersion: version.label,
        signInCount: sign_in_count
      }
    })

    return shutdown
  }, [])
}
