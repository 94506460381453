import { useState } from 'react'
import * as yup from 'yup'

import { Box, Text } from '@cutover/react-ui'
import { TextInputField } from 'main/components/shared/form/form-fields'
import { TaskLineItem } from 'main/components/shared/task-line-item'
import { useRunbookId, useRunbookVersionId } from 'main/recoil/runbook'
import { useHandleRunbookResponse } from 'main/recoil/runbook/updaters/tasks-operations'
import { useLanguage } from 'main/services/hooks'
import { bulkSkipTasks } from 'main/services/queries/use-task'
import { RunbookResponse } from 'main/services/api/data-providers/runbook-types'
import { FormModal } from 'main/components/shared/form'

const validationSchema = yup.object({
  comment: yup.string().required()
})

export type SkipTaskFormSchema = yup.InferType<typeof validationSchema>

type TaskSkipProps = {
  ids: number[]
  onClose: () => void
}

export const TaskSkip = ({ ids, onClose }: TaskSkipProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskSkipModal' })
  const [loading, setLoading] = useState(false)

  const runbookId = useRunbookId()
  const runbookVersionId = useRunbookVersionId()
  const processTaskUpdateResponse = useHandleRunbookResponse()

  const defaultValues = {
    comment: ''
  }

  const handleSubmit = async (data: SkipTaskFormSchema) => {
    setLoading(true)
    const payload = {
      comment: data.comment,
      task_ids: ids
    }
    return await bulkSkipTasks(runbookId, runbookVersionId, payload)
  }

  return (
    <FormModal
      open
      data-testid="modal-task-skip"
      loading={loading}
      confirmText={t('confirmText')}
      confirmIcon="chevron-right"
      title={t('title')}
      key="task-skip-form"
      onError={() => setLoading(false)}
      onSubmit={handleSubmit}
      onSuccess={(response: RunbookResponse) => {
        setLoading(false)
        processTaskUpdateResponse(response)
        onClose()
      }}
      defaultValues={defaultValues}
      schema={validationSchema}
    >
      <Box direction="column" gap="medium">
        <Text>{t('content')}</Text>
        {ids.map(id => (
          <TaskLineItem key={id} taskId={id} />
        ))}
        <TextInputField label={t('reasonLabel')} name="comment" required />
      </Box>
    </FormModal>
  )
}
