import { TransactionInterface_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { extend, keyBy } from 'lodash'

import { RunbookShowRunbook, TaskListTask } from 'main/services/queries/types'
import {
  runbookResponseState_INTERNAL,
  runbookVersionResponseState_INTERNAL,
  taskListResponseState_INTERNAL
} from '../models'
import {
  RunbookChangedTask,
  RunbookVersionData
} from 'main/services/api/data-providers/runbook-types/runbook-shared-types'

// TODO: does not currently check data.id < RunBookActiveVersionModel.versionData.id) return which is done in angular.
// confirm this is still needed, see https://cutover.atlassian.net/browse/WIN-1296 from 3 years ago, and update if necessary.
export const updateVersionData =
  (transactionInterface: TransactionInterface_UNSTABLE) => (versionData: RunbookVersionData) => {
    const { set, get } = transactionInterface

    const runbookVersionResponseData = get(runbookVersionResponseState_INTERNAL)

    // FIXME: seems wrong -- can't just update the active version data if not viewing the active version (websocket update)
    const updatedVersionData = produce(runbookVersionResponseData, draftRunbookVersionResponse => {
      extend(draftRunbookVersionResponse, versionData)
    })

    set(runbookVersionResponseState_INTERNAL, runbookVersionResponseData)

    // FIXME: seems wrong - we're not updating the runbook_version_id, but we are updating the current_version
    updateRunbookData(transactionInterface)({ current_version: updatedVersionData.runbook_version })
  }

export const updateAllChangedTasks =
  ({ set, get }: TransactionInterface_UNSTABLE) =>
  (changedTasks: RunbookChangedTask[]) => {
    if (!changedTasks?.length) return

    const runbookVersionResponseState = get(runbookVersionResponseState_INTERNAL)
    const runbookComponents = runbookVersionResponseState.meta.runbook_components
    const runbookComponentLookup = keyBy(runbookComponents, 'id')

    setChangedTasks(set)({ changedTasks, runbookComponentLookup })
  }

// Is used in recoil callbacks and transactions. The `set` available in each of those has the same type.
export const setChangedTasks =
  (set: TransactionInterface_UNSTABLE['set']) =>
  ({
    changedTasks,
    runbookComponentLookup
  }: {
    changedTasks: RunbookChangedTask[]
    runbookComponentLookup: Record<number, any>
  }) => {
    set(taskListResponseState_INTERNAL, prevTaskResponse =>
      produce(prevTaskResponse, draftTaskResponse => {
        const taskLookup = keyBy(draftTaskResponse.tasks, 'id')

        changedTasks.forEach(taskChanges => {
          const existingTask = taskLookup[taskChanges.id]

          // TODO: check - what is the response like for bulk create? do we get multiple changed tasks
          // back that don't yet exist in the task array?
          // FIXME: conditional for `updated_at` needs to be `<=` until back-end returns the correct list and
          // updated timestamps for changed tasks. Reference tickets: CBE-379 and CBE-497.
          if (!existingTask) {
            draftTaskResponse.tasks.push(taskChanges as TaskListTask)
          } else if (existingTask.updated_at <= taskChanges.updated_at) {
            extend(existingTask, taskChanges)

            // re-join rb component fields because extend removes them (immutable update, confirm this)
            // @ts-ignore why does this task changes type not have field_values? check
            if (taskChanges.field_values && existingTask.runbook_component_id) {
              const runbookComponent = runbookComponentLookup[existingTask.runbook_component_id]
              if (!runbookComponent) return

              existingTask.field_values.push(...(runbookComponent.field_values ?? []))
            }
          }
        })
      })
    )
  }

export const updateRunbookData =
  ({ set }: TransactionInterface_UNSTABLE) =>
  (responseRunbook: Partial<RunbookShowRunbook>) => {
    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        extend(draftRunbookResponse.runbook, responseRunbook)
      })
    )
  }

export const updateTasksAndVersion =
  (transactionInterface: TransactionInterface_UNSTABLE) =>
  ({ versionData, changedTasks }: { versionData?: RunbookVersionData; changedTasks?: RunbookChangedTask[] }) => {
    if (versionData) {
      updateVersionData(transactionInterface)(versionData)
    }

    if (changedTasks) {
      updateAllChangedTasks(transactionInterface)(changedTasks)
    }
  }
