import { memo, MouseEvent as ReactMouseEvent, useCallback, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { sortBy } from 'lodash'
import parse from 'html-react-parser'
import { AxiosError } from 'axios'

import { Badge, Box, Message, Pill, RagIndicator } from '@cutover/react-ui'
import { CheckboxHierarchyGroup } from '../shared/checkbox-hierarchy-group'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { runbookIdState, runbookVersionIdState, useStreamsPermission } from 'main/recoil/runbook'
import {
  streamsInternalIdLookupState,
  streamsLookupState,
  streamsState,
  streamTaskCountsRecordState
} from 'main/recoil/runbook/models/runbook-version/streams'
import { useProcessStreamCreateResponse } from 'main/recoil/runbook/updaters/runbook-operations'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { useLanguage } from 'main/services/hooks'
import { createStream, StreamCreatePayload } from 'main/services/queries/use-stream'
import { useStreamFilter } from 'main/recoil/shared/filters/individual-filter-state'
import { useEditStreamPanel } from 'main/context/panel-context'
import { RagStatus, StreamListStream } from 'main/services/queries/types'
import { useCurrentUserId } from 'main/recoil/current-user'
import { FilterOptionHierarchy } from 'main/components/shared/filter/filter-types'

export const StreamGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const canCreateStreams = useStreamsPermission({ attribute: 'create' })
  const currentUserId = useCurrentUserId()
  const { streamId: editingStreamId, closePanel, openPanel } = useEditStreamPanel()
  const [streams, setStreams] = useStreamFilter()
  const [createStreamError, setCreateStreamError] = useState<string[]>([])
  const [createStreamLoading, setCreateStreamLoading] = useState(false)
  const taskCounts = useRecoilValue(streamTaskCountsRecordState)
  const streamLookup = useRecoilValue(streamsLookupState)
  const streamInternalIdLookup = useRecoilValue(streamsInternalIdLookupState)
  const parentStreams = useRecoilValue(streamsState)
  const processCreateStreamResponse = useProcessStreamCreateResponse()

  const handleStreamChange = useCallback(
    (selected: number[]) => {
      setStreams(selected)
    },
    [setStreams]
  )

  const streamCreate = useRecoilCallback(({ snapshot }) => async (payload: StreamCreatePayload) => {
    const runbookId = await snapshot.getPromise(runbookIdState)
    const runbookVersionId = await snapshot.getPromise(runbookVersionIdState)

    return await createStream({ runbookId, runbookVersionId, ...payload })
  })

  const handleStreamCreate = async (val: string, streamId?: number) => {
    setCreateStreamLoading(true)

    try {
      const response = await streamCreate({
        stream: {
          name: val.trim(),
          parent_id: streamId ? streamId.toString() : null
        }
      })
      if (response && response.stream) {
        createStreamError.length && setCreateStreamError([])
        setCreateStreamLoading(false)
        processCreateStreamResponse(response)
      }
    } catch (e) {
      if (e instanceof ApiError) {
        setCreateStreamError(e.errors)
      } else if (e instanceof AxiosError) {
        setCreateStreamError(e.response?.data.errors)
      }
      setCreateStreamLoading(false)
    }
  }

  const handleStreamEdit = (e: ReactMouseEvent, option: FilterOptionHierarchy) => {
    const clickedStreamInternalId = Number(option.value)
    const stream = streamInternalIdLookup[clickedStreamInternalId]
    if (editingStreamId === stream.id) {
      closePanel()
    } else {
      openPanel({ streamId: stream.id })
    }
  }

  const isStreamEditor = (stream: StreamListStream) => {
    return !!currentUserId && !!stream.permissions.create_task.includes(currentUserId)
  }

  return (
    <FilterAccordionPanel label={t('stream')} applied={!!streams?.length}>
      <CheckboxHierarchyGroup
        onEditClick={handleStreamEdit ? (_, option) => handleStreamEdit(_, option as FilterOptionHierarchy) : undefined}
        withTooltipLabel
        value={streams}
        // @ts-ignore
        onChange={handleStreamChange}
        options={parentStreams.map(stream => {
          const isEditor = isStreamEditor(stream)
          return {
            value: stream.internal_id,
            label: parse(stream.name) as string,
            suffix: (
              <StreamSuffix
                status={stream.status}
                statusMessage={stream.status_message}
                color={stream.color}
                isEditor={isEditor}
                filteredTaskCount={taskCounts[stream.id]?.filtered ?? 0}
              />
            ),
            itemCreateInputProps:
              canCreateStreams || isEditor
                ? {
                    onCreateItem: val => handleStreamCreate(val, stream.id),
                    addNewLabel: t('addNewFilterHierarchyLabel', { type: 'substream' }),
                    placeholder: t('enterNewFilterHierarchyTitle', { type: 'substream' }),
                    loading: createStreamLoading
                  }
                : undefined,
            children: sortBy(streamLookup[stream.id].children, 'name').map(child => {
              return {
                value: child.internal_id,
                label: parse(child.name) as string,
                suffix: (
                  <StreamSuffix
                    status={child.status}
                    statusMessage={child.status_message}
                    color={child.color}
                    filteredTaskCount={taskCounts[child.id]?.filtered ?? 0}
                  />
                )
              }
            })
          }
        })}
        itemCreateInputProps={
          canCreateStreams
            ? {
                onCreateItem: val => handleStreamCreate(val),
                addNewLabel: t('addNewFilterHierarchyLabel', { type: 'stream' }),
                placeholder: t('enterNewFilterHierarchyTitle', { type: 'stream' }),
                loading: createStreamLoading
              }
            : undefined
        }
      />
      <Message message={createStreamError} type="error" />
    </FilterAccordionPanel>
  )
})

const StreamSuffix = ({
  status,
  statusMessage,
  color,
  filteredTaskCount,
  isEditor
}: {
  status: RagStatus
  statusMessage: string | null
  color: string
  filteredTaskCount: number
  isEditor?: boolean
}) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  return (
    <Box direction="row" gap="4px" align="center">
      {isEditor && <Pill label={t('streamEditor')} tip={t('streamEditorInfo')} />}
      <RagIndicator status={status} tip={statusMessage} tipPlacement="top" data-testid={`rag-indicator-${status}`} />
      <Badge label={filteredTaskCount} textColor="white" color={color} max={4} />
    </Box>
  )
}
