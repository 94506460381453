import { atom } from 'recoil'

import {
  AppContexts,
  AppFieldValues,
  AppHeaderViewState,
  AppListItemOpenState,
  AppModalViewState,
  AppViews
} from 'main/components/apps/apps-types'

export const appViews = atom<AppViews>({
  key: 'AppViews',
  default: {}
})

export const appFieldValues = atom<AppFieldValues>({
  key: 'AppValues',
  default: {}
})

export const appComponentProps = atom<AppContexts>({
  key: 'AppContexts',
  default: {}
})

export const appHeaderView = atom<AppHeaderViewState>({
  key: 'AppHeader',
  default: {
    view: {
      appId: '',
      id: '',
      resourceId: '',
      type: 'header',
      content: []
    },
    appId: '',
    resourceId: ''
  }
})

export const appModal = atom<AppModalViewState>({
  key: 'AppModal',
  default: {
    view: {
      appId: '',
      id: '',
      resourceId: '',
      type: 'modal',
      title: '',
      content: []
    },
    appId: '',
    resourceId: '',
    open: false
  }
})

export const appListItemOpenState = atom<AppListItemOpenState>({
  key: 'AppListItemsOpen',
  default: {}
})

export const appConnectionLost = atom<boolean>({
  key: 'AppConnectionLostError',
  default: false
})

export const incidentTimeZone = atom<string>({
  key: 'AppTimeZone',
  default: ''
})
