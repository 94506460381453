import { useCallback } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { useMount } from 'react-use'

import { apiClient } from 'main/services/api'
import { useWebsockets } from 'main/services/hooks/use-websockets'

export type ActivityTrackableType =
  | 'User'
  | 'RunbookTeam'
  | 'Comment'
  | 'Task'
  | 'Runbook'
  | 'CustomField'
  | 'IncidentSeverity'
  | 'IncidentStatus'
  | 'Stream'

export type ActivityConfig = {
  key: string
  icon: string
  text: string
  group: string
}

export type ActivitiesResponse = {
  activities: ActivityVM[]
  meta: {
    activity_config: ActivityConfig[]
    pagination: { has_more: boolean; total_size: number }
    permissions: { update: number[] }
  }
}

export type ActivityVM = {
  id: number
  key: string
  activist: Activist
  created_at: Date
  display?: {
    inline_message?: string
    message?: string
  }
  changes?: ActivityChanges[]
  trackables: Trackable[]
  featured: boolean
  recipient_type: Recipients
  recipient_id: number
  new?: boolean
  text?: string
  grouped?: boolean
}

export type ActivityChanges = {
  field: string
  value: string
}

export type Activist = {
  type: ActivistType
  properties: ActivityProperties
}

export type ActivistType = 'User' | 'Runbook'

type Filter = { keys: string[]; featured?: boolean }

type IncidentResponse = {
  activity: ActivityVM
}

export type Recipients = 'Incident' | 'Runbook'

export type TrackableProperties = {
  id?: number
  key?: string
  content?: string
  color?: string
  createdAt?: number
  featured?: boolean
  runId?: number
  runbookVersionId?: number
  taskId?: number
  label?: string
  name?: string
  internal_id?: number
}

export type ActivityProperties = {
  id: number
  name: string
}

export type Trackable = {
  type: ActivityTrackableType
  properties?: TrackableProperties
}

type CacheKey = [string, number, Filter]

let cacheKey: CacheKey

const RUNBOOK_CHANNEL_NAME = 'RunbookChannel'

export const useActivities = (
  incidentId: number,
  filter: Filter = { keys: [] },
  getNextPageParam: (activityResponse: ActivitiesResponse) => ActivityVM['id'],
  runbookId: number
) => {
  const client = useQueryClient()
  const websockets = useWebsockets()
  const recipientId = runbookId

  const runbookChannelSubscription = websockets.findExistingSubscription(RUNBOOK_CHANNEL_NAME, runbookId)

  cacheKey = ['/activities?recipient_type=Runbook', recipientId, filter]

  const updateCacheOrReload = useCallback(
    (data: IncidentResponse) => {
      if (!data.activity) return
      // only update cache if using the same filter or not filtered at all
      if (
        (filter.featured && data.activity.featured) ||
        (!filter.featured && (filter.keys.includes(data.activity.key) || filter.keys.length === 0))
      ) {
        // @ts-ignore: this is a known issue https://github.com/tannerlinsley/react-query/issues/506
        // also see here https://github.com/gocutover/core/pull/1064#discussion_r697596903
        // also see this ticket to resolve https://cutover.atlassian.net/browse/OR-364
        client.setQueriesData<{ pages: ActivitiesResponse[] }>(cacheKey, cache => {
          if (!cache) return
          const newCache = cache
          //the line below is used for show/hide activity feed scroll-to-bottom icon button
          //to prevent glitchy behaviour when adding new activity, it has only UI usage
          data.activity['new'] = true
          newCache.pages[0].activities.unshift(data.activity)
          return newCache
        })
      }
    },
    [client, filter, recipientId]
  )

  useMount(() => {
    if (!runbookChannelSubscription && runbookId) {
      websockets.subscribe(RUNBOOK_CHANNEL_NAME, runbookId, {
        received: (data: IncidentResponse) => updateCacheOrReload(data)
      })
    }
  })

  const getActivities = async (lastId: string) => {
    const idQuery = lastId ? `last_id=${lastId}` : ''
    const keyQuery = filter.keys.length > 0 ? `&key[]=${filter.keys.join('&key[]=')}` : ''
    const featuredQuery = filter.featured ? '&featured=true' : ''
    const recipientQuery = `&recipient_type=Runbook&recipient_id=${recipientId}`

    const { data } = await apiClient.get<ActivitiesResponse[]>({
      url: `activities?${idQuery}${recipientQuery}${keyQuery}${featuredQuery}`
    })

    return data
  }

  //use to mock Activity Feed UI (WIP, remove when UI is migrated to new payload structure)
  // const getData = () => {
  //   var requestOptions = {
  //     method: 'GET'
  //   }

  //   //use activity-feed.json to change response structure
  //   const data = fetch('http://localhost:3030/data', requestOptions)
  //     .then(response => response.json())
  //     .catch(error => console.log('error', error))
  //   return data
  // }

  return useInfiniteQuery<ActivitiesResponse, Error, ActivitiesResponse>(
    cacheKey,
    // @ts-ignore see here https://github.com/gocutover/core/pull/1064#discussion_r697596903
    // also see this ticket to resolve https://cutover.atlassian.net/browse/OR-364
    ({ pageParam }) => getActivities(pageParam),

    //use getData() to Mock Activity (command to run mock (use npm 18.3v and relative path): json-server activity-feed.json --port 3030)
    // ({}) => getData(),
    {
      getNextPageParam
    }
  )
}

export const getCacheKey = (): CacheKey => {
  return cacheKey
}
