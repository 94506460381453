import { useEffect, useMemo } from 'react'
import { useSetRecoilState } from 'recoil'

import { Banner, BannerProps, MessageType, useNotify } from '@cutover/react-ui'
import { runActionState, useRunbookVersionPermission, useRunbookVersionProperty } from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import { useBannerLayout } from 'main/components/layout/layout-hooks'
import { postRunbookVersion } from 'main/services/queries/use-runbook-versions'
import { useHandleRunbookResponse } from 'main/recoil/runbook/updaters/tasks-operations'
import { useNavigateToCurrentVersionAlias } from 'main/recoil/shared/nav-utils'

export const RunbookBanner = () => {
  const stage = useRunbookVersionProperty({ attribute: 'stage' })
  const run = useRunbookVersionProperty({ attribute: 'run' })
  const approvalStatus = useRunbookVersionProperty({ attribute: 'approval_status' })
  const id = useRunbookVersionProperty({ attribute: 'id' })
  const runbookId = useRunbookVersionProperty({ attribute: 'runbook_id' })
  const isCurrent = useRunbookVersionProperty({ attribute: 'is_current' })

  const canCreateRunbookVersion = useRunbookVersionPermission({ attribute: 'create' })
  const navigateToCurrentVersion = useNavigateToCurrentVersionAlias()
  const processRunbookVersionResponse = useHandleRunbookResponse()
  const setRunAction = useSetRecoilState(runActionState)
  const { showBannerLayout, hideBannerLayout } = useBannerLayout()
  const { t } = useLanguage('runbook', { keyPrefix: 'banner' })
  const notify = useNotify()
  const runType = run?.run_type

  const handleReturnToPlanning = async () => {
    const response = await postRunbookVersion(runbookId, { base_version_id: id })
    processRunbookVersionResponse(response)
    notify.success('Version has been saved')
  }

  const banner: BannerProps | undefined = useMemo(():
    | { message: string; type: MessageType; linkText?: string; icon: boolean; onClick?: any }
    | undefined => {
    if (!isCurrent) {
      return {
        message: t('message.oldVersion'),
        type: 'idea',
        linkText: t('linkText.goToCurrentVersion'),
        onClick: () => navigateToCurrentVersion(),
        icon: false
      }
    }
    if (approvalStatus === 'pending') {
      return {
        message: t('message.draft'),
        type: 'idea',
        linkText: t('linkText.draft'),
        onClick: () => {
          console.log('approve template')
        },
        icon: false
      }
    }
    switch (stage) {
      case 'complete':
        if (runType === 'live') {
          return {
            message: t('message.complete'),
            type: 'idea',
            linkText: t('linkText.complete'),
            onClick: () => {
              console.log('navigate to PIR')
            },
            icon: false
          }
        }
        if (runType === 'rehearsal') {
          return {
            message: t('message.rehearsalComplete'),
            type: 'info',
            linkText: canCreateRunbookVersion ? t('linkText.rehearsalComplete') : undefined,
            onClick: canCreateRunbookVersion ? handleReturnToPlanning : undefined,
            icon: false
          }
        }
      case 'cancelled':
        if (runType === 'live') {
          return {
            message: t('message.cancelled'),
            type: 'error',
            icon: true
          }
        }
      case 'paused':
        return {
          message: t('message.paused'),
          type: 'info',
          linkText: t('linkText.paused'),
          onClick: () => {
            setRunAction('show-resume')
          },
          icon: false
        }
      default:
        undefined
    }
  }, [
    stage,
    runType,
    approvalStatus,
    t,
    setRunAction,
    canCreateRunbookVersion,
    handleReturnToPlanning,
    navigateToCurrentVersion
  ])

  useEffect(() => {
    // Reduces height of InnerContent so there is space for banner below
    if (banner) {
      showBannerLayout()
    } else {
      hideBannerLayout()
    }
    return () => {
      hideBannerLayout()
    }
  }, [banner])

  return (
    <>
      {banner ? (
        <Banner
          message={banner.message}
          type={banner.type}
          linkText={banner.linkText}
          icon={banner.icon}
          onClick={banner.onClick}
        />
      ) : undefined}
    </>
  )
}
