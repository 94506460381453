import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { cssTransition, toast, ToastContainer as ToastifyContainer, TypeOptions } from 'react-toastify'
import styled, { keyframes } from 'styled-components/macro'

import 'react-toastify/dist/ReactToastify.css'
import { themeColor } from '../../theme'
import { Text } from '../../typography'

export type ToasterProps = {
  title: string
  message: string
  type: TypeOptions
  hideProgressBar?: boolean
  autoClose?: number
}

export function showToaster({ title, message, type, hideProgressBar = true, autoClose = 5000 }: ToasterProps) {
  toast.clearWaitingQueue()
  const content = () => {
    return (
      <>
        <Text
          weight={700}
          css={`
            display: block;
            padding-bottom: 6px;
          `}
        >
          {title}
        </Text>
        <Text
          data-testid="toaster-message-text"
          css={`
            display: block;
          `}
        >
          {message}
        </Text>
      </>
    )
  }

  const fade = cssTransition({
    enter: 'Toastify__custom-fade-in',
    exit: 'Toastify__custom-fade-out'
  })

  return (
    <>
      {toast(content, {
        hideProgressBar,
        type,
        autoClose,
        position: 'bottom-right',
        draggable: false,
        transition: fade
      })}
    </>
  )
}

const fadein = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const fadeout = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`

export const ToastContainer = () => {
  const [toasterContentPortal, setToasterContentPortal] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const portal = document.getElementById('toaster-content-portal') || document.body

    if (portal) {
      setToasterContentPortal(portal)
    }
  }, [])

  return <>{toasterContentPortal && ReactDOM.createPortal(<ToastContainerStyled limit={1} />, toasterContentPortal)}</>
}

const ToastContainerStyled = styled(ToastifyContainer)`
  .Toastify__custom-fade-in {
    animation: ${fadein} 1s ease both;
  }

  .Toastify__custom-fade-out {
    animation: ${fadeout} 1s ease both;
  }

  .Toastify__close-button {
    color: ${themeColor('white')};
    opacity: 0.8;
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__toast--info {
    color: ${themeColor('notify-info-text')};
    background: ${themeColor('notify-info')};

    .Toastify__close-button {
      color: ${themeColor('notify-info-text')};
    }
  }

  .Toastify__toast--success {
    color: ${themeColor('notify-text')};
    background: ${themeColor('notify-success')};
  }

  .Toastify__toast--warning {
    color: ${themeColor('notify-text')};
    background: ${themeColor('notify-warning')};

    .Toastify__toast-icon {
      background: transparent;
      border: 0px;
    }
  }

  .Toastify__toast--error {
    color: ${themeColor('notify-text')};
    background: ${themeColor('notify-error')};
  }
`
