import { isEmpty } from 'lodash'
import { UseFormGetValues } from 'react-hook-form'
import * as yup from 'yup'

import { toCamelCase, toSnakeCase } from '@cutover/api'
import { urlRegex } from '@cutover/react-ui'
import { IntegrationActionItemFormType } from './integration-action-item-types'
import { CustomFieldOptionType } from '../shared-integration-components/integration-form-setting-fields'
import { LanguageTranslate } from 'main/services/hooks'
import {
  ActionConfig,
  IntegrationConnectionConfig,
  IntegrationHookConfig,
  IntegrationSettingOption
} from 'main/services/hooks/config-types'
import { IntegrationActionItem, IntegrationSetting } from 'main/services/queries/types'

export const validationSchema = (t: LanguageTranslate) => {
  return yup.object({
    name: yup.string().required(),
    integration_action: yup.string().required(),
    on: yup.array().of(yup.object()).min(1, 'at least 1').required(),
    image_url: yup
      .string()
      .matches(urlRegex, { message: t('form.validationErrors.imageUrl.invalid'), excludeEmptyString: true })
  })
}

export function buildSettingsSchema(actionConfigSettings: any | undefined) {
  const settings = actionConfigSettings ?? {}

  if (!isEmpty(settings)) {
    return Object.entries(settings).reduce((fields: any, [setting]) => {
      if (settings[setting]?.visible && settings[setting]?.required) {
        fields[setting] = yup.string().required()
      } else {
        switch (typeof fields[setting]) {
          case 'object':
            fields[setting] = yup.object().nullable()
          case 'string':
            fields[setting] = yup.string().nullable()
          default:
            fields[setting] = yup.mixed().nullable()
        }
      }

      return fields
    }, {} as { [key: string]: yup.StringSchema })
  }
}

export function buildValidationSchema(
  actionItem: IntegrationActionItem,
  actionConfig: ActionConfig | undefined,
  t: LanguageTranslate
) {
  delete actionItem.settings?.['encrypted']
  let settingsSchema = {}
  settingsSchema = !isEmpty(actionItem.settings) && buildSettingsSchema(actionConfig?.settings)

  return isEmpty(actionItem.settings)
    ? validationSchema(t)
    : validationSchema(t).shape({
        settings: yup.object(settingsSchema).required()
      })
}

export const buildDefaultValues = (
  actionItem: IntegrationActionItem,
  selectedActionConfig: ActionConfig | undefined,
  integrationHooks: IntegrationHookConfig[]
) => {
  const foundHooks = integrationHooks?.filter(hook => actionItem.on.indexOf(hook.on) !== -1) || []
  const hookOptions = foundHooks.map(hook => {
    return { label: hook.name, value: hook.on }
  })

  return {
    name: actionItem.name,
    image_url: actionItem.image_url,
    id: actionItem.id,
    entity_id: actionItem.entity?.id,
    integration_action: actionItem.integration_action,
    oauth_user: actionItem.oauth_user,
    on: hookOptions,
    global: actionItem.global,
    account_id: actionItem.account_id,
    settings: buildDefaultActionItemSettings(actionItem, selectedActionConfig),
    options: buildDefaultActionItemOptions(actionItem, selectedActionConfig),
    additional_settings: {
      auto_start: actionItem.entity?.auto_start,
      enable_start_fixed: actionItem.entity?.enable_start_fixed,
      visibility: actionItem.global ? -1 : actionItem.account_id || accountId(actionItem)
    }
  }
}

// Resolve the visibility based on the account ID. Integration Links will rely on the account_id stored in the settings
// hash of the action item.
export const accountId = (actionItem: IntegrationActionItem): number => {
  return actionItem.entity ? actionItem.entity.account_id || -1 : (actionItem.settings.account_id as number)
}

export const buildDefaultActionItemSettings = (
  actionItem: IntegrationActionItem,
  selectedActionConfig: ActionConfig | undefined
) => {
  const settings = toCamelCase(Object.assign({}, actionItem.settings))
  for (const [key, value] of Object.entries(selectedActionConfig?.settings || {})) {
    if (!Boolean(settings[key]) && value.hasOwnProperty('default')) {
      ;(settings[key] as string | string[] | boolean) =
        typeof value.default !== 'undefined'
          ? (value.default as unknown as boolean) !== false
            ? value.default
            : false
          : ''
    }
  }

  /**
   * The mapping components below may be displayed but have no defined value, in which case settings
   * has no corresponding property key. Need to set the keys here in order to prompt a rerender when
   * moving from an action item with a defined value for the key to one where the key is not present.
   */
  if (!settings.hasOwnProperty('successMappings')) {
    settings.successMappings = ''
  }
  if (!settings.hasOwnProperty('errorMappings')) {
    settings.errorMappings = ''
  }
  if (!settings.hasOwnProperty('pollingConditions')) {
    settings.pollingConditions = ''
  }

  return settings
}

export const buildDefaultActionItemOptions = (
  actionItem: IntegrationActionItem | null,
  selectedActionConfig: ActionConfig | undefined
) => {
  const options = Object.assign({}, selectedActionConfig?.options)
  delete options.executeInRehearsal
  delete options.failWhenInvalid
  delete options.updateStatusAfterExecute

  if (actionItem) {
    for (const [key, value] of Object.entries(actionItem.option_overrides)) {
      options[key] = value
    }
  }
  return toSnakeCase(options)
}

export const getItemTitle = (actionItentifier: string, integrations: IntegrationConnectionConfig[]): string => {
  const tokens = actionItentifier.split('::')
  const integrationIdentifier = tokens[1]
  const actionItemIdentifier = tokens[2]
  const foundIntegration = integrations.find(integration => integration.klass.includes(integrationIdentifier))
  const foundAction = foundIntegration?.actions.find(action => action.klass.includes(actionItemIdentifier))
  return foundAction?.name || ''
}

export function buildRequestData(
  values: IntegrationActionItemFormType,
  integrationSetting: IntegrationSetting,
  actionSettings: { [x: string]: any } | undefined,
  dirtyFields: { [x: string]: any } | undefined
) {
  const removedSensitiveUnchangedValues = Object.assign({}, values)

  if (actionSettings) {
    for (const [key, value] of Object.entries(actionSettings)) {
      if (value?.sensitive && !dirtyFields?.settings?.[toCamelCase(key)]) {
        delete removedSensitiveUnchangedValues.settings?.[toCamelCase(key)]
      }
    }
  }

  // We would no longer require this when task is connected to integration action item via integration links
  const hooks = values.on.map((opt: CustomFieldOptionType) => {
    return opt.value
  })
  const entityType =
    hooks.length === 1 && hooks.indexOf('PubSub::Task::Started') !== -1 ? 'TaskType' : 'IntegrationLink'

  const global = values.additional_settings?.visibility === -1 || values.global
  const accountId =
    (values.additional_settings?.visibility !== -1 ? values.additional_settings?.visibility : undefined) ||
    values.account_id
  const on = values.on.map(opt => opt.value)

  return {
    ...removedSensitiveUnchangedValues,
    entity_type: entityType,
    integration_setting_id: integrationSetting.id,
    global,
    account_id: accountId,
    on
  }
}

type MergedSettings = Record<
  string,
  Partial<{ label: string; placeholder: string; required: boolean; value: string; default: string }>
>

// Coerce a partial object with the relevant keys for displaying the form settings fields sourced from either type of
// data that may be used to display the form
export function mergeSettings(actionItem: IntegrationActionItem | ActionConfig) {
  return actionItem.settings as MergedSettings
}

export function actionConfigSettingsVisibleAndRequired(
  settings: IntegrationSettingOption,
  selectedActionConfigSettings: any,
  getValues: UseFormGetValues<any>
) {
  Object.entries(settings ?? {}).map(([settingKey, _v], _index) => {
    const setting = selectedActionConfigSettings?.[settingKey]
    if (setting) {
      setting.visible = isDependentSettings(setting, getValues)
    }
  })
}

export function isDependentSettings(setting: IntegrationSettingOption, formValues: UseFormGetValues<any>): boolean {
  let found = true
  if (setting.dependency) {
    let matches = 0
    setting.dependency.forEach(dep => {
      if (Array.isArray(dep.value)) {
        dep.value.find((el: any) => {
          if (el === formValues(`settings.${toCamelCase(dep?.dependentSetting || '')}`)) {
            matches++
          }
        })
      } else {
        const dependencyValue = formValues(`settings.${toCamelCase(dep.dependentSetting)}`)
        if (dep.inverse ? dep.value !== dependencyValue : dep.value === dependencyValue) {
          matches++
        }
      }
    })
    if (setting.dependencyOperator) {
      if (setting.dependencyOperator === 'not') {
        found = matches !== setting.dependency.length
      } else if (setting.dependencyOperator === 'or') {
        found = matches > 0
      }
    } else {
      found = matches === setting.dependency.length
    }
  }
  return found
}
