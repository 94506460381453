import { eventManager } from 'event-manager'
import { useRecoilCallback, useRecoilTransaction_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { extend, keyBy } from 'lodash'

import { runbookVersionResponseState_INTERNAL, useTaskListRefetch } from '../models'
import {
  RunbookStreamCreateResponse,
  RunbookStreamDestroyResponse,
  RunbookStreamUpdateResponse,
  RunbookUpdateResponse,
  RunbookVersionImportResponse
} from 'main/services/api/data-providers/runbook-types'
import { setChangedTasks, updateAllChangedTasks, updateRunbookData } from './shared-updates'
import { filterSelector } from 'main/recoil/shared/filters'
import { StreamChangedStream, StreamListStream } from 'main/services/queries/types'

export const useProcessRunbookUpdateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (data: RunbookUpdateResponse) => {
    updateRunbookData(transactionInterface)(data.runbook)
    updateAllChangedTasks(transactionInterface)(data.meta.changed_tasks)
  })
}

export const useProcessRunbookVersionCsvImportResponse = () => {
  const refetchTasks = useTaskListRefetch()

  // FIXME: seems wrong. we don't updeate the runbook state's current_version and runbook_version_id
  return useRecoilCallback(({ set }) => async (data: RunbookVersionImportResponse) => {
    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, data.runbook_version)
      })
    )

    refetchTasks()
  })
}

// NOTE: all the streams updates currently need to update the people panel through the even manager. Once that is
// no longer the case, we can transactionalize these updates.

export const useProcessStreamCreateResponse = () => {
  return useRecoilCallback(({ snapshot, set }) => async (data: RunbookStreamCreateResponse) => {
    const prevRunbookVersionResponse = await snapshot.getPromise(runbookVersionResponseState_INTERNAL)
    const nextRunbookVersionResponse = produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
      updateChangedStreams(data.meta.changed_streams, draftRunbookVersionResponse.meta.streams)
      updateVersionStreamsCount(draftRunbookVersionResponse.runbook_version.streams_count, 1)
      const newStreamListItem = data.meta.changed_streams.find(s => s.id === data.stream.id)
      if (newStreamListItem) {
        // Note: we are not adding data.stream directly as it uses the show serializer
        addStream(newStreamListItem, draftRunbookVersionResponse.meta.streams)
      }
    })

    set(runbookVersionResponseState_INTERNAL, nextRunbookVersionResponse)

    updatePeoplePanel(nextRunbookVersionResponse.meta.streams)
  })
}

export const useProcessStreamUpdateResponse = () => {
  return useRecoilCallback(({ snapshot, set }) => async (data: RunbookStreamUpdateResponse) => {
    const prevRunbookVersionResponse = await snapshot.getPromise(runbookVersionResponseState_INTERNAL)
    const nextRunbookVersionResponse = produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
      updateChangedStreams(data.meta.changed_streams, draftRunbookVersionResponse.meta.streams)
    })
    const runbookComponents = nextRunbookVersionResponse.meta.runbook_components
    const runbookComponentLookup = keyBy(runbookComponents, 'id')

    set(runbookVersionResponseState_INTERNAL, nextRunbookVersionResponse)

    setChangedTasks(set)({ changedTasks: data.meta.changed_tasks, runbookComponentLookup })

    updatePeoplePanel(nextRunbookVersionResponse.meta.streams)
  })
}

export const useProcessStreamDeleteResponse = () => {
  return useRecoilCallback(({ set, snapshot }) => async (data: RunbookStreamDestroyResponse) => {
    const prevRunbookVersionResponse = await snapshot.getPromise(runbookVersionResponseState_INTERNAL)
    const nextRunbookVersionResponse = produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
      updateChangedStreams(data.meta.changed_streams, draftRunbookVersionResponse.meta.streams)
      removeDeletedStream(data.stream.id, draftRunbookVersionResponse.meta.streams)
      updateVersionStreamsCount(draftRunbookVersionResponse.runbook_version.streams_count, -1)
    })
    const runbookComponents = nextRunbookVersionResponse.meta.runbook_components
    const runbookComponentLookup = keyBy(runbookComponents, 'id')

    set(runbookVersionResponseState_INTERNAL, nextRunbookVersionResponse)

    setChangedTasks(set)({ changedTasks: data.meta.changed_tasks, runbookComponentLookup: runbookComponentLookup })

    // need to unfilter from a stream that no longer exists if necessary
    const streamFilterValue = (await snapshot.getPromise(filterSelector({ attribute: 'stream' }))) as number[]
    if (streamFilterValue?.includes(data.stream.internal_id)) {
      set(
        filterSelector({ attribute: 'stream' }),
        streamFilterValue.filter(internalId => internalId !== data.stream.internal_id)
      )
    }

    updatePeoplePanel(nextRunbookVersionResponse.meta.streams)
  })
}

const updatePeoplePanel = (streams: StreamListStream[]) => {
  // NOTE: we're using the event emitter here to communicate with people panel
  // specifically which is tightly reliant on external sources for updating data.
  // This is a temporary solution until we can refactor the people panel.
  eventManager.emit('runbook-streams-updated', {
    streams: streams.map(stream => {
      return { id: stream.id, name: stream.name }
    })
  })
}

// TODO: settings_substreams_inherit_color logic
const updateChangedStreams = (changedStreams: StreamChangedStream[], existingStreams: StreamListStream[]) => {
  changedStreams.forEach(changedStream => {
    // Loop through changed streams, if it exists in the master data (incl as a substream), update
    const index = existingStreams.findIndex(stream => stream.id === changedStream.id)
    if (index > -1) {
      // Not a substream
      extend(existingStreams[index], changedStream)
    } else {
      // Substream
      for (let i = 0; i < existingStreams.length; i++) {
        const subStreamIndex = existingStreams[i].children.findIndex(subStream => subStream.id === changedStream.id)

        if (subStreamIndex > -1) {
          extend(existingStreams[i].children[subStreamIndex], changedStream)
          break
        }
      }
    }
  })
}

const removeDeletedStream = (deletedStreamId: number, existingStreams: StreamListStream[]) => {
  const deletedIndex = existingStreams.findIndex(stream => stream.id === deletedStreamId)

  if (deletedIndex > -1) {
    existingStreams.splice(deletedIndex, 1)
  } else {
    for (let i = 0; i < existingStreams.length; i++) {
      const subStreamIndex = existingStreams[i].children.findIndex(subStream => subStream.id === deletedStreamId)

      if (subStreamIndex > -1) {
        existingStreams[i].children.splice(subStreamIndex, 1)
        break
      }
    }
  }
}

// TODO: fix typings with streams during refactor
const addStream = (stream: StreamChangedStream, existingStreams: StreamListStream[]) => {
  if (stream.parent_id) {
    const parentStream = existingStreams.find(st => st.id === stream.parent_id)

    if (!parentStream) {
      console.warn('Parent stream not found for stream with parent_id', stream)
      return
    }
    // @ts-ignore
    parentStream.children = parentStream.children || []
    // @ts-ignore
    parentStream.children.push(stream)
  } else {
    // @ts-ignore
    existingStreams.push(stream)
  }
}

const updateVersionStreamsCount = (property: number, count: number) => {
  property = Math.max(0, (property ?? 0) + count)
}
