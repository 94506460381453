import { Controller, FieldValues, useFormContext } from 'react-hook-form'

import { MultiSelect, SelectProps } from '@cutover/react-ui'
import { FieldProps, getInputProps } from './form-fields'
import { TaskListTask } from 'main/services/queries/types'
import { TaskLineItem } from '../task-line-item'

type TaskSelectProps = Omit<
  SelectProps<TaskListTask, number>,
  'renderOption' | 'labelKey' | 'valueKey' | 'filterKeys' | 'options'
>

export type TaskFieldProps<TFieldValues extends FieldValues> = FieldProps<TaskSelectProps, TFieldValues> & {
  tasks: TaskListTask[]
  label: string
}

export const TaskSelectField = <TFieldValues extends FieldValues>({
  tasks,
  ...props
}: TaskFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { onChange, value, onBlur, ref } }) => {
        const tasksValue = tasks?.filter(task => value?.includes(task.id)) ?? []
        return (
          // @ts-ignore
          <MultiSelect<TaskListTask>
            {...props}
            {...inputProps}
            valueKey="id"
            filterKeys={['name', 'internal_id', 'label']}
            renderOption={(opt, { onDeselect, highlighted, selected }) => {
              return (
                <TaskLineItem
                  key={opt.id}
                  taskId={opt.id}
                  active={highlighted}
                  hasClickEvent={!!selected}
                  // @ts-ignore
                  onClickRemove={!inputProps.readOnly && onDeselect ? () => onDeselect(opt.id) : undefined}
                  role="option"
                  disabled={props.disabled}
                />
              )
            }}
            icon="add"
            inputRef={ref}
            onBlur={onBlur}
            onChange={val => onChange(val?.map(v => v.id))}
            // required for searching by "#internal_id"
            options={tasks.map(task => ({ ...task, label: `#${task.internal_id}` }))}
            value={tasksValue}
          />
        )
      }}
    />
  )
}
