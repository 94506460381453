import styled from 'styled-components/macro'
import { LiteralUnion } from 'type-fest'

import { Box, BoxProps } from '../layout'
import { resolveColor, themeColor } from '../theme'
import { ColorProp } from '../theme/color'

export type ListItemBoxProps = Omit<BoxProps, 'color' | 'border'> & {
  selected?: boolean
  size?: 'small' | 'medium' | 'large'
  border?: boolean
  clickable?: boolean
  active?: boolean
  // TODO: is there better prop for this -- are tehre a preset of types/colors? etc */
  color?: LiteralUnion<ColorProp, string>
  level?: number
}

const LEVEL_INDENT_MULTIPLIER = 32

export const ListItemContainer = styled(Box).attrs((props: ListItemBoxProps) => ({
  round: '8px',
  direction: 'row',
  flex: false,
  align: 'center',
  width: props.width ?? '100%',
  background: props.background ?? (props.selected ? 'primary-bg' : props.active ? 'bg-1' : undefined),
  pad: props.size === 'large' ? '8px' : '4px',
  height: props.size === 'small' ? '32px' : props.size === 'medium' ? '40px' : '56px',
  gap: props.size === 'small' ? 'xxsmall' : 'xsmall'
}))<ListItemBoxProps>`
  color: ${props => props.color && resolveColor(props.color, props.theme)};

  ${props =>
    props.level &&
    props.level > 1 &&
    `
    padding-left: ${LEVEL_INDENT_MULTIPLIER * (props.level - 1)}px;
  `}

  border: ${props => props.border && '1px solid ' + resolveColor('bg-3', props.theme)};

  &:hover {
    background: ${props => props.clickable && !props.selected && themeColor('bg-1')};
    border: ${props => props.border && '1px solid ' + resolveColor('bg-3', props.theme)};
  }
`

export const ListItemExpandableContent = styled(Box)<{
  listItemSize?: 'small' | 'medium' | 'large'
  listItemLevel: number
}>`
  ${props =>
    props.listItemLevel &&
    props.listItemLevel > 1 &&
    `
    padding-left: ${LEVEL_INDENT_MULTIPLIER * (props.listItemLevel - 1)}px;
  `}

  ${props =>
    props.listItemSize &&
    `
    margin-left: ${props.listItemSize === 'small' ? '0' : props.listItemSize === 'medium' ? '4px' : '8px'};
  `}
`

export const ListItemEndBox = styled(Box).attrs((props: ListItemBoxProps) => ({
  flex: false,
  direction: 'row',
  gap: props.size === 'small' ? 'xsmall' : 'small'
}))<ListItemBoxProps>``
