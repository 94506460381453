import styled, { css } from 'styled-components'

import { Box, IconButton, media } from '@cutover/react-ui'
import { useUserOrTeamDetailsEditPanel } from 'main/context/panel-context'

export const RunbookRightNavigation = () => {
  const { openPanel } = useUserOrTeamDetailsEditPanel()

  const handleClickPeoplePanel = () => {
    openPanel({ initialScreen: 'users-and-teams' })
  }

  const handleEdit = () => {
    console.log('edit runbook')
  }

  const handleComments = () => {
    console.log('comments')
  }

  const handleComms = () => {
    console.log('comms')
  }

  // TODO: use i18n for labels
  return (
    <NavContainer>
      <IconButton icon="edit" onClick={handleEdit} label="Runbook Details" />
      <IconButton icon="users" onClick={handleClickPeoplePanel} label="View Users/Teams" />
      <IconButton icon="message" onClick={handleComments} label="Comments" />
      <IconButton icon="phone" onClick={handleComms} label="Ad hoc Comms" />
    </NavContainer>
  )
}

const NavContainer = styled(Box)`
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 14px;
  ${media.sm(css`
    flex-direction: row;
    justify-content: center;
    gap: 32px;
  `)}
`
