import { useState } from 'react'
import styled, { css } from 'styled-components'
import { format as formatDate, utcToZonedTime } from 'date-fns-tz'
import { useRecoilValue } from 'recoil'

import { Box, Icon, IconName, media, Text, themeColor } from '@cutover/react-ui'
import { incidentTimeZone } from 'main/recoil/apps/apps-atoms'
import { ActivityConfig, ActivityVM } from 'main/services/queries/use-activities'
import { ActivityHeader } from './activity-header'
import { ActivityFeatured } from './activity-featured'
import { ActivityDetails } from './activity-details'

type ActivityProps = {
  activity: ActivityVM
  activityConfig: ActivityConfig[]
  canUpdate: boolean
  previousActivity: ActivityVM
  index: number
  active: boolean
  setActive: (index: number | null) => void
}

export const Activity = ({
  activity,
  activityConfig,
  previousActivity,
  canUpdate,
  index,
  active,
  setActive
}: ActivityProps) => {
  const showDetails = !!activity.display?.message || !!activity.changes?.length
  const [activityIsHovered, setActivityIsHovered] = useState(false)
  const timeZone = useRecoilValue<string>(incidentTimeZone)
  const formattedDate = formatDate(utcToZonedTime(new Date(activity.created_at), timeZone), 'HH:mm')
  const formattedPreviousDate = previousActivity
    ? formatDate(utcToZonedTime(new Date(previousActivity.created_at), timeZone), 'HH:mm')
    : ''
  const samePreviousActivityTime = formattedDate === formattedPreviousDate

  return (
    <ActivityStyledBox
      featured={activity.featured}
      key={activity.id}
      data-testid={`activity-${activity.id}`}
      onMouseEnter={() => setActivityIsHovered(true)}
      onMouseLeave={() => setActivityIsHovered(false)}
    >
      {!activity.grouped && (
        <ActivityHeader
          activity={activity}
          activityConfig={activityConfig}
          previousActivity={previousActivity}
          time={samePreviousActivityTime ? '' : formattedDate}
          index={index}
          active={active}
          setActive={setActive}
        />
      )}
      {showDetails && <ActivityDetails activity={activity} />}
      {activityIsHovered && (activity.grouped || samePreviousActivityTime) && (
        <ActivityInsightsWrapper>
          <ActivityInsights time={formattedDate} />
        </ActivityInsightsWrapper>
      )}
      <CallToActionWrapper activityIsHovered={activityIsHovered}>
        <ActivityFeatured activity={activity} canUpdate={canUpdate} isHovered={activityIsHovered} />
      </CallToActionWrapper>
    </ActivityStyledBox>
  )
}

const ActivityStyledBox = styled(Box)<{ featured?: boolean }>`
  list-style: none;
  padding-left: 35px;
  padding-right: 4px;
  ${media.sm(css`
    padding: 0 20px;
  `)}
  background-color: ${props => (props.featured ? 'rgba(251, 196, 56, 0.16)' : themeColor('bg'))};

  &:hover {
    background-color: ${props => (props.featured ? 'rgba(251, 196, 56, 0.16)' : themeColor('bg-1'))};
  }
`

const CallToActionWrapper = styled(Box)<{ activityIsHovered?: boolean }>`
  display: block;
  right: 11px;
  position: absolute;
  margin-top: 6px;
`

const ActivityInsightsWrapper = styled(Box)`
  display: block;
  left: 39px;
  position: absolute;
`

export const ActivityInsights = ({ time, icon, featured }: { time?: string; icon?: IconName; featured?: boolean }) => {
  return (
    <Box gap="small" direction="row">
      <Box width="40px" align="center" pad="xsmall">
        {time && <Text color="text-light">{time}</Text>}
      </Box>
      {icon && (
        <Box
          align="center"
          background={featured ? { custom: 'rgba(251, 196, 56, 0.2)' } : 'bg-2'}
          round="xsmall"
          pad="xsmall"
        >
          <Icon size="medium" icon={icon} color="text-light" aria-hidden />
        </Box>
      )}
    </Box>
  )
}
