import { useEffect } from 'react'
import { atom, Loadable, selector, SetterOrUpdater, useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { useProcessTaskBulkDeleteResponse } from '../../updaters/tasks-operations'
import { runbookIdState } from '../runbook/runbook'
import { runbookVersionIdState } from '../runbook-version/runbook-version'
import { deleteTasks } from 'main/services/queries/use-tasks'
import { RunbookTaskBulkDeleteResponse } from 'main/services/api/data-providers/runbook-types'

const tasksDeleteIdsAtom = atom<number[] | undefined>({
  key: 'tasks:delete:ids',
  default: undefined
})

const tasksDeleteRequestState = selector({
  key: 'tasks:delete:request',
  get: async ({ get }) => {
    const tasksToDelete = get(tasksDeleteIdsAtom)
    const runbookId = get(runbookIdState)
    const versionId = get(runbookVersionIdState)

    if (!tasksToDelete || !runbookId || !versionId) return

    return await deleteTasks(runbookId, versionId, tasksToDelete)
  }
})

export const useTaskDeleteTasksState: () => [
  Loadable<RunbookTaskBulkDeleteResponse | undefined>,
  SetterOrUpdater<number[] | undefined>
] = () => {
  const processBulkDeleteResponse = useProcessTaskBulkDeleteResponse()
  const deleteTasksRequestState = useRecoilValueLoadable(tasksDeleteRequestState)
  const setTasksToDelete = useSetRecoilState(tasksDeleteIdsAtom)
  const { state, contents } = deleteTasksRequestState

  useEffect(() => {
    if (state === 'hasValue' && contents) {
      processBulkDeleteResponse(contents)
      setTasksToDelete(undefined)
    }
  }, [state, contents])

  return [deleteTasksRequestState, setTasksToDelete]
}
