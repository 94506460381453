import { useRecoilCallback } from 'recoil'
import { produce } from 'immer'

import { DependencyType, MenuListItemProps, TaskItemIcon } from '@cutover/react-ui'
import {
  accountTaskTypeLookup,
  runbookViewState_INTERNAL,
  RunbookViewStateType,
  streamsLookupState,
  taskCreateFromPredecessorIdState_INTERNAL,
  taskListLookupState,
  TaskPermissions
} from 'main/recoil/runbook'
import { filterSelector } from 'main/recoil/shared/filters'
import { useLanguage } from 'main/services/hooks'
import { RunbookVersion, TaskListTask } from 'main/services/queries/types'
import { rightPanelState } from 'main/context/panel-context'
import { stageIconName, taskTypeIcon } from './task-list-item-props'

export const useBuildTaskActionMenuItems = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ task, taskPermissions }: { task: TaskListTask; taskPermissions: TaskPermissions }) => {
        const { canCreateTaskAfter, canCreateLinkedTaskAfter, canAddSnippetAfter, canDeleteTask } = taskPermissions
        const { id, internal_id: internalId, predecessor_ids: predecessorIds } = task
        const runbookViewState = await snapshot.getPromise(runbookViewState_INTERNAL)

        const menuItems = [
          canCreateTaskAfter &&
            ({
              label: t('actions.addTaskAfter'),
              icon: 'add',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                set(taskCreateFromPredecessorIdState_INTERNAL, id)
              }
            } as MenuListItemProps),
          canCreateLinkedTaskAfter &&
            ({
              label: t('actions.addLinkedTask'),
              icon: 'runbook',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                set(runbookViewState_INTERNAL, mutateModalState({ id, type: 'linked-runbook-add' }))
              }
            } as MenuListItemProps),
          canAddSnippetAfter &&
            ({
              label: t('actions.addSnippet'),
              icon: 'snippet',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                set(runbookViewState_INTERNAL, mutateModalState({ id, type: 'snippet-add' }))
              }
            } as MenuListItemProps),
          {
            label: t('actions.showCriticalPath'),
            icon: 'critical-path',
            disabled: !predecessorIds.length, // Note: disabling instead of omitting so no chance of completely empty menu
            onClick: e => {
              e.syntheticEvent.stopPropagation()
              e.syntheticEvent.preventDefault()
              set(filterSelector({ attribute: 'critical_to_here' }), internalId)
            }
          } as MenuListItemProps,
          {
            label: t('actions.showAncestors'),
            icon: 'predecessors',
            disabled: !predecessorIds.length,
            onClick: e => {
              e.syntheticEvent.stopPropagation()
              e.syntheticEvent.preventDefault()
              set(filterSelector({ attribute: 'predecessors_to_here' }), internalId)
            }
          } as MenuListItemProps,
          canDeleteTask &&
            ({
              label: t('actions.delete'),
              icon: 'delete',
              destructive: true,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                set(runbookViewState_INTERNAL, mutateModalState({ id: [id], type: 'tasks-delete' }))
              }
            } as MenuListItemProps)
        ].filter(Boolean) as MenuListItemProps[]

        const mutateModalState = (modalState: RunbookViewStateType['modal']['active']) =>
          produce(runbookViewState, draft => {
            draft.modal.active = modalState
          })

        return menuItems
      }
  )
}

export const useBuildTaskDependencyMenuItems = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async ({
        task,
        type,
        runbookVersion
      }: {
        task: TaskListTask
        type: DependencyType
        runbookVersion: RunbookVersion
      }) => {
        const { openPanel, closePanel, panel } = await snapshot.getPromise(rightPanelState)
        const taskLookup = await snapshot.getPromise(taskListLookupState)
        const taskTypeLookup = await snapshot.getPromise(accountTaskTypeLookup)
        const streamLookup = await snapshot.getPromise(streamsLookupState)

        const dependencyIds = type === 'predecessors' ? task.predecessor_ids : task.successor_ids
        const tasks = dependencyIds.map(id => taskLookup[id])

        const menuItems = tasks
          .sort((a, b) => {
            return a.internal_id - b.internal_id
          })
          .map(task => {
            const { internal_id: internalId, name, id } = task
            const taskType = taskTypeLookup[task.task_type_id]

            const iconProps = {
              color: streamLookup[task.stream_id].color,
              icon: taskTypeIcon(taskType.icon, task.stage),
              inProgress: task.stage === 'in-progress',
              isOpaque: task.stage === 'complete',
              stageIcon: stageIconName(
                task.completion_type,
                task.stage,
                runbookVersion.stage,
                task.start_fixed,
                runbookVersion.run?.run_type
              )
            }

            const item = {
              icon: <TaskItemIcon iconSize="xsmall" {...iconProps} />,
              label: `#${internalId} ${name}`,
              onClick: () => {
                // @ts-ignore
                if (panel.taskId === id) {
                  closePanel?.()
                } else {
                  openPanel({ panel: 'task-edit', taskId: task.id })
                }
              }
            }
            return item
          })
        return menuItems
      }
  )
}
