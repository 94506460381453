import { useCallback } from 'react'

import { useNotify } from '@cutover/react-ui'
import { useCurrentUser } from 'main/recoil/current-user'
import {
  RunbookResponse,
  RunbookTaskBulkSkipResponse,
  RunbookTaskFinishResponse,
  RunbookTaskStartResponse,
  RunbookTaskUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import { useLanguage } from 'main/services/hooks'

export const useTaskNotifications = () => {
  const notify = useNotify()
  const { id: currentUserId } = useCurrentUser()
  const { t } = useLanguage('notification')

  const taskStartOrFinishNotification = useCallback(
    (response: RunbookTaskStartResponse | RunbookTaskFinishResponse, messageKey: string) => {
      let message
      const taskName = response.task.name
      if (response.meta.headers.request_user_id === currentUserId) {
        message = t(`task.${messageKey}.currentUserMessage`, { taskName })
      } else {
        const userName = response.meta.headers.request_user_name
        message = t(`task.${messageKey}.userMessage`, { userName, taskName })
      }
      notify.success(message, { title: t(`task.${messageKey}.title`) })
    },
    [currentUserId]
  )

  const taskStartNotification = useCallback(
    (response: RunbookTaskStartResponse) => {
      const messageKey = response.task.stage === 'startable' ? 'startable' : 'started'
      taskStartOrFinishNotification(response, messageKey)
    },
    [taskStartOrFinishNotification]
  )

  const taskFinishNotification = useCallback(
    (response: RunbookTaskFinishResponse) => {
      const messageKey = response.task.stage === 'in-progress' ? 'finishable' : 'finished'
      taskStartOrFinishNotification(response, messageKey)
    },
    [taskStartOrFinishNotification]
  )

  const taskSkippedNotification = useCallback(
    (response: RunbookTaskBulkSkipResponse) => {
      let message
      if (response.meta.headers.request_user_id === currentUserId) {
        message = t('task.skipped.currentUserMessage')
      } else {
        const userName = response.meta.headers.request_user_name
        message = t('task.skipped.userMessage', { userName })
      }
      notify.success(message, { title: t('task.skipped.title') })
    },
    [currentUserId]
  )

  const taskUpdateNotification = useCallback(
    (response: RunbookTaskUpdateResponse) => {
      let message
      if (response.meta.headers.request_user_id === currentUserId) {
        message = t('task.updated.currentUserMessage', { taskName: response.task.name })
      } else {
        const userName = response.meta.headers.request_user_name
        message = t('task.updated.userMessage', { userName: userName, taskName: response.task.name })
      }
      notify.success(message, { title: t('task.updated.title') })
    },
    [currentUserId]
  )

  return useCallback((response: RunbookResponse) => {
    switch (response.meta.headers.request_method) {
      case 'start':
        return taskStartNotification(response as RunbookTaskStartResponse)
      case 'finish':
        return taskFinishNotification(response as RunbookTaskFinishResponse)
      case 'bulk_skip':
        return taskSkippedNotification(response as RunbookTaskBulkSkipResponse)
      case 'update':
        return taskUpdateNotification(response as RunbookTaskUpdateResponse)
      default:
        return () => {}
    }
  }, [])
}
