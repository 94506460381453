import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { getInfiniteRunbookCacheKey } from 'main/services/queries/use-runbooks-infinite'
import { RunbookListRunbook } from 'main/services/queries/types'

// dummy function for RB squad

const setResponseState = (response: {}) => {
  console.log(response)
}

export const useHandleAccountResponse = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (response: {
      meta: { headers: { request_class: string; request_method: string; request_user_id: number } }
      runbook: RunbookListRunbook
    }) => {
      if (response.meta.headers.request_class === 'Runbook' && response.meta.headers.request_method === 'create') {
        const cacheKey = getInfiniteRunbookCacheKey()
        queryClient.invalidateQueries(cacheKey)
      }
    },
    [setResponseState]
  )
}
