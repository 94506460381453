import { atom, selector, useRecoilValue } from 'recoil'

import { globalConfig } from 'main/recoil/shared/global-config'
import { runbookRunbookTypeState } from '../..'
import { accountTaskTypesGroupedIntegrations } from '../account/task-types'
import { TaskShowTask } from 'main/services/queries/types'

// TODO: fix name, says it's an id but its a task
export const taskEditUpdatedTaskId = atom<TaskShowTask | undefined>({
  key: 'task-edit:updated-task',
  default: undefined
})

const taskEditTaskTypesState = selector({
  key: 'task-edit:task-types',
  get: ({ get }) => {
    const { taskTypes: taskTypesNormal, taskTypeIntegrations: initialTaskTypeIntegrations } = get(
      accountTaskTypesGroupedIntegrations
    )
    const { dynamic } = get(runbookRunbookTypeState)
    const { integrations } = get(globalConfig)

    const integrationTaskTypeKlasses = integrations.flatMap(integration =>
      integration.actions.map(action => action.klass)
    )
    const taskTypeIntegrations = initialTaskTypeIntegrations.filter(tti => {
      if (tti.archived) return false

      return integrationTaskTypeKlasses.includes(tti.integration_action_items?.[0]?.integration_action)
    })

    return {
      taskTypes: taskTypesNormal.filter(tt => (dynamic ? !tt.archived : !tt.archived && tt.key !== 'snippet')),
      taskTypeIntegrations
    }
  }
})

export const useTaskEditTaskTypes = () => {
  return useRecoilValue(taskEditTaskTypesState)
}
