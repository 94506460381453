import { ReactNode, useCallback, useEffect, useRef } from 'react'
import { assertion, CheckerReturnType, mixed, writableDict } from '@recoiljs/refine'
import ReactAppErroScreen from 'react-app-error'
import { ErrorBoundary } from 'react-error-boundary'
import { AtomEffect, DefaultValue } from 'recoil'
import {
  ItemKey,
  ItemSnapshot,
  ListenInterface,
  ReadItem,
  RecoilSync,
  StoreKey,
  syncEffect,
  URLSyncEffectOptions,
  WriteInterface
} from 'recoil-sync'

import { handlePercentEncoding } from './recoil-sync-component'

/**
 * IMPORTANT: This source was copied from Facebook's RecoilURLSync source and modified so it works with our hash based routing
 *
 * This enables us to sync recoil state with the search segment of url's hash.
 *
 * NOTE: There is an issue seen in **Firefox in development mode** related to releasing snapshots. It's possible to trigger when
 * switching very quickly between runbooks in different workspaces (e.g., via starred runbook in nav panel). We have a mechanism
 * in place in development mode with an error boundary to retry loading the app contents instead of crashing the first time.
 */

export type NodeKey = string
export type HistoryOption = 'push' | 'replace'

export type AtomRegistration = {
  history: HistoryOption
  itemKeys: Set<ItemKey>
}

export const DEFAULT_BROWSER_INTERFACE = {
  replaceURL: (url: string) => window.history.replaceState(null, '', url),
  pushURL: (url: string) => window.history.pushState(null, '', url),
  getURL: () => window.document.location,
  listenChangeURL: (handleUpdate: () => void) => {
    window.addEventListener('popstate', handleUpdate)
    return () => window.removeEventListener('popstate', handleUpdate)
  }
}

export type BrowserInterface = {
  replaceURL?: (url: string) => void
  pushURL?: (url: string) => void
  getURL?: () => string
  listenChangeURL?: (handler: () => void) => () => void
}

export type RecoilURLSyncOptions = {
  children: ReactNode
  storeKey?: StoreKey
  serialize: (arg: any) => string
  deserialize: (arg: string) => any
  browserInterface?: BrowserInterface
}

type ItemState = CheckerReturnType<typeof itemStateChecker>

const itemStateChecker = writableDict(mixed())
const refineState = assertion(itemStateChecker)
const registries: Map<StoreKey, Map<NodeKey, AtomRegistration>> = new Map()

// see note in top level comment
let resetCount = 1

export function RecoilURLHashSearchSync(props: RecoilURLSyncOptions) {
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={() => {
        // has a details arg but that is empty because the error that is throw does not include event.details
        console.warn('Error: Recovered from error in hash url search params sync component. resetCount: ' + resetCount)
      }}
    >
      <RecoilURLHashSearchSyncComponent {...props} />
    </ErrorBoundary>
  )
}

function RecoilURLHashSearchSyncComponent({
  storeKey,
  serialize,
  deserialize,
  browserInterface,
  children
}: RecoilURLSyncOptions) {
  const { getURL, replaceURL, pushURL, listenChangeURL } = {
    ...DEFAULT_BROWSER_INTERFACE,
    ...(browserInterface ?? {})
  }

  const updateCachedState: () => void = useCallback(() => {
    cachedState.current = parseURL(getURL(), deserialize)
  }, [getURL, deserialize])
  const cachedState = useRef<ItemSnapshot | null>(null)

  // Avoid executing updateCachedState() on each render
  const firstRender = useRef(true)
  firstRender.current && updateCachedState()
  firstRender.current = false
  useEffect(updateCachedState, [updateCachedState])

  const write = useCallback(
    ({ diff, allItems }: WriteInterface) => {
      updateCachedState() // Just to be safe...
      // This could be optimized with an itemKey-based registery if necessary to avoid atom traversal.
      // @ts-ignore
      const atomRegistry = registries.get(storeKey)
      const itemsToPush =
        atomRegistry != null
          ? new Set(
              Array.from(atomRegistry)
                .filter(
                  ([, { history, itemKeys }]) => history === 'push' && Array.from(itemKeys).some(key => diff.has(key))
                )
                .map(([, { itemKeys }]) => itemKeys)
                .reduce(
                  // @ts-ignore
                  (itemKeys, keys) => itemKeys.concat(Array.from(keys)),
                  []
                )
            )
          : null

      if (itemsToPush?.size && cachedState.current != null) {
        const replaceItems: ItemSnapshot = cachedState.current
        // First, repalce the URL with any atoms that replace the URL history
        for (const [key, value] of allItems) {
          // @ts-ignore
          if (!itemsToPush.has(key)) {
            replaceItems.set(key, value)
          }
        }

        replaceURL(encodeURL(getURL(), replaceItems, serialize).split('/#')[1])
        pushURL(encodeURL(getURL(), allItems, serialize).split('/#')[1])
      } else {
        replaceURL(encodeURL(getURL(), allItems, serialize).split('/#')[1])
      }
      cachedState.current = allItems
    },
    [getURL, pushURL, replaceURL, serialize, storeKey, updateCachedState]
  )

  const read: ReadItem = useCallback((itemKey: ItemKey) => {
    return cachedState.current?.has(itemKey) ? cachedState.current?.get(itemKey) : new DefaultValue()
  }, [])

  const listen = useCallback(
    ({ updateAllKnownItems }: ListenInterface) => {
      function handleUpdate() {
        updateCachedState()
        if (cachedState.current != null) {
          updateAllKnownItems(cachedState.current)
        }
      }
      return listenChangeURL(handleUpdate)
    },
    [listenChangeURL, updateCachedState]
  )

  // reset if we've gotten this far
  resetCount = 0

  return (
    <RecoilSync storeKey={storeKey} read={read} write={write} listen={listen}>
      {children}
    </RecoilSync>
  )
}

const fallbackRender = ({ error, resetErrorBoundary }: any) => {
  // Resets the app max 3 times for when it catches for snapshot. Have seen it get to 2 times
  // when it happens. This limit is set so we can be aware if there are significant changes in frequency.
  if (isSnapshotError(error) && resetCount <= 3) {
    resetErrorBoundary()
    resetCount++
  }

  return <ReactAppErroScreen />
}

//////////////////////////////////// internal

const isSnapshotError = (error: Error) => {
  return process.env.NODE_ENV === 'development' && error.message === 'Snapshot has already been released.'
}

export function urlHashSearchSyncEffect<T>({
  history = 'replace',
  ...options
}: URLSyncEffectOptions<T> & { history: 'push' | 'replace' }): AtomEffect<T> {
  const atomEffect = syncEffect<T>(options)

  return (effectArgs: { node: { key: string } }) => {
    // Register URL sync options
    // @ts-ignore
    if (!registries.has(options.storeKey)) {
      // @ts-ignore
      registries.set(options.storeKey, new Map())
    }

    // @ts-ignore
    const atomRegistry = registries.get(options.storeKey)
    if (atomRegistry == null) {
      throw new Error('Error with atom registration')
    }

    atomRegistry.set(effectArgs.node.key, {
      history,
      itemKeys: new Set([options.itemKey ?? effectArgs.node.key])
    })

    // Wrap syncEffect() atom effect
    // @ts-ignore
    const cleanup = atomEffect(effectArgs)

    // Cleanup atom option registration
    return () => {
      atomRegistry.delete(effectArgs.node.key)
      cleanup?.()
    }
  }
}

function wrapState(x: any): ItemSnapshot {
  return new Map(Array.from(Object.entries(refineState(x))))
}
function unwrapState(state: ItemSnapshot): ItemState {
  return Object.fromEntries(
    Array.from(state.entries())
      // Only serialize atoms in a non-default value state.
      .filter(([, value]) => !(value instanceof DefaultValue))
  )
}

function parseURL(href: any, deserialize: (string: string) => any): ItemSnapshot | null {
  const search = href.split('#')[1]?.split('?')[1]

  return wrapState({ search: search ? deserialize(search) : '' })
}

function encodeURL(href: any, items: ItemSnapshot, serialize: (props: any) => string): string {
  const hashPath = href.split('#')[1]?.split('?')[0]

  let hash = ''

  if (items.get('search')) {
    try {
      const searchPath = '?' + decodeURI(handlePercentEncoding(serialize(unwrapState(items))))
      hash = '#' + hashPath + searchPath
    } catch {
      console.warn('Unable to encode filter: ', items)
      hash = '#' + hashPath
    }
  } else {
    hash = '#' + hashPath
  }

  const result = window.location.origin + '/' + hash

  return result
}
